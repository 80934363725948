import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Pressable, RefreshControl, ScrollView, StyleSheet, Text, View } from 'react-native';
import { getKidAnswers } from '../../api/kid/kid.api';
import { getQuestionById } from '../../api/question/question.api';
import Button from '../../components/Button';
import Colors from '../../constants/Colors';
import { AnswerType, AppNavigationProps, QuestionType, RootStackParamList } from '../../types';

const sectionTitles = {
  family: 'Sa famille',
  places: 'Ses lieux',
  companions: 'Ses compagnons',
  passions: 'Ses passions',
  contextual: 'Sur le moment',
  emotions: 'Ses émotions',
};

interface SectionData {
  family: ItemType[];
  places: ItemType[];
  companions: ItemType[];
  passions: ItemType[];
  contextual: ItemType[];
  emotions: ItemType[];
}

interface ItemType {
  id: number;
  question: string;
  answer: string;
  question_id: number;
}

const KidWorldScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "KidWorld">) => {
  const navigation = useNavigation<AppNavigationProps<"KidWorld">>();
  const kidName = route.params.kidName;
  const kidId = route.params.kidId;

  const [sectionData, setSectionData] = useState<SectionData>({
    family: [],
    places: [],
    companions: [],
    passions: [],
    contextual: [],
    emotions: [],
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = async () => {
    let isMounted = true;
    setIsLoading(true);
    try {
      const answersResponse = await getKidAnswers(kidId);
      const answers = answersResponse.data;

      const updatedSectionData: SectionData = {
        family: [],
        places: [],
        companions: [],
        passions: [],
        contextual: [],
        emotions: [],
      };

      for (const answer of answers) {
        const questionResponse = await getQuestionById(answer.question_id);
        if (questionResponse) { // Include all types of answers, including 'contextual'
          const questionType = questionResponse.question_type as keyof SectionData;
          updatedSectionData[questionType]?.push({
            id: answer.id,
            question: questionResponse.title,
            answer: answer.content,
            question_id: answer.question_id,
          });
        }
      }

      if (isMounted) {
        setSectionData(updatedSectionData);
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    return () => { isMounted = false; };
  };

  useFocusEffect(
    useCallback(() => {
      fetchData();
    }, [kidId])
  );

  const determinePreposition = (name: string) => {
    const vowels = ['a', 'e', 'i', 'o', 'u', 'h'];
    const firstLetter = name[0].toLowerCase();
    return vowels.includes(firstLetter) ? "d'" : "de ";
  };

  const truncate = (str: string, maxLength: number) => {
    if (str.length <= maxLength) return str;
    return `${str.substring(0, maxLength - 3)}...`;
  };

  useLayoutEffect(() => {
    const preposition = determinePreposition(kidName);
    const fullTitle = `Le monde ${preposition}${kidName}`;
    const truncatedTitle = truncate(fullTitle, 22);

    navigation.setOptions({
      title: truncatedTitle,
    });
  }, [navigation, kidName]);

  const onAnswerSelect = async (answerId: number, questionId: number, answerContent: string) => {
    try {
      const question = await getQuestionById(questionId);
      const answer = {
        id: answerId,
        content: answerContent,
        question_id: questionId,
      };

      navigation.navigate('EditKidWorld', {
        kidId: kidId,
        answer: answer as unknown as AnswerType,
        question: question as QuestionType,
        kidName: kidName,
      });

    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <ScrollView
      style={[{ backgroundColor: Colors.primary05 }]}
      contentContainerStyle={[styles.container]}
      refreshControl={<RefreshControl refreshing={isLoading} onRefresh={fetchData} />}
    >
      {!isLoading && (
        Object.entries(sectionData).map(([sectionKey, items]) => {
          return (
            <View key={sectionKey} style={styles.section}>
              <Text style={styles.sectionTitle}>{sectionTitles[sectionKey as keyof typeof sectionTitles]}</Text>
              {items.map((item: ItemType, index: number) => (
                <Pressable
                  key={index}
                  onPress={() => onAnswerSelect(item.id, item.question_id, item.answer)}
                  style={styles.itemContainer}
                >
                  <Text style={styles.questionText}>{item.question}</Text>
                  <Text style={styles.answerText}>{item.answer}</Text>
                </Pressable>
              ))}
              <Button
                title="Ajouter un élement"
                variant="secondary"
                size="small"
                onPress={() => navigation.navigate('CreateKidWorld', {
                  kidName: kidName,
                  kidId: kidId,
                  sectionKey: sectionKey,
                  fromOnboarding: false,
                })}
              />
            </View>
          );
        })
      )}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    padding: 16,
    backgroundColor: Colors.primary05,
  },
  section: {
    width: '100%',
    marginBottom: 20,
    alignItems: 'flex-start',
  },
  sectionTitle: {
    fontFamily: 'Gilroy-Bold',
    width: '100%',
    fontSize: 18,
    marginBottom: 10,
    color: Colors.text,
  },
  itemContainer: {
    width: '100%',
    marginBottom: 10,
    padding: 10,
    backgroundColor: Colors.primary10,
    borderRadius: 10,
    borderWidth: 1,
  },
  questionText: {
    fontFamily: 'Gilroy-Bold',
  },
  answerText: {
    fontFamily: 'Gilroy-Regular',
    marginTop: 5,
  },
});

export default KidWorldScreen;
