import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Picker } from '@react-native-picker/picker';
import React from 'react';
import { Platform, StyleSheet, View } from 'react-native';

type SelectorItem<T> = {
  label: string;
  value: T;
};

type ItemSelectorProps<T> = {
  selectedItem: T;
  onItemSelect: (item: T) => void;
  items: SelectorItem<T>[];
};


const ItemSelector = <T,>({ selectedItem, onItemSelect, items }: ItemSelectorProps<T>) => (
  <View style={styles.pickerWrapper}>
    {Platform.OS === 'web' ? (
      <>
        <select
          value={selectedItem?.toString ? selectedItem.toString() : ''}
          onChange={(e) => onItemSelect(e.target.value as unknown as T)}
          style={{
            width: '100%',
            height: 52,
            paddingTop: 16,
            paddingBottom: 16,
            paddingLeft: 24,
            paddingRight: 48,
            borderRadius: 40,
            backgroundColor: 'white',
            borderColor: '#000',
            borderWidth: 2,
            fontSize: 16,
            color: '#333',
            outline: 'none',
            cursor: 'pointer',
            boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1)',
            outlineStyle: 'none',
          }}
        >
          {items.map((item, index) => (
            <option key={index} value={item.value?.toString ? item.value.toString() : ''}>
              {item.label}
            </option>
          ))}
        </select>
        <View style={styles.arrowIcon}>
          <MaterialCommunityIcons name="chevron-down" size={20} color="#000" />
        </View>
      </>
    ) : (
      <Picker
        selectedValue={selectedItem}
        onValueChange={(itemValue) => onItemSelect(itemValue as unknown as T)}
        style={styles.picker}
      >
        {items.map((item, index) => (
          <Picker.Item key={index} label={item.label} value={item.value?.toString ? item.value.toString() : ''} />
        ))}
      </Picker>
    )}
  </View>
);

const styles = StyleSheet.create({
  pickerWrapper: {
    position: 'relative',
    width: '100%',
  },
  picker: {
    backgroundColor: 'white',
    borderColor: 'black',
    borderWidth: 2,
    borderRadius: 40,
    width: '100%',
    marginVertical: 8,
  },
  arrowIcon: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'none',
  },
});

export default ItemSelector;
