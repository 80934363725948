import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getKidAnswers } from '../../api/kid/kid.api';

const initialState = {
  answers: [],
  loading: false,
  error: null,
};

export const fetchAnswers = createAsyncThunk(
  'answers/fetchAnswers',
  async (kidId) => {
    const response = await getKidAnswers(kidId);
    return response.data;
  }
);

const answersSlice = createSlice({
  name: 'answers',
  initialState,
  reducers: {
    // Reducers for other synchronous actions
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnswers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAnswers.fulfilled, (state, action) => {
        state.loading = false;
        state.answers = action.payload;
      })
      .addCase(fetchAnswers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
    // Handle other async actions similarly
  },
});

export const { } = answersSlice.actions;

export default answersSlice.reducer;
