import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as Haptics from 'expo-haptics';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { FlatList, ListRenderItemInfo, Platform, Pressable, RefreshControl, StyleSheet, Text, View } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Toast from 'react-native-toast-message';
import { useSelector } from 'react-redux';
import { getKid, getKidAnswers, getKidStories } from '../../api/kid/kid.api';
import { getQuestionById } from '../../api/question/question.api';
import AnimatedBackground from '../../components/AnimatedBackground';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import StoryCard from '../../components/StoryCard';
import Colors from '../../constants/Colors';
import { AppNavigationProps, RootStackParamList } from '../../types';
import { AnswerType } from '../../types/answer';
import { StoryType } from '../../types/story';
import { formatAge } from '../../utils/functions/formatAge';
import useLoadData from '../../utils/hooks/useLoadData';
import useLoadDataList from '../../utils/hooks/useLoadDataList';

const KidOverviewScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "KidOverview">) => {
  const navigation = useNavigation<AppNavigationProps<"KidOverview">>();
  const kidId = route.params.kidId.toString();
  const [refreshing, setRefreshing] = useState(false);
  const { data: kidData } = useLoadData(() => getKid(route.params.kidId));
  const { data: storyData, loadData: loadStories } = useLoadData(() => getKidStories(route.params.kidId));
  const { data: answerData, loadData: loadAnswers } = useLoadDataList(() => getKidAnswers(route.params.kidId));
  const [isLoading, setLoading] = useState({ stories: true, answers: true });
  const [animationKey, setAnimationKey] = useState(0);
  const credits = useSelector(state => state.app.credits);

  useEffect(() => {
    if (kidData && !kidData.kid.onboarded) {
      navigation.navigate('EndOnboarding', {
        kidId: route.params.kidId,
        kidName: route.params.kidName,
      });
    }
  }, [kidData, navigation, route.params.kidId, route.params.kidName]);

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    try {
      await loadStories();
      await loadAnswers();
    } catch (error) {
      console.error('Error refreshing data:', error);
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Échec du rafraîchissement des données.'
      });
    } finally {
      setRefreshing(false);
    }
  }, [loadStories, loadAnswers]);

  useEffect(() => {
    if (isLoading.answers) {
      return;
    }

    if (!answerData || answerData.length === 0) {
      navigation.navigate('KidOnboarding', {
        kidId: route.params.kidId,
        kidName: route.params.kidName,
      });
    } else {
    }
  }, [answerData, isLoading.answers]);

  useFocusEffect(
    useCallback(() => {
      setAnimationKey(prevKey => prevKey + 1);
      const loadContent = async () => {
        setLoading({ stories: true, answers: true });

        await loadStories();
        await loadAnswers();

        setLoading({ stories: false, answers: false });
      };

      loadContent();
    }, [route.params.kidId])
  );

  useLayoutEffect(() => {
    if (kidData && kidData.kid) {
      const ageFormatted = formatAge(kidData.kid.age);
      const name = kidData.kid.first_name || 'Enfant';
      const fullTitle = `${name}, ${ageFormatted}`;

      const truncate = (str: string, maxLength: number) => {
        if (str.length > maxLength) {
          return `${str.substring(0, maxLength - 3)}...`;
        }
        return str;
      };

      const title = truncate(fullTitle, 15);

      navigation.setOptions({
        title: title,
      });
    }
  }, [navigation, kidData]);

  const sortedAnswers = answerData ? [...answerData].sort((a, b) => {
    return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
  }) : [];

  const sortedStories = storyData ? [...storyData.stories].sort((a, b) => {
    return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
  }) : [];

  const onAnswerSelect = async (answer: AnswerType) => {
    try {
      const question = await getQuestionById(answer.question_id);
      if (question) {
        navigation.navigate('EditKidWorld', {
          kidId: route.params.kidId,
          answer: answer,
          question: question,
          kidName: kidData?.kid.first_name ?? ''
        });
      }
    } catch (error) {
    }
  };

  const handleAddAnswer = () => {
    navigation.navigate('CreateKidWorld', {
      kidName: kidData?.kid.first_name ?? '',
      kidId: route.params.kidId,
      sectionKey: '',
      fromOnboarding: false,
    });
  };

  const onSeeAllAnswers = () => {
    navigation.navigate('KidWorld', {
      kidId: route.params.kidId,
      kidName: kidData?.kid.first_name ?? '',
    });
  };

  const renderStoryItem = ({ item, index }: ListRenderItemInfo<StoryType>) => {
    if (!sortedStories) return null;

    return (
      <Pressable
        onPress={() => onStorySelect(item.id)}
        style={({ pressed }) => [
          {
            marginRight: index === sortedStories.length - 1 ? 0 : 7.5,
            marginLeft: index === 0 ? 20 : 7.5,
            opacity: pressed ? 0.5 : 1,
          }
        ]}
      >
        <StoryCard story={item} />
      </Pressable>
    );
  };

  const handleHapticFeedback = () => {
    if (Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    }
  };

  const onStorySelect = (storyId: number) => {
    if (!storyData) return;
    const selectedStory = storyData.stories.find(story => story.id === storyId);
    if (selectedStory) {
      handleHapticFeedback();
      navigation.navigate('StoryOverview', {
        kidId: route.params.kidId,
        storyId: storyId,
        story: selectedStory,
      });
    }
  };

  const handleAddStory = () => {
    if (credits === 0) {
      Toast.show({
        type: 'info',
        text1: 'Crédit épuisé !',
        text2: 'Il vous en faut pour créer une histoire.',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
      navigation.navigate('Credits');
    } else {
      navigation.navigate('StoryCreationFlow' as any, {
        screen: 'StoryType',
        params: {
          kidId: route.params.kidId,
          kidName: kidData?.kid.first_name ?? '',
        },
      });
    }
  };

  const onSeeAllStories = () => {
    navigation.navigate('KidStories', {
      kidId: route.params.kidId,
      kidName: kidData?.kid.first_name ?? '',
    });
  };

  type AllStoryCardProps = {
    onPress: () => void;
    marginLeft: number;
  }

  const AllStoryCard = ({ onPress, marginLeft }: AllStoryCardProps) => {
    const totalStories = storyData?.stories.length || 0;
    const remainingStories = totalStories > 5 ? totalStories - 5 : 0;
    const storyText = remainingStories === 1 ? 'Voir 1 autre histoire' : `Voir les ${remainingStories} autres histoires`;

    return (
      <Pressable
        style={[styles.allStoryCard, { marginLeft: marginLeft }]}
        onPress={onPress}
      >
        <MaterialCommunityIcons name="dots-horizontal-circle" size={60} color={Colors.text} />
        <Text style={styles.addStoryText}>{remainingStories > 0 ? storyText : 'Voir toutes les histoires'}</Text>
      </Pressable>
    );
  };

  const renderAnswers = () => {
    const displayedAnswers = sortedAnswers.slice(0, 5).map((answer, index) => (
      <Pressable
        key={index}
        onPress={() => onAnswerSelect(answer)}
        style={({ pressed }) => [
          styles.answer,
          pressed ? { opacity: 0.5 } : {},
        ]}
      >
        <Text style={{ color: Colors.text, fontFamily: 'Gilroy-Regular' }}>{answer.content}</Text>
      </Pressable>
    ));

    const seeMoreItem = (
      <Pressable
        onPress={onSeeAllAnswers}
        style={({ pressed }) => [
          styles.answer,
          pressed ? { opacity: 0.5 } : {},
        ]}
      >
        <Text style={{ color: Colors.text, fontFamily: 'Gilroy-Regular' }}>...</Text>
      </Pressable>
    );

    return (
      <View style={styles.answersContainer}>
        {displayedAnswers}
        {seeMoreItem}
      </View>
    );
  };

  return (
    <>
      <AnimatedBackground animationKey={0} />
      <ScrollView
        style={styles.container}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.paddedContainer}>
          <Pressable onPress={onSeeAllAnswers} style={styles.worldHeaderRow}>
            <Text style={styles.headerText}>Son monde</Text>
            <View style={styles.iconsContainer}>
              <MaterialCommunityIcons name="chevron-right" size={24} />
            </View>
          </Pressable>
          {isLoading.answers ? (
            <View style={styles.centeredContent}>
              <Loader />
            </View>
          ) : (

            <>
              {renderAnswers()}
              <Button
                variant="secondary"
                size="small"
                title="Ajouter un élément"
                onPress={() => handleAddAnswer()}
                style={styles.fullWidthButton}
              />
            </>
          )}
        </View>
        <Pressable onPress={onSeeAllStories} style={styles.storiesHeaderRow}>
          <Text style={styles.headerText}>
            {sortedStories.length > 1 ? `Ses ${sortedStories.length} histoires` : 'Son histoire'}
          </Text>
          <View style={styles.iconsContainer}>
            <MaterialCommunityIcons name="chevron-right" size={24} />
          </View>
        </Pressable>
        {isLoading.stories ? (
          <View style={styles.centeredContent}>
            <Loader />
          </View>
        ) : (
          <>
            <FlatList
              data={sortedStories.slice(0, 5)}
              horizontal
              keyExtractor={(item) => item.id.toString()}
              renderItem={renderStoryItem}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={[
                styles.storiesContainer,
                sortedStories.length === 1 && styles.centeredStoryContainer,
              ]}
              ListFooterComponent={() =>
                sortedStories.length > 1 ? (
                  <AllStoryCard onPress={onSeeAllStories} marginLeft={16} />
                ) : null
              }
            />
            <View style={styles.paddedContainer}>
              <Button
                variant="primary"
                title="Créer une histoire"
                onPress={handleAddStory}
              />
            </View>
          </>
        )}
        <View style={styles.divider} />
      </ScrollView>
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: 'transparent',
  },
  centeredContent: {
    height: 136,
  },
  profileSection: {
    alignItems: 'center',
    marginBottom: 20,
    width: '100%',
  },
  paddedContainer: {
    paddingHorizontal: 16,
    alignItems: 'center',
    width: '100%',
  },
  worldHeaderRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  storiesHeaderRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 20,
    width: '100%',
    paddingLeft: 16,
    paddingTop: 16,
  },
  headerText: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 18,
    marginBottom: 0,
    textAlign: 'left',
  },
  iconsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  answersContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  answer: {
    marginRight: 10,
    backgroundColor: Colors.primary10,
    borderRadius: 8,
    paddingHorizontal: 12,
    paddingVertical: 6,
    marginVertical: 5,
    borderWidth: 1,
  },
  fullWidthButton: {
    width: '100%',
    alignSelf: 'center',
    marginTop: 16,
  },
  storiesHeader: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  storiesContainer: {
    paddingRight: 16,
    paddingBottom: 8,
  },
  centeredStoryContainer: {
    justifyContent: 'center',
    flexGrow: 1,
  },
  addStoryText: {
    marginTop: 10,
    fontSize: 18,
    color: Colors.text,
  },
  allStoryCard: {
    width: 300,
    height: 300,
    borderWidth: 2,
    borderRadius: 25,
    backgroundColor: 'white',
    marginHorizontal: 8,
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 5,
    // Shadow properties for iOS
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 0,
    // Elevation for Android
    elevation: 3,
  },
  divider: {
    height: 24,
  },
});

export default KidOverviewScreen;
