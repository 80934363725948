import React from 'react';
import { StyleSheet, Text, TextStyle, View } from 'react-native';

interface CustomHeaderTitleProps {
  title: string;
  style?: TextStyle;
}

const CustomHeaderTitle = ({ title, style }: CustomHeaderTitleProps) => {
  return (
    <View style={styles.headerTitleContainer}>
      <Text style={[styles.headerTitle, style]} numberOfLines={1} ellipsizeMode="tail">
        {title}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  headerTitleContainer: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerTitle: {
    fontSize: 18,
  },
});

export default CustomHeaderTitle;
