import { MaterialCommunityIcons } from '@expo/vector-icons';
import React, { useState } from 'react';
import { Animated, Dimensions, Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Colors from '../constants/Colors';

type FAQItemProps = {
  question: string;
  answer: string;
};

const screenWidth = Dimensions.get('window').width;

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [expanded, setExpanded] = useState(false);
  const [animationHeight, setAnimationHeight] = useState(new Animated.Value(0));

  const toggleExpand = () => {
    setExpanded(!expanded);
    Animated.timing(animationHeight, {
      toValue: expanded ? 0 : 100,
      duration: 300,
      useNativeDriver: false,
    }).start();
  };

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={toggleExpand} style={styles.questionContainer}>
        <View style={styles.questionContent}>
          <Text style={styles.question}>{question}</Text>
        </View>
        <MaterialCommunityIcons
          name={expanded ? 'chevron-up' : 'chevron-down'}
          size={24}
          color="#000"
        />
      </TouchableOpacity>
      <Animated.View
        style={[
          styles.answerContainer,
          { height: animationHeight },
          expanded && { height: null },
        ]}
      >
        <View style={styles.answerTextContainer}>
        <Text style={styles.answer}>{answer}</Text>
       </View>
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginBottom: 10,
    overflow: 'hidden',
    borderRadius: 25,
    borderWidth: 2,
    borderColor: Colors.text,
    backgroundColor: 'white',
    ...(Platform.OS === 'android' && { minWidth: screenWidth - 32 }),
  },
  questionContainer: {
    paddingVertical: 16,
    paddingHorizontal: 16,
    backgroundColor: 'white',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  questionContent: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  question: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    marginRight: 10,
  },
  answerContainer: {
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  answerTextContainer : {
    paddingBottom: 16,
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  answer: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 14,
    lineHeight: 22,
  },
});

export default FAQItem;
