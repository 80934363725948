import * as Haptics from 'expo-haptics';
import React from 'react';
import { Dimensions, Platform, Pressable, StyleSheet, Text } from 'react-native';
import Colors from '../constants/Colors';

type KidCardProps = {
  id: number;
  name: string;
  emoji: string;
  age: string;
  onPress: (id: number) => void;
};

const truncate = (str: string) => {
  if (str.length > 10) {
    return str.substring(0, 10) + '...';
  }
  return str;
};

const screenWidth = Dimensions.get('window').width;

const KidCard: React.FC<KidCardProps> = ({ id, name, emoji, age, onPress }) => {
  const calculatedLeft = name.length > 10 ? screenWidth * 0.03 : 20;

  const nameStyle = {
    ...styles.name,
    left: calculatedLeft,
  };

  const handlePress = () => {
    handleHapticFeedback();
    onPress(id);
  };

  const handleHapticFeedback = () => {
    if (Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    }
  };

  return (
    <Pressable
      style={({ pressed }) => [
        styles.card,
        { opacity: pressed ? 0.5 : 1 },
      ]}
      onPress={handlePress}>
      <Text style={styles.emoji}>{emoji}</Text>
      <Text style={nameStyle}>{truncate(name)}</Text>
      <Text style={styles.age}>{age}</Text>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  card: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.white,
    borderWidth: 2,
    borderRadius: 25,
    width: 150,
    aspectRatio: 1,
    margin: 8,
    // Shadow properties for iOS
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 0,
    // Elevation for Android
    elevation: 3,
  },
  emoji: {
    fontSize: 38,
    position: 'absolute',
    top: '20%',
    alignSelf: 'center',
  },
  name: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 18,
    backgroundColor: Colors.primary,
    overflow: 'hidden',
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 4,
    transform: [{ rotate: '-4deg' }],
    position: 'absolute',
    top: '50%',
    marginTop: 15,
    left: '20%',
  },
  age: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 12,
    backgroundColor: Colors.secondary,
    overflow: 'hidden',
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 4,
    position: 'absolute',
    top: '50%',
    marginTop: 35,
    left: '50%',
  },
});

export default KidCard;
