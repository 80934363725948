import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import LottieView from 'lottie-react-native';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  KeyboardAvoidingView,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  View
} from 'react-native';
import Modal from 'react-native-modal';
import Toast from 'react-native-toast-message';
import { postAnswer } from '../../api/answer/answer.api';
import { getEmotionsQuestionById } from '../../api/question/question.api';
import { postKidStory } from '../../api/story/story.api';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import { useProgressBar } from '../../components/ProgressBarContext';
import Colors from '../../constants/Colors';
import { EmotionType } from '../../constants/Enums';
import { StoryCreationNavigationProps, StoryCreationStackParamList } from '../../types';

const CustomEmotionScreen = ({ route }: NativeStackScreenProps<StoryCreationStackParamList, "CustomEmotion">) => {
  const navigation = useNavigation<StoryCreationNavigationProps<"CustomEmotion">>();
  const { kidId, kidName, need, theme, contextual_question, questionId } = route.params;
  console.log("Screen Params:", { kidId, kidName, need, theme, contextual_question, questionId });

  const [contextual_answer, setContextualAnswer] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [contextualQuestion, setContextualQuestion] = useState('');
  const [placeholderText, setPlaceholderText] = useState('');
  const { setCurrentStep } = useProgressBar();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setCurrentStep(4);
    setContextualQuestion(contextual_question);
    fetchPlaceholderAnswer();
  }, []);

  const fetchPlaceholderAnswer = async () => {
    try {
      const questionData = await getEmotionsQuestionById(questionId);
      if (questionData) {
        const exampleAnswers = questionData.example_answers;
        const randomIndex = Math.floor(Math.random() * exampleAnswers.length);
        const randomAnswer = exampleAnswers[randomIndex];
        setPlaceholderText(randomAnswer);
      }
    } catch (error) {
      console.error('Error fetching question data:', error);
    }
  };

  const handleAnswerChange = (text: string) => {
    setContextualAnswer(text);
  };

  const handleConfirmStory = () => {
    setShowModal(false);
    handleCreateStory();
  };

  const handleCreateStory = async () => {
    if (isButtonDisabled) return;

    setIsButtonDisabled(true);

    try {
      const answerPayload = {
        question_id: questionId,
        content: contextual_answer,
      };

      await postAnswer(kidId, answerPayload);

      const storyData = {
        need,
        kid_id: kidId,
        theme,
        contextual_question: contextual_question,
        contextual_answer: contextual_answer,
        instant_generation: false,
      };

      const storyResponse = await postKidStory(storyData, kidId);
      const storyId = storyResponse.data.story.id;

      navigation.navigate('StoryGeneration', {
        kidId,
        kidName,
        need,
        theme,
        contextual_question: contextual_question,
        contextual_answer: contextual_answer,
        storyId
      });

    } catch (error) {
      console.error('Error creating story:', error);
      setIsButtonDisabled(false);
      Toast.show({
        type: 'error',
        text1: 'Error',
        text2: 'An error occurred while creating the story. Please try again later.',
        visibilityTime: 4000,
        autoHide: true,
      });
    }
  };

  const getAnimationPath = (theme: EmotionType) => {
    switch (theme) {
      case EmotionType.Joy:
        return require('../../../assets/animations/emoji-happy.json');
      case EmotionType.Anger:
        return require('../../../assets/animations/emoji-anger.json');
      case EmotionType.Sadness:
        return require('../../../assets/animations/emoji-frustration.json');
      case EmotionType.Fear:
        return require('../../../assets/animations/emoji-fear.json');
      case EmotionType.Frustration:
        return require('../../../assets/animations/emoji-frustration.json');
      case EmotionType.Jealousy:
        return require('../../../assets/animations/emoji-jealousy.json');
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Une dernière question',
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('Main' as any, {
          screen: 'KidOverview',
          params: {
            kidId,
            kidName,
          }
        })}
          style={{ marginRight: 16 }} >
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation]);

  return (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{ flex: 1 }}
      >
        <ScrollView contentContainerStyle={styles.container} keyboardShouldPersistTaps='handled'>
          <View style={styles.questionContainer}>
            <Text
              style={styles.question}
              numberOfLines={3}
              ellipsizeMode="tail"
            >
              {contextualQuestion}
            </Text>
          </View>
          <View style={styles.centeredContainer}>
            <View style={styles.animationContainer}>
              <LottieView
                source={getAnimationPath(theme as EmotionType)}
                autoPlay
                loop
                style={styles.lottieAnimation}
              />
            </View>
          </View>
          <InputField
            placeholder={placeholderText || "Votre réponse !"}
            onChangeText={handleAnswerChange}
            inputType="answer"
            maxLength={50}
            value={contextual_answer}
            autoFocus={true}
          />
          <Button
            title="Valider"
            onPress={() => setShowModal(true)}
            disabled={isButtonDisabled}
            iconRight="coin"
          />
        </ScrollView>
      </KeyboardAvoidingView>
      <Modal isVisible={showModal} >
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Une nouvelle histoire</Text>
          <Text style={styles.modalText}>
            Souhaitez-vous utiliser 1 crédit pour créer une histoire ?
          </Text>
          <Button title="Valider" variant="primary" onPress={handleConfirmStory} />
          <Button title="Annuler" variant="secondary" onPress={() => setShowModal(false)} />
        </View>
      </Modal >
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  questionContainer: {
    minHeight: 100,
    justifyContent: 'center',
    padding: 10,
  },
  question: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalContent: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  centeredContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 16,
  },
  animationContainer: {
    height: 100,
    width: 100,
  },
  lottieAnimation: {
    width: '100%',
    height: '100%',
  },
});

export default CustomEmotionScreen;
