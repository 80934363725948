import { MaterialCommunityIcons } from '@expo/vector-icons';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { CardStyleInterpolators, createStackNavigator } from '@react-navigation/stack';
import * as Haptics from 'expo-haptics';
import React, { useCallback, useState } from 'react';
import { Animated, Image, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { getUserCredits } from '../api/user/user.api';
import CustomBackButton from '../components/CustomBackButton';
import Colors from '../constants/Colors';
import { globalStyles } from '../constants/Styles';
import KidOverviewScreen from '../screens/kidProfile/KidOverviewScreen';
import KidStoriesScreen from '../screens/kidProfile/KidStoriesScreen';
import KidWorldScreen from '../screens/kidProfile/KidWorldScreen';
import AccountSettingsScreen from '../screens/main/AccountSettingsScreen';
import FamilyScreen from '../screens/main/FamilyScreen';
import HelpAndContactScreen from '../screens/main/HelpAndContactScreen';
import KidsSettingsScreen from '../screens/main/KidsSettingsScreen';
import PasswordSettingsScreen from '../screens/main/PasswordSettingsScreen';
import SettingsScreen from '../screens/main/SettingsScreen';
import StoryOverviewScreen from '../screens/storyViewer/StoryOverviewScreen';
import { updateCredits } from '../store/slices/appSlice';
import { useAppSelector } from '../store/store';
import { AppNavigationProps, RootStackParamList } from '../types';
import ParentalGate from '../components/ParentalGate';
import Modal from 'react-native-modal';

const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

type TransitionProps = {
  current: {
    progress: Animated.AnimatedInterpolation<number>;
  };
  next?: {
    progress: Animated.AnimatedInterpolation<number>;
  };
  inverted: Animated.AnimatedInterpolation<number>;
  layouts: {
    screen: {
      width: number;
      height: number;
    };
  };
};

const forSlideFromLeft = ({
  current,
  next,
  inverted,
  layouts: { screen },
}: TransitionProps) => {
  const progress = Animated.add(
    current.progress.interpolate({
      inputRange: [0, 1],
      outputRange: [0, 1],
      extrapolate: 'clamp',
    }),
    next
      ? next.progress.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 1],
        extrapolate: 'clamp',
      })
      : 0
  );

  return {
    cardStyle: {
      transform: [
        {
          translateX: Animated.multiply(
            progress.interpolate({
              inputRange: [0, 1, 2],
              outputRange: [-screen.width, 0, screen.width],
              extrapolate: 'clamp',
            }),
            inverted
          ),
        },
      ],
    },
  };
};

const StackNavigator = () => {
  const navigation = useNavigation<AppNavigationProps<'Main'>>();
  const dispatch = useDispatch();
  const credits = useAppSelector((state) => state.app.credits);
  const [isParentalGateVisible, setIsParentalGateVisible] = useState(false);
  const [destination, setDestination] = useState('');

  const fetchCredits = async () => {
    try {
      const response = await getUserCredits();
      const { credits } = response.data;
      dispatch(updateCredits(credits));
    } catch (error) {
      console.error('Failed to fetch credits:', error);
    }
  };

  useFocusEffect(
    useCallback(() => {
      fetchCredits();
    }, [])
  );

  const handleHapticFeedback = () => {
    if (Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
  };

  const handleParentalGateSuccess = () => {
    setIsParentalGateVisible(false);
    if (destination) navigation.navigate(destination);
  };

  const handleCogPress = () => {
    setDestination('Settings');
    setIsParentalGateVisible(true);
    handleHapticFeedback();
  };

  const handleCreditsPress = () => {
    setDestination('Credits');
    setIsParentalGateVisible(true);
    handleHapticFeedback();
  };

  return (
    <View style={{ flex: 1 }}>
      <Stack.Navigator
        screenOptions={{
          headerShown: true,
          headerBackImage: () => <CustomBackButton />,
          title: 'Fabulo',
          headerTitleStyle: globalStyles.headerTitleStyle,
          headerTitleAlign: 'center',
          headerTintColor: Colors.text,
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          cardStyle: { backgroundColor: Colors.primary05 },
          headerBackTitleVisible: false,
          headerStyle: {
            backgroundColor: Colors.primary05,
            borderBottomWidth: 1,
            borderBottomColor: 'black',
          },
        }}
      >
        <Stack.Screen
          name="MyFamily"
          component={FamilyScreen}
          options={{
            headerLeft: () => (
              <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 16 }}>
                <Pressable
                  style={styles.iconWrapper}
                  onPress={handleCogPress}
                >
                  <MaterialCommunityIcons name="cog-outline" size={24} color={Colors.text} />
                </Pressable>
              </View>
            ),
            headerRight: () => (
              <Pressable onPress={handleCreditsPress}>
                <View style={styles.creditsContainer}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={styles.creditsText}>
                      {credits != null ? `${credits}` : '?'}
                    </Text>
                    <Image source={require('../../assets/images/coin.png')} style={styles.coinImage} />
                  </View>
                </View>
              </Pressable>
            ),
            headerShown: true,
            headerTransparent: true,
            title: ''
          }}
        />
        <Stack.Screen
          name="KidOverview"
          component={KidOverviewScreen}
          options={{
            headerRight: () => (
              <Pressable onPress={handleCreditsPress}>
                <View style={styles.creditsContainer}>
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={styles.creditsText}>
                      {credits != null ? `${credits}` : '?'}
                    </Text>
                    <Image source={require('../../assets/images/coin.png')} style={styles.coinImage} />
                  </View>
                </View>
              </Pressable>
            ),
          }}
        />
        <Stack.Screen name="KidStories" component={KidStoriesScreen} />
        <Stack.Screen name="KidWorld" component={KidWorldScreen} />
        <Stack.Screen name="StoryOverview" component={StoryOverviewScreen} />
        <Stack.Screen
          name="Settings"
          component={SettingsScreen}
          options={{
            cardStyleInterpolator: forSlideFromLeft,
          }}
        />
        <Stack.Screen name="AccountSettings" component={AccountSettingsScreen} />
        <Stack.Screen name="PasswordSettings" component={PasswordSettingsScreen} />
        <Stack.Screen name="KidsSettings" component={KidsSettingsScreen} />
        <Stack.Screen name="HelpAndContact" component={HelpAndContactScreen} />
      </Stack.Navigator>
      <Modal isVisible={isParentalGateVisible} onBackdropPress={() => setIsParentalGateVisible(false)}>
        <ParentalGate onCorrectAnswer={handleParentalGateSuccess} onClose={() => setIsParentalGateVisible(false)} />
      </Modal>
    </View>
  );
}

const MainTabNavigator = () => {
  return (
    <Tab.Navigator
      screenOptions={({ route }) => ({
        tabBarIcon: ({ color, size }) => {
          if (route.name === 'Family') {
            return <MaterialCommunityIcons name="home" size={size} color={color} />;
          }
        },
        tabBarActiveTintColor: Colors.text,
        tabBarInactiveTintColor: Colors.grey,
      })}
    >
      <Tab.Screen
        name="Family"
        component={StackNavigator}
        options={{
          title: 'Acceuil',
          headerShown: false,
          tabBarStyle: { display: 'none' }
        }}
      />
    </Tab.Navigator>
  );
};

const styles = StyleSheet.create({
  iconWrapper: {
    marginRight: 20,
  },
  creditsContainer: {
    borderWidth: 1,
    borderColor: 'black',
    borderRadius: 20,
    paddingTop: 4,
    paddingBottom: 2,
    paddingHorizontal: 8,
    marginRight: 16,
    backgroundColor: Colors.white,
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 0,
    elevation: 3,
  },
  creditsText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 12,
    color: Colors.text,
    lineHeight: 12,
  },
  coinImage: {
    width: 16,
    height: 16,
    marginLeft: 2,
    marginTop: Platform.OS === 'android' ? 1 : 0,
    alignContent: 'center',
  },

});

export default MainTabNavigator;
