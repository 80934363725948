import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform, Pressable, ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { postAnswer } from '../../api/answer/answer.api';
import { getKidAnswers } from '../../api/kid/kid.api';
import { getQuestions } from '../../api/question/question.api';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import Loader from '../../components/Loader';
import Colors from '../../constants/Colors';
import { QuestionType, StoryCreationNavigationProps, StoryCreationStackParamList } from '../../types';

const shuffleQuestions = (questions: QuestionType[]) => {
  for (let i = questions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [questions[i], questions[j]] = [questions[j], questions[i]];
  }
  return questions;
};

const CreateWorldInstantScreen = ({ route }: NativeStackScreenProps<StoryCreationStackParamList, "InstantStoryGeneration">) => {
  const navigation = useNavigation<StoryCreationNavigationProps<"InstantStoryGeneration">>();
  const { kidId, kidName, storyId } = route.params;
  const [answer, setAnswer] = useState('');
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<QuestionType | null>(null);
  const [placeholderText, setPlaceholderText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setAnswer('');
  }, [currentQuestion]);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Une dernière chose !',
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('KidOverview', { kidId: route.params.kidId, kidName })} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation]);

  useEffect(() => {
    const fetchAnswersAndQuestions = async () => {
      try {
        const answersResponse = await getKidAnswers(kidId);
        const existingAnswers = answersResponse.data;
        const questionsResponse = await getQuestions();
        const allQuestions = questionsResponse.data.filter(question => question.question_type !== 'contextual' && question.question_type !== 'emotions' && !existingAnswers.some(answer => answer.question_id === question.id));

        const shuffledQuestions = shuffleQuestions(allQuestions);
        setQuestions(shuffledQuestions);
        setCurrentQuestion(shuffledQuestions[0]);

        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false);
      }
    };

    fetchAnswersAndQuestions();
  }, [kidId]);

  useEffect(() => {
    if (currentQuestion) {
      const randomIndex = Math.floor(Math.random() * currentQuestion.example_answers.length);
      setPlaceholderText(currentQuestion.example_answers[randomIndex]);
    }
  }, [currentQuestion]);

  const handleRefreshQuestion = () => {
    if (questions.length > 1) {
      let nextQuestions = questions.filter(q => q.id !== currentQuestion?.id);
      const shuffledQuestions = shuffleQuestions(nextQuestions);
      setCurrentQuestion(shuffledQuestions[0]);
    } else {
      Toast.show({
        type: 'info',
        text1: 'Information',
        text2: 'Toutes les questions ont été parcourues.',
      });
    }
  };

  const handleSave = async () => {
    if (answer.trim() === '') {
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Veuillez entrer une réponse avant de valider.'
      });
      return;
    }

    setIsLoading(true);
    try {
      if (currentQuestion) {
        await postAnswer(kidId, { question_id: currentQuestion.id, content: answer.trim() });
        Toast.show({
          type: 'success',
          text1: `Réponse ajoutée pour plus tard !`,
          text2: 'Découvrez maintenant sa première histoire !',
        });
        navigation.navigate('StoryOverview', { kidId, storyId });
      }
    } catch (error) {
      console.error('Error:', error);
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Erreur lors de l\'ajout de la réponse.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1, backgroundColor: Colors.primary05 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
    >
      <ScrollView
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps='handled'
      >
        <TouchableWithoutFeedback>
          <View style={{ flexGrow: 1, backgroundColor: Colors.primary05 }}>
            {isLoading ? (
              <View style={styles.loader}>
                <Loader />
              </View>
            ) : questions.length > 0 ? (
              <View>
                <Text style={styles.introText}>
                  La réponse à cette question servira pour une prochaine histoire 💡
                </Text>
                <View style={styles.questionContainer}>
                  <Text style={styles.question}
                    numberOfLines={3}
                    ellipsizeMode="tail">
                    {currentQuestion ? currentQuestion.title : ''}
                  </Text>
                </View>
                <View style={{ width: '100%' }}>
                  <InputField
                    value={answer}
                    onChangeText={setAnswer}
                    inputType="answer"
                    maxLength={50}
                    placeholder={placeholderText || 'Votre réponse !'}
                    autoFocus={true}
                  />
                  <Button title="Valider" onPress={handleSave} variant='primary' />
                  <Button
                    title="Changer de question"
                    variant='link'
                    onPress={handleRefreshQuestion}
                  />
                </View>
              </View>
            ) : (
              <View style={styles.noQuestionsContainer}>
                <Text style={styles.noQuestionsText}>
                  "Toutes les questions ont été répondues. Merci !"
                </Text>
              </View>
            )}
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingTop: 16,
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  introText: {
    fontFamily: 'Gilroy-Italic',
    fontSize: 18,
    textAlign: 'center',
    color: Colors.text,
    marginVertical: 8,
    paddingHorizontal: 16,
    fontStyle: 'italic',
  },

  question: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    textAlign: 'center',
  },
  questionContainer: {
    height: 88,
    justifyContent: 'center',
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary05,
  },
  noQuestionsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 16,
  },
  noQuestionsText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 16,
  },
});

export default CreateWorldInstantScreen;
