import { MaterialCommunityIcons } from '@expo/vector-icons';
import React from 'react';
import { StyleSheet } from 'react-native';
import Toast, { BaseToast, BaseToastProps } from 'react-native-toast-message';
import Colors from '../constants/Colors';

const createCustomToast = (backgroundColor: string, iconName: string) => (props: BaseToastProps) => (
  <BaseToast
    {...props}
    style={[styles.toast, { backgroundColor }]}
    text1Style={styles.text1}
    text2Style={styles.text2}
    renderLeadingIcon={() => (
      <MaterialCommunityIcons name={iconName} size={24} color="black" style={styles.toastIcon} />
    )}
    renderTrailingIcon={() => (
      <MaterialCommunityIcons name="close" size={16} color="black" style={styles.closeIcon} />
    )}
    onPress={() => Toast.hide()}
  />
);

const CustomToast = {
  success: createCustomToast(Colors.success, 'check-circle-outline'),
  error: createCustomToast(Colors.error, 'alert-circle-outline'),
  info: createCustomToast(Colors.primary, 'information-outline'),
};

const styles = StyleSheet.create({
  toast: {
    paddingHorizontal: 8,
    minHeight: 72,
    borderWidth: 2,
    borderColor: 'black',
    borderLeftWidth: 1,
    borderLeftColor: 'black',
    marginTop: 16,
  },
  text1: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    lineHeight: 18,
    fontWeight: '400',
    color: 'black',
  },
  text2: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 14,
    lineHeight: 16,
    color: 'black',
    paddingTop: 4,
  },
  toastIcon: {
    left: 5,
    alignSelf: 'center'
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5
  }
});


export default CustomToast;
