import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Modal from 'react-native-modal';
import Toast from 'react-native-toast-message';
import { postKidStory } from '../../api/story/story.api';
import Button from '../../components/Button';
import ItemSelector from '../../components/ItemSelector';
import { useProgressBar } from '../../components/ProgressBarContext';
import {
  AdventuresSubThemes,
  AnimalsSubThemes,
  DailyLifeSubThemes,
  FamilySubThemes,
  ProfessionsSubThemes,
  SeasonsSubThemes,
  ThemeType,
} from '../../constants/Enums';
import { globalStyles } from '../../constants/Styles';
import { PostStoryType, StoryCreationNavigationProps, StoryCreationStackParamList } from '../../types';
import Colors from '../../constants/Colors';

const SubThemeSelectionScreen = ({ route }: NativeStackScreenProps<StoryCreationStackParamList, "SubThemeSelection">) => {
  const navigation = useNavigation<StoryCreationNavigationProps<"SubThemeSelection">>();
  const { kidId, kidName, need, theme } = route.params;
  const [selectedSubTheme, setSelectedSubTheme] = React.useState<string | null>(null);
  const [selectedSubThemeLabel, setSelectedSubThemeLabel] = React.useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  const { setCurrentStep } = useProgressBar();

  useFocusEffect(
    React.useCallback(() => {
      setCurrentStep(4);
    }, [])
  );

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: `${theme}`,
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('Main' as any, {
          screen: 'KidOverview',
          params: {
            kidId,
            kidName,
          }
        })
        } style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation]);

  const getSubThemes = (theme: ThemeType) => {
    switch (theme) {
      case ThemeType.DailyLife:
        return DailyLifeSubThemes;
      case ThemeType.Family:
        return FamilySubThemes;
      case ThemeType.Adventures:
        return AdventuresSubThemes;
      case ThemeType.Professions:
        return ProfessionsSubThemes;
      case ThemeType.Animals:
        return AnimalsSubThemes;
      case ThemeType.Seasons:
        return SeasonsSubThemes;
      default:
        return {};
    }
  };

  const handleConfirmStory = () => {
    setShowModal(false);
    if (selectedSubThemeLabel) {
      CreateStory();
    } else {
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Veuillez sélectionner un sous-thème avant de valider.',
      });
    }
  };

  const CreateStory = async () => {
    if (selectedSubThemeLabel === null) {
      console.error("Selected sub-theme label is null.");
      return;
    }

    try {
      const storyPayload: PostStoryType = {
        need: need,
        theme: selectedSubThemeLabel,
        kid_id: kidId,
        instant_generation: true,
      };

      const response = await postKidStory(storyPayload, kidId);
      const storyId = response.data.story.id;

      navigation.navigate('InstantStoryGeneration', {
        kidId,
        kidName,
        storyId,
        need,
        theme
      });
    } catch (error) {
      console.error('Error creating instant story:', error);
    }
  };

  const subThemes = getSubThemes(theme);

  const renderSubThemes = () => {
    const entries = Object.entries(subThemes);
    if (entries.length > 7) {
      return (
        <>
          <ItemSelector
            selectedItem={selectedSubTheme}
            onItemSelect={(selectedKey) => {
              const entry = entries.find(([key]) => key === selectedKey);
              if (entry) {
                const [key, label] = entry;
                setSelectedSubTheme(key);
                setSelectedSubThemeLabel(label as string);
              }
            }}
            items={entries.map(([key, label]) => ({
              value: key,
              label: label as string,
            }))}
          />
          <Button
            title="Valider"
            onPress={() => {
              const selectedLabel = entries.find(([key]) => key === selectedSubTheme)?.[1];
              selectedLabel && setShowModal(true)
            }}
            iconRight="coin"
          />
        </>
      );
    } else {
      return entries.map(([key, label]) => (
        <Button
          key={key}
          title={label as string}
          variant="select"
          onPress={() => {
            console.log(`Selected sub-theme key: ${key}, label: ${label}`);
            setSelectedSubThemeLabel(label as string);
            setShowModal(true);
          }}
        />
      ));

    }
  };

  return (
    <>
      <View style={globalStyles.container}>
        {renderSubThemes()}
      </View>
      <Modal isVisible={showModal} >
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Une nouvelle histoire</Text>
          <Text style={styles.modalText}>
            Souhaitez-vous utiliser 1 crédit pour créer une histoire ?
          </Text>
          <Button title="Valider" variant="primary" onPress={handleConfirmStory} />
          <Button title="Annuler" variant="secondary" onPress={() => setShowModal(false)} />
        </View>
      </Modal >
    </>
  );
};

export default SubThemeSelectionScreen;

const styles = StyleSheet.create({
  modalContent: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
});
