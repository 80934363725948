import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React from 'react';
import { Pressable, View } from 'react-native';
import Button from '../../components/Button';
import { useProgressBar } from '../../components/ProgressBarContext';
import { ThemeType } from '../../constants/Enums';
import { globalStyles } from '../../constants/Styles';
import { StoryCreationNavigationProps, StoryCreationStackParamList } from '../../types';

const ThemeSelectionScreen = ({ route }: NativeStackScreenProps<StoryCreationStackParamList, "ThemeSelection">) => {
  const navigation = useNavigation<StoryCreationNavigationProps<"ThemeSelection">>();
  const { kidId, kidName, need } = route.params;
  console.log("Screen Params:", { kidId, kidName, need });

  const { setCurrentStep } = useProgressBar();

  useFocusEffect(
    React.useCallback(() => {
      setCurrentStep(3);
    }, [])
  );

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Sur quel thème ?',
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('Main' as any, {
          screen: 'KidOverview',
          params: {
            kidId,
            kidName,
          }
        })
        } style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation]);

  const navigateToSubThemeSelection = (theme: ThemeType) => {
    navigation.navigate('SubThemeSelection', {
      kidId,
      kidName,
      need,
      theme
    });
  };

  return (
    <View style={globalStyles.container}>
      {Object.values(ThemeType).map((theme) => (
        <Button
          variant="select"
          key={theme}
          title={theme}
          onPress={() => navigateToSubThemeSelection(theme)}
        />
      ))}
    </View>
  );
};

export default ThemeSelectionScreen;
