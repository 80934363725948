import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../../types';

export type AppState = {
  accessToken: string | undefined;
  uid: string | undefined;
  client: string | undefined;
  firstName?: string;
  lastName?: string;
  shouldLogin: boolean;
  user: User | undefined;
  selectedKidId: number | undefined;
  email?: string;
  credits: number;
};

const initialState: AppState = {
  accessToken: undefined,
  uid: undefined,
  client: undefined,
  firstName: undefined,
  lastName: undefined,
  shouldLogin: true,
  user: undefined,
  selectedKidId: undefined,
  email: undefined,
  credits: 0,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setShouldLogin: (state, action: PayloadAction<boolean>) => {
      state.shouldLogin = action.payload;
    },
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    resetAppState: (state) => {
      state.accessToken = undefined;
      state.shouldLogin = true;
      state.user = undefined;
      state.email = undefined;
    },
    signInSuccess: (
      state,
      action: PayloadAction<{
        token: string;
        client: string;
        uid: string;
        firstName: string;
        lastName: string;
        email: string;
      }>
    ) => {
      state.accessToken = action.payload.token;
      state.shouldLogin = false;
      state.client = action.payload.client;
      state.uid = action.payload.uid;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
    },
    setSelectedKidId: (state, action: PayloadAction<number | undefined>) => {
      state.selectedKidId = action.payload;
    },
    setEmail: (state, action: PayloadAction<string | undefined>) => {
      state.email = action.payload;
    },
    updateUserDetails: (state, action: PayloadAction<{ firstName: string; lastName: string; email: string }>) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
    },
    updateCredits: (state, action: PayloadAction<number>) => {
      state.credits = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const {
  setShouldLogin,
  setAccessToken,
  resetAppState,
  signInSuccess,
  setSelectedKidId,
  setEmail,
  updateUserDetails,
  updateCredits,
} = appSlice.actions;
export const appReducer = appSlice.reducer;
