import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { KeyboardAvoidingView, Platform, ScrollView, StyleSheet, Text } from 'react-native';
import Toast from 'react-native-toast-message';
import { useDispatch } from 'react-redux';
import { handleSignUp } from '../../api/auth/auth.api';
import Button from '../../components/Button';
import CriteriaText from '../../components/CriteriaText';
import InputField from '../../components/InputField';
import Loader from '../../components/Loader';
import Colors from '../../constants/Colors';
import { signInSuccess } from '../../store/slices/appSlice';
import { AppNavigationProps } from '../../types';

const SignUpScreen = () => {
  const navigation = useNavigation<AppNavigationProps<"SignIn">>();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const [passwordCriteria, setPasswordCriteria] = useState({
    containsDigit: false,
    containsUpper: false,
    containsLower: false,
    containsSpecial: false,
    minLength: false,
  });

  const updatePasswordCriteria = (password: string) => {
    setPasswordCriteria({
      containsDigit: /\d/.test(password),
      containsUpper: /[A-Z]/.test(password),
      containsLower: /[a-z]/.test(password),
      containsSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      minLength: password.length >= 6,
    });
  };

  const handleSignUpPress = async () => {
    if (!email || !password || !firstName || !lastName) {
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Veuillez remplir tous les champs'
      });
      return;
    }

    if (password !== passwordConfirmation) {
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Les mots de passe ne correspondent pas'
      });
      return;
    }

    try {
      const response = await handleSignUp(firstName, lastName, email, password, passwordConfirmation);
      console.log('Sign Up Response:', response);

      if (response.status === 'success') {
        dispatch(signInSuccess({
          token: response.token,
          client: response.client,
          uid: response.uid,
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email,
        }));
        Toast.show({
          type: 'success',
          text1: 'Inscription réussie',
          text2: 'Bienvenue dans l\'application !'
        });
      } else {
        Toast.show({
          type: 'error',
          text1: 'Erreur',
          text2: 'Échec de l’inscription'
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Erreur lors de l’inscription.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <KeyboardAvoidingView
      style={styles.keyboardAvoidingViewContainer}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
    >
      <ScrollView
        contentContainerStyle={styles.scrollViewContainer}
        keyboardShouldPersistTaps='handled'
      >
        <Text style={styles.titleText}>Bienvenue chez Fabulo 🌛!</Text>
        <InputField
          label="Prénom"
          onChangeText={setFirstName}
          inputType="name"
          value={firstName}
          placeholder="Jacques"
        />
        <InputField
          label="Nom"
          onChangeText={setLastName}
          inputType="name"
          value={lastName}
          placeholder="Dupont"
        />
        <InputField
          label="Adresse e-mail"
          onChangeText={setEmail}
          inputType="email"
          value={email}
          placeholder="jacques.dupont@mail.com"
          keyboardType="email-address"
        />
        <InputField
          label="Mot de passe"
          onChangeText={(text) => {
            setPassword(text);
            updatePasswordCriteria(text);
          }}
          inputType="password"
          value={password}
          placeholder="••••••••"
          secureTextEntry
        />
        <Text style={styles.instructionText}>
          Le mot de passe doit être de
          <CriteriaText isMet={passwordCriteria.minLength}>
            {' '}<Text style={styles.boldText}>minimum 6 caractères</Text>
          </CriteriaText>, avec au moins
          <CriteriaText isMet={passwordCriteria.containsDigit}>
            {' '}<Text style={styles.boldText}>un chiffre</Text>
          </CriteriaText>, une
          <CriteriaText isMet={passwordCriteria.containsUpper}>
            {' '}<Text style={styles.boldText}>lettre majuscule</Text>
          </CriteriaText> et un
          <CriteriaText isMet={passwordCriteria.containsSpecial}>
            {' '}<Text style={styles.boldText}>caractère spécial</Text>.
          </CriteriaText>
        </Text>
        <InputField
          label="Confirmation du mot de passe"
          onChangeText={setPasswordConfirmation}
          inputType="password"
          value={passwordConfirmation}
          placeholder="••••••••"
          secureTextEntry
        />
        {isLoading ? (
          <Loader />
        ) : (
          <Button title="S’inscrire" onPress={handleSignUpPress} />
        )}
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidingViewContainer: {
    flex: 1,
    backgroundColor: Colors.primary05,
  },
  scrollViewContainer: {
    justifyContent: 'center',
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  titleText: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    color: Colors.text,
    textAlign: 'center',
    paddingTop: 16,
  },
  instructionText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    color: Colors.text,
    marginVertical: 8,
  },
  boldText: {
    fontWeight: 'bold',
  },
});

export default SignUpScreen;
