import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Colors from '../constants/Colors';

const InfoBubble = ({ text, inverted = false }: { text: string, inverted?: boolean }) => {

  const capitalizeFirstLetter = (text: string) => {
    if (!text) return '';
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  return (
    <View style={[styles.infoBubble, inverted && styles.invertedBubble]}>
      <Text style={[styles.infoBubbleText, inverted && styles.invertedText]}>
        {capitalizeFirstLetter(text)}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  infoBubble: {
    backgroundColor: Colors.primary10,
    borderRadius: 8,
    paddingHorizontal: 8,
    paddingVertical: 4,
    marginVertical: 5,
    marginRight: 10,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  invertedBubble: {
    backgroundColor: Colors.text,
  },
  infoBubbleText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 14,
    color: Colors.text,
  },
  invertedText: {
    color: '#EAEAEA',
  },
});

export default InfoBubble;
