import { channel } from "expo-updates";

type EnvironmentConfig = {
  apiUrl: string;
  wsUrl: string;
  resetPasswordUrl: string;
};

// const dev: EnvironmentConfig = {
//   apiUrl: 'http://localhost:3000/',
//   wsUrl: 'ws://localhost:3000/cable',
//   resetPasswordUrl: 'http://localhost:3000/password_resets/edit',
// };

const dev: EnvironmentConfig = {
  apiUrl: 'https://fabulo-back-00bd7638ab28.herokuapp.com/',
  wsUrl: 'wss://fabulo-back-00bd7638ab28.herokuapp.com/cable',
  resetPasswordUrl: 'https://fabulo-back-00bd7638ab28.herokuapp.com/password_resets/edit',
};

const staging: EnvironmentConfig = {
  apiUrl: 'https://fabulo-back-00bd7638ab28.herokuapp.com/',
  wsUrl: 'wss://fabulo-back-00bd7638ab28.herokuapp.com/cable',
  resetPasswordUrl: 'https://fabulo-back-00bd7638ab28.herokuapp.com/password_resets/edit',
};

// const staging: EnvironmentConfig = {
//   apiUrl: 'https://staging-fabulo-back-292641a9b638.herokuapp.com/',
//   wsUrl: 'wss://staging-fabulo-back-292641a9b638.herokuapp.com/cable',
//   resetPasswordUrl: 'https://staging-fabulo-back-292641a9b638.herokuapp.com/password_resets/edit',
// };

const prod: EnvironmentConfig = {
  apiUrl: 'https://fabulo-back-00bd7638ab28.herokuapp.com/',
  wsUrl: 'wss://fabulo-back-00bd7638ab28.herokuapp.com/cable',
  resetPasswordUrl: 'https://fabulo-back-00bd7638ab28.herokuapp.com/password_resets/edit',
};

console.log("Current Channel:", channel);
console.log("Is __DEV__ true?:", __DEV__);

const getEnvironment = () => {
  if (channel && channel.startsWith("production")) {
    return prod;
  }
  return staging;
};

const environment = __DEV__ ? dev : getEnvironment();

console.log("Selected Environment:", environment);

export default environment;
