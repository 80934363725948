import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../api/user/user.api';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import WalkingDogAnimation from '../../components/WalkingDogAnimation';
import Colors from '../../constants/Colors';
import { updateUserDetails } from '../../store/slices/appSlice';
import { useAppSelector } from '../../store/store';
import handleApiError from '../../utils/functions/apiErrorHandling';

const AccountSettingsScreen = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const userFirstName = useAppSelector((state) => state.app.firstName);
  const userLastName = useAppSelector((state) => state.app.lastName);
  const userEmail = useAppSelector((state) => state.app.email);

  const moveAnimation = new Animated.Value(0);

  const startAnimation = useCallback(() => {
    moveAnimation.setValue(0);
    Animated.timing(moveAnimation, {
      toValue: 1,
      duration: 11000,
      useNativeDriver: true,
    }).start(() => startAnimation());
  }, []);

  useEffect(() => {
    startAnimation();
  }, [startAnimation]);


  useEffect(() => {
    setFirstName(userFirstName || '');
    setLastName(userLastName || '');
    setEmail(userEmail || '');
  }, [userFirstName, userLastName, userEmail]);

  const handleSaveUserChanges = async () => {
    try {
      const updatedUserData = {
        first_name: firstName,
        last_name: lastName,
        email: email.toLowerCase(),
      };

      const response = await updateUser(updatedUserData);
      if (response.status === 'success') {
        dispatch(updateUserDetails({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          email: response.data.email,
        }));

        Toast.show({
          type: 'success',
          text1: 'Succès',
          text2: 'Les informations de votre compte ont été mises à jour avec succès.',
        });

        navigation.goBack();

      } else {
        Toast.show({
          type: 'error',
          text1: 'Erreur',
          text2: 'Une erreur inattendue est survenue. Veuillez réessayer.',
        });
      }
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Impossible de mettre à jour les informations du compte. Veuillez réessayer.',
      });
      handleApiError(error);
    }
  };

  useLayoutEffect(() => {

    const fullTitle = `Mes informations personnelles`;
    const truncate = (str: string, maxLength: number) => {
      if (str.length <= maxLength) return str;
      return `${str.substring(0, maxLength - 3)}...`;
    };
    const truncatedTitle = truncate(fullTitle, 27);

    navigation.setOptions({
      title: truncatedTitle,
    });
  }, [navigation]);

  return (
    <View style={styles.container}>
      <View style={styles.divider} />
      <InputField
        label="Prénom"
        value={firstName}
        onChangeText={setFirstName}
        inputType="name"
        placeholder="Jacques"
      />
      <InputField
        label="Nom"
        value={lastName}
        onChangeText={setLastName}
        inputType="name"
        placeholder="Dupont"
      />
      <InputField
        label="Email"
        value={email}
        onChangeText={setEmail}
        inputType="email"
        placeholder="votreemail@example.com"
      />
      <Button title="Sauvegarder" onPress={handleSaveUserChanges} />
      <WalkingDogAnimation />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  divider: {
    height: 24,
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default AccountSettingsScreen;
