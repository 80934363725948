import { CardStyleInterpolators, StackNavigationOptions, createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import CustomBackButton from '../components/CustomBackButton';
import Colors from '../constants/Colors';
import { globalStyles } from '../constants/Styles';
import PasswordResetScreen from '../screens/auth/PasswordResetScreen';
import SignInScreen from '../screens/auth/SignInScreen';
import SignUpScreen from '../screens/auth/SignUpScreen';
import WelcomeScreen from '../screens/auth/WelcomeScreen';

const Stack = createStackNavigator();

const commonOptions = {
  gestureEnabled: true,
  gestureDirection: 'horizontal',
  cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
  cardStyle: { backgroundColor: Colors.primary05 },
  headerBackImage: () => <CustomBackButton />,
  headerTitleAlign: 'center',
  headerTitleStyle: globalStyles.headerTitleStyle,
  headerTintColor: Colors.text,
  headerBackTitleVisible: false,
  headerStyle: {
    backgroundColor: Colors.primary05,
    borderBottomWidth: 1,
    borderBottomColor: 'black',
  },
};

const AuthNavigator: React.FC = () => (
  <Stack.Navigator
    screenOptions={commonOptions as StackNavigationOptions}
  >
    <Stack.Screen
      name="Welcome"
      component={WelcomeScreen}
      options={{ headerShown: false }}
    />
    <Stack.Screen
      name="SignUp"
      component={SignUpScreen}
      options={{ title: 'Créer un compte' }}
    />
    <Stack.Screen
      name="SignIn"
      component={SignInScreen}
      options={{ title: 'Se connecter' }}
    />
    <Stack.Screen
      name="PasswordReset"
      component={PasswordResetScreen}
      options={{ title: 'Mot de passe' }}
    />
  </Stack.Navigator>
);

export default AuthNavigator;
