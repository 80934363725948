import LottieView from 'lottie-react-native';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';

// Image animations
import Brush from '../../assets/animations/story_gen/image/brush.json';
import CrayonsPastels from '../../assets/animations/story_gen/image/crayons-pastels.json';
import DaVinci from '../../assets/animations/story_gen/image/da-vinci.json';
import Easel from '../../assets/animations/story_gen/image/easel.json';
import PaintBrush from '../../assets/animations/story_gen/image/paint-brush.json';
import Painter from '../../assets/animations/story_gen/image/painter.json';
import Watercolor from '../../assets/animations/story_gen/image/watercolor.json';

// Text animations
import ArtificialIntelligence from '../../assets/animations/story_gen/text/artificial-intelligence.json';
import EditDocument from '../../assets/animations/story_gen/text/edit-document.json';
import Edit from '../../assets/animations/story_gen/text/edit.json';
import Inkwell from '../../assets/animations/story_gen/text/inkwell.json';
import Keyboard from '../../assets/animations/story_gen/text/keyboard.json';
import Newspaper from '../../assets/animations/story_gen/text/newspaper.json';
import Poetry from '../../assets/animations/story_gen/text/poetry.json';
import TextBox from '../../assets/animations/story_gen/text/text-box.json';
import WritingMachine from '../../assets/animations/story_gen/text/writing-machine.json';

// Voice animations
import Microphone from '../../assets/animations/story_gen/voice/microphone.json';
import OldMicrophone from '../../assets/animations/story_gen/voice/old-microphone.json';
import PersonTalking from '../../assets/animations/story_gen/voice/person-talking.json';
import Recording from '../../assets/animations/story_gen/voice/recording.json';
import SingingVocal from '../../assets/animations/story_gen/voice/singing-vocal.json';
import Vocalist from '../../assets/animations/story_gen/voice/vocalist.json';
import Colors from '../constants/Colors';
import BreathingCircle from './BreathingCircle';

type StepType = 'text' | 'voice' | 'image';

const animationGroups: Record<StepType, any[]> = {
  text: [
    ArtificialIntelligence,
    EditDocument,
    Edit,
    Inkwell,
    Keyboard,
    Newspaper,
    Poetry,
    TextBox,
    WritingMachine,
  ],
  voice: [
    Microphone,
    OldMicrophone,
    PersonTalking,
    Recording,
    SingingVocal,
    Vocalist,
  ],
  image: [
    Brush,
    CrayonsPastels,
    DaVinci,
    Easel,
    PaintBrush,
    Painter,
    Watercolor,
  ],
};

const stepsText: Record<StepType, string> = {
  text: "J'écris l'histoire",
  voice: "J'enregistre la voix",
  image: "Je dessine l'illustration",
};

interface AnimatedStoryStepProps {
  stepType: StepType;
}

export const AnimatedStoryStep: React.FC<AnimatedStoryStepProps> = ({ stepType }) => {
  const [animation, setAnimation] = useState<any>(null);

  useEffect(() => {
    const animations = animationGroups[stepType];
    const randomAnimation = animations[Math.floor(Math.random() * animations.length)];
    setAnimation(randomAnimation);
  }, [stepType]);

  return (
    <View style={styles.container}>
      <BreathingCircle size={150} style={StyleSheet.absoluteFillObject} />
      {animation && (
        <LottieView
        source={animation}
        autoPlay
        loop
        style={styles.animation} />
      )}
      <Text style={styles.text}>{stepsText[stepType]}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 150,
    width: 400,
    alignSelf: 'center',
    marginBottom: 96,
  },
  animation: {
    height: '100%',
    width: '100%',
  },
  text: {
    fontFamily: 'GochiHandRegular',
    textAlign: 'center',
    fontSize: 32,
    marginTop: 16,
    paddingTop: 32,
    color: Colors.text,
  },
});

export default AnimatedStoryStep;
