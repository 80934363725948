import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import LottieView from 'lottie-react-native';
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Image, Platform, Pressable, ScrollView, Share, StyleSheet, Text, View } from 'react-native';
import Modal from 'react-native-modal';
import Toast from 'react-native-toast-message';
import { deleteStory, getStory, sendFeedback, voteOnStory } from '../../api/story/story.api';
import AnimatedBackground from '../../components/AnimatedBackground';
import Button from '../../components/Button';
import InfoBubble from '../../components/InfoBubble';
import InputField from '../../components/InputField';
import Loader from '../../components/Loader';
import Colors from '../../constants/Colors';
import { useAppSelector } from '../../store/store';
import { AppNavigationProps, RootStackParamList } from '../../types';
import { StoryType } from '../../types/story';
import { formatDuration } from '../../utils/functions/formatDuration';
import * as Haptics from 'expo-haptics';

const StoryOverviewScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "StoryOverview">) => {
  const navigation = useNavigation<AppNavigationProps<"StoryOverview">>();
  const [story, setStory] = useState<StoryType | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [hasBeenRead, setHasBeenRead] = useState(false);
  const [hasVoted, setHasVoted] = useState(false);
  const [feedbackText, setFeedbackText] = useState('');
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isVoteModalVisible, setVoteModalVisible] = useState(false);
  const [isShareModalVisible, setShareModalVisible] = useState(false);
  const [isFeedbackModalVisible, setFeedbackModalVisible] = useState(false);
  const [modalContentType, setModalContentType] = useState('vote');
  const [imageLoading, setImageLoading] = useState(true);
  const [showFeedbackModalDelayed, setShowFeedbackModalDelayed] = useState(false);
  const readStories = useAppSelector((state) => state.readStories.readStories);
  const storyId = route.params.storyId;
  const [isImageFullscreen, setImageFullscreen] = useState(false);
  const [renderFullscreenImage, setRenderFullscreenImage] = useState(false);
  const formattedDate = story?.created_at ? new Date(story.created_at).toLocaleDateString('fr-FR') : '';

  useEffect(() => {
    if (isImageFullscreen) {
      setRenderFullscreenImage(true); // Render the image when the modal is open
    }
  }, [isImageFullscreen]);

  const handleHapticFeedback = () => {
    if (Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    }
  };

  const handleCloseFullscreen = () => {
    handleHapticFeedback();
    setImageFullscreen(false);
    // Delay the removal of the image from the DOM to allow the modal to close smoothly
    setTimeout(() => setRenderFullscreenImage(false), 300); // Adjust the timeout to match the modal's animation duration
  };

  const cloudName = 'dzcevvxsn';
  const transformationString = 'w_600,h_600,c_fill,g_auto';
  const imagePublicId = story?.image_public_id;
  const imageFormat = 'jpg';
  const imageSource = imagePublicId
    ? { uri: `https://res.cloudinary.com/${cloudName}/image/upload/${transformationString}/${imagePublicId}.${imageFormat}` }
    : require('../../../assets/images/story_placeholder_square.png');

  const onShare = async () => {
    try {
      const shareMessage = `*Fabulo présente ✨ : ${story?.title}*\n\n${story?.content}\n\n_À votre tour de créer et d'écouter votre histoire personnalisée sur www.fabulo.app !_`;
      const shareResponse = await Share.share({
        message: shareMessage,
      });
      console.log("Share response:", shareResponse);
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const fetchStoryDetails = async () => {
    try {
      const { kidId, storyId } = route.params;
      if (kidId && storyId) {
        const response = await getStory(kidId, storyId);
        // console.log("Fetched story details:", response.data);
        console.log("Audio duration from API:", response.data.audio_duration);
        setStory(response.data);
        setHasVoted(response.data.vote !== 'none');
      }
    } catch (error) {
      console.error('Error fetching story:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeStoryData = async () => {
      await fetchStoryDetails();
      setTimeout(() => setImageLoading(false), 1000);
    };

    initializeStoryData();
  }, [storyId]);

  useEffect(() => {
    if (Platform.OS === 'android' && isFeedbackModalVisible) {
      // Delay modal display on Android
      const timer = setTimeout(() => {
        setShowFeedbackModalDelayed(true);
      }, 500); // Adjust delay as needed

      return () => clearTimeout(timer);
    } else if (!isFeedbackModalVisible) {
      setShowFeedbackModalDelayed(false);
    }
  }, [isFeedbackModalVisible]);

  useFocusEffect(
    React.useCallback(() => {
      const storyHasBeenRead = readStories[storyId];
      const storyHasVote = story?.vote !== 'none';

      if (storyHasBeenRead && !storyHasVote) {
        setVoteModalVisible(true);
      }
    }, [storyId, readStories, story?.vote])
  );

  const removeEmojiFromTheme = (themeWithEmoji: string) => {
    return themeWithEmoji.split(' ').slice(1).join(' ');
  };

  const storyDetails = useMemo(() => {
    // Array to hold details including the processed theme
    const details = [];

    // If theme exists, process it to remove emoji
    if (story?.theme) {
      details.push(removeEmojiFromTheme(story.theme));
    }

    // Add other details that do not require processing
    if (story?.random_answer) details.push(story.random_answer);
    if (story?.contextual_answer) details.push(story.contextual_answer);
    if (story?.audio_duration) details.push(formatDuration(story.audio_duration));

    return details;
  }, [story]);

  // console.log('Story duration:', story?.audio_duration);

  const getRandomReaderBackgroundColor = () => {
    const randomIndex = Math.floor(Math.random() * Colors.readerBackgroundColors.length);
    return Colors.readerBackgroundColors[randomIndex];
  };

  const createStoryContinuation = () => {
    Toast.show({
      type: 'info',
      text1: 'Bientôt disponible ⌚️!',
      text2: 'Cette fonctionnalité arrive bientôt.',
    });
  };

  const handleDeletePress = () => {
    setDeleteModalVisible(true);
  };

  const handleThumbsUp = async (fromVoteModal = false) => {
    console.log('Thumbs up pressed');
    if (story?.kid_id !== undefined && story?.id !== undefined) {
      try {
        await voteOnStory(story?.kid_id, story?.id, 'up');
        setHasVoted(true);
        if (!isFeedbackModalVisible && fromVoteModal) {
          setModalContentType('share');
          setShareModalVisible(true);
        } else if (!fromVoteModal) {
          setFeedbackModalVisible(true);
        }
        if (fromVoteModal) {
          setVoteModalVisible(false);
        }
      } catch (error) {
        console.error('Error sending upvote:', error);
      }
    }
  };

  const handleShareModalClose = () => {
    setShareModalVisible(false);

    Toast.show({
      type: 'success',
      text1: 'Merci !',
      text2: 'Ravi que cette histoire vous ait plu 🙂',
    });
  };

  const handleThumbsDown = async (fromVoteModal = false) => {
    if (story?.kid_id !== undefined && story?.id !== undefined) {
      try {
        await voteOnStory(story?.kid_id, story?.id, 'down');
        setHasVoted(true);
        if (!isFeedbackModalVisible && fromVoteModal) {
          setModalContentType('feedback');
          setFeedbackModalVisible(true);
          setFeedbackText('');
        } else if (!fromVoteModal) {
          setFeedbackModalVisible(true);
        }
        if (fromVoteModal) {
          setVoteModalVisible(false);
        }
      } catch (error) {
        console.error('Error sending downvote:', error);
      }
    }
  };

  const handleSendFeedback = async () => {
    if (story?.kid_id !== undefined && story?.id !== undefined) {
      try {
        await sendFeedback(story.kid_id, story.id, feedbackText);
        setFeedbackModalVisible(false);
        setModalContentType('vote');
        setHasVoted(true);
        Toast.show({
          type: 'success',
          text1: 'Merci pour votre retour,',
          text2: 'Nous en tiendrons compte 🙏'
        });
      } catch (error) {
        console.error('Error sending feedback:', error);
      }
    }
  };

  const confirmDeletion = async () => {
    if (story && story.id) {
      try {
        await deleteStory(story.kid_id, story.id);
        console.log('Story deleted successfully');
        setDeleteModalVisible(false);
        navigation.navigate('KidOverview', { kidId: story.kid_id, kidName: story.kid_name });
      } catch (error) {
        console.error('Error deleting story:', error);
        Toast.show({
          type: 'error',
          text1: 'Erreur',
          text2: "Impossible de supprimer l'histoire.",
        });
      }
    } else {
      console.log('Story details are missing');
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Détails de l’histoire manquants.',
      });
    }
  };

  useLayoutEffect(() => {
    const truncatedTitle = story?.title ? story.title.slice(0, 23) + (story.title.length > 23 ? '...' : '') : '';
    navigation.setOptions({
      title: truncatedTitle,
      headerRight: () => (
        <Pressable onPress={onShare} style={({ pressed }) => ({ opacity: pressed ? 0.5 : 1, marginRight: 16 })}>
          <MaterialCommunityIcons name="share-variant-outline" size={24} color={Colors.text} />
        </Pressable>
      ),
    });
  }, [navigation, story?.title, onShare]);

  return (
    <>
      <AnimatedBackground animationKey={0} />
      {isLoading ? (
        <View style={styles.loaderContainer}>
          <Loader />
        </View>
      ) : (
        <ScrollView style={styles.container}>
          <View style={styles.card}>
            <View style={styles.imageContainer}>
              {imageLoading && (
                <Loader style={styles.imageLoader} />
              )}
              <Pressable onPress={() => { setImageFullscreen(true); handleHapticFeedback(); }}>
                {imageLoading && (
                  <Loader style={styles.imageLoader} />
                )}
                <Image
                  source={imageSource}
                  style={styles.storyImage}
                  onLoadEnd={() => setImageLoading(false)}
                />
              </Pressable>
            </View>
            <View style={styles.contentContainer}>
              <View style={styles.dateNeedContainer}>
                <Text style={styles.dateText}>
                  {formattedDate}
                </Text>
                <Text style={styles.needText}>
                  {story?.need}
                </Text>
              </View>
              <Text style={styles.title} numberOfLines={3} ellipsizeMode='tail'>
                {story?.title}
              </Text>
              <Text style={styles.synopsis} numberOfLines={3} ellipsizeMode='tail'>
                {story?.synopsis}
              </Text>
              <View style={styles.infoContainer}>
                {storyDetails.map((detail, index) => detail ? (
                  <InfoBubble key={index} text={detail.toString()} />
                ) : null)}
              </View>
              <View style={styles.buttonRow}>
                <Button
                  title="Lire"
                  onPress={() => {
                    navigation.navigate('StoryReadPlayback', {
                      story: story,
                      audioUrl: story?.audio_url ?? null,
                      backgroundColor: getRandomReaderBackgroundColor(),
                      artworkUrl: imageSource.uri,
                      onReturn: () => setHasBeenRead(true)
                    });
                  }}
                  variant="primary"
                  size="small"
                  style={styles.flexButton}
                />
                <Button
                  title="Créer la suite"
                  onPress={createStoryContinuation}
                  variant="secondary"
                  size="small"
                  style={[styles.flexButton, styles.secondButton]}
                />
              </View>
            </View>
          </View>
          <Button
            title="Supprimer"
            onPress={handleDeletePress}
            variant="danger"
            size="small"
            style={styles.flexButton}
          />
          <Modal isVisible={isVoteModalVisible || isFeedbackModalVisible || isShareModalVisible}>
            {modalContentType === 'vote' && (
              <VoteModal
                isVisible={isVoteModalVisible}
                onThumbsUp={() => handleThumbsUp(true)}
                onThumbsDown={() => handleThumbsDown(true)}
                onClose={() => setVoteModalVisible(false)}
              />
            )}
            {modalContentType === 'feedback' && (
              <FeedbackModal
                isVisible={isFeedbackModalVisible}
                backdropOpacity={Platform.OS === 'android' ? 0 : 0.5}
                feedbackText={feedbackText}
                setFeedbackText={setFeedbackText}
                onSubmit={handleSendFeedback}
                onCancel={() => {
                  setFeedbackModalVisible(false);
                  setModalContentType('vote');
                }}
              />
            )}
            {modalContentType === 'share' && (
              <ShareModal
                isVisible={isShareModalVisible}
                onShare={onShare}
                onClose={handleShareModalClose}
              />
            )}
          </Modal>
          <Modal isVisible={isDeleteModalVisible} backdropOpacity={Platform.OS === 'android' ? 0 : 0.5}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Confirmer la suppression</Text>
              <Text style={styles.modalText}>
                Êtes-vous sûr de vouloir supprimer cette histoire ?
              </Text>
              <Button title="Supprimer" variant="tertiary" onPress={confirmDeletion} />
              <Button title="Annuler" variant="secondary" onPress={() => setDeleteModalVisible(false)} />
            </View>
          </Modal>
          <Modal
            isVisible={isImageFullscreen}
            onBackdropPress={handleCloseFullscreen}
            style={styles.fullscreenModal}
            useNativeDriver={true}
            onModalHide={() => setRenderFullscreenImage(false)}
            backdropOpacity={Platform.OS === 'android' ? 0 : 0.7}
          >
            <Pressable onPress={handleCloseFullscreen} style={styles.fullscreenPressable}>
              {renderFullscreenImage && (
                <Image
                  source={imageSource}
                  style={styles.fullscreenImage}
                  resizeMode="contain"
                />
              )}
            </Pressable>
          </Modal>
        </ScrollView>
      )}
    </>
  );
}

type VoteModalProps = {
  isVisible: boolean;
  onThumbsUp: () => void;
  onThumbsDown: () => void;
  onClose: () => void;
};

const VoteModal = ({ isVisible, onThumbsUp, onThumbsDown, onClose }: VoteModalProps) => (
  <Modal isVisible={isVisible} backdropOpacity={Platform.OS === 'android' ? 0 : 0.5}>
    <View style={styles.modalContent}>
      <Text style={styles.modalTitle}>Votre avis sur l'histoire</Text>
      <View style={styles.modalThumbsContainer}>
        <Pressable onPress={onThumbsUp} style={styles.modalThumb}>
          <LottieView
            source={require('../../../assets/animations/thumb-up.json')}
            autoPlay
            loop={false}
            style={styles.lottieAnimation}
          />
        </Pressable>
        <Pressable onPress={onThumbsDown} style={[styles.modalThumb, { marginLeft: 64 }]}>
          <LottieView
            source={require('../../../assets/animations/thumb-down.json')}
            autoPlay
            loop={false}
            style={styles.lottieAnimation}
          />
        </Pressable>
      </View>
      <Button title="Fermer" variant="secondary" onPress={onClose} />
    </View>
  </Modal>
);

type FeedbackModalProps = {
  isVisible: boolean;
  backdropOpacity: number;
  feedbackText: string;
  setFeedbackText: (text: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
};

const FeedbackModal = ({ isVisible, feedbackText, setFeedbackText, onSubmit, onCancel }: FeedbackModalProps) => (
  <Modal isVisible={isVisible} backdropOpacity={Platform.OS === 'android' ? 0 : 0.5}>
    <View style={styles.modalContent}>
      <Text style={styles.modalTitle}>Votre avis</Text>
      <Text style={styles.modalText}>
        Pourquoi cette histoire ne vous a-t-elle pas plu ?
      </Text>
      <InputField
        placeholder="Votre avis"
        value={feedbackText}
        onChangeText={setFeedbackText}
        inputType="answer"
        autoFocus={true}
      />
      <Button title="Envoyer" variant="primary" onPress={onSubmit} />
      <Button title="Annuler" variant="secondary" onPress={onCancel} />
    </View>
  </Modal>
);

type ShareModalProps = {
  isVisible: boolean;
  onShare: () => void;
  onClose: () => void;
};

const ShareModal = ({ isVisible, onShare, onClose }: ShareModalProps) => (
  <Modal isVisible={isVisible} backdropOpacity={Platform.OS === 'android' ? 0 : 0.5} onBackdropPress={() => setImageFullscreen(false)}>
    <View style={styles.modalContent}>
      <Text style={styles.modalTitle}>Partagez l'histoire</Text>
      <Text style={styles.modalText}>
        Souhaitez-vous partager cette histoire avec vos amis ou votre famille ?
      </Text>
      <Button title="Partager" onPress={onShare} />
      <Button title="Fermer" variant="secondary" onPress={onClose} />
    </View>
  </Modal>
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
  card: {
    backgroundColor: 'white',
    borderRadius: 25,
    margin: 10,
    borderWidth: 2,
    // Shadow properties for iOS
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 0,
    // Elevation for Android
    elevation: 3,
  },
  contentContainer: {
    borderTopWidth: 2,
    paddingVertical: 8,
    paddingHorizontal: 20,
  },
  title: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 8,
  },
  synopsis: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 14,
    color: Colors.grey1,
  },
  readButtonText: {
    color: 'white',
    fontSize: 18,
  },
  imageContainer: {
    borderTopLeftRadius: 23,
    borderTopRightRadius: 23,
    overflow: 'hidden',
  },
  imageLoader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  storyImage: {
    width: '100%',
    height: 300,
  },
  buttonRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  flexButton: {
    flex: 1,
  },
  flexButtonLast: {
    flex: 1,
    marginBottom: 32,
  },
  firstButton: {
    marginRight: 10,
  },
  secondButton: {
    marginStart: 8,
  },
  infoContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  modalContent: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary05,
    height: '100%',
  },
  dateNeedContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    width: '100%',
    marginBottom: 8,
    paddingTop: Platform.select({ android: 8, ios: 0 }),
  },
  dateText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 14,
    color: Colors.grey1,
  },
  needText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 14,
    color: Colors.grey1,
  },
  modalThumbsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20,
  },
  modalThumb: {
    width: 75,
    height: 75,
    alignSelf: 'center',
  },
  lottieAnimation: {
    height: '100%',
    width: '100%',
  },
  iconsInnerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '50%',
  },
  fullscreenModal: {
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullscreenPressable: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullscreenImage: {
    width: '100%',
    height: '100%',
    resizeMode: 'contain',
  },
});

export default StoryOverviewScreen;
function setImageFullscreen(arg0: boolean): void {
  throw new Error('Function not implemented.');
}
