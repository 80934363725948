import React, { forwardRef, useState } from 'react';
import { KeyboardTypeOptions, Platform, ReturnKeyTypeOptions, StyleSheet, Text, TextInput, TextInputProps, View } from 'react-native';
import Colors from '../constants/Colors';

interface InputFieldProps extends TextInputProps {
  label?: string;
  placeholder?: string;
  value: string;
  onChangeText: (text: string) => void;
  maxLength?: number;
  onFocus?: () => void;
  onBlur?: () => void;
  keyboardType?: KeyboardTypeOptions;
  returnKeyType?: ReturnKeyTypeOptions;
  onSubmitEditing?: () => void;
  autoFocus?: boolean;
  inputType?: 'email' | 'name' | 'answer' | 'password';
}

interface ExtraProps {
  textContentType?: 'none' | 'URL' | 'addressCity' | 'addressCityAndState' | 'addressState' | 'countryName' | 'name' | 'password' | 'newPassword' | 'oneTimeCode' | 'username' | 'emailAddress';
  autoCompleteType?: 'off' | 'username' | 'password' | 'email' | 'name' | 'tel' | 'street-address' | 'postal-code' | 'cc-number' | 'cc-csc' | 'cc-exp' | 'cc-exp-month' | 'cc-exp-year';
}

const InputField = forwardRef<TextInput, InputFieldProps>(({
  label,
  placeholder,
  value,
  onChangeText,
  maxLength,
  onFocus,
  onBlur,
  keyboardType = 'default',
  returnKeyType,
  onSubmitEditing,
  autoFocus = false,
  inputType = 'name',
  ...props
}, ref) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleTextChange = (text: string) => {
    let modifiedText = text;

    if (inputType === 'name' || inputType === 'answer') {
      modifiedText = text.charAt(0).toUpperCase() + text.slice(1);
    }

    if (inputType === 'email') {
      modifiedText = text.toLowerCase();
    }

    onChangeText(modifiedText);
  };

  const secureTextEntry = inputType === 'password';

  const extraProps: ExtraProps = {};
  if (inputType === 'password') {
    extraProps.textContentType = 'password';
    extraProps.autoCompleteType = 'password';
  }

  return (
    <View style={styles.fieldContainer}>
      {label && <Text style={styles.label}>{label}</Text>}
      <TextInput
        style={[styles.input, isFocused && { borderColor: Colors.text }]}
        value={value}
        onChangeText={handleTextChange}
        placeholder={placeholder}
        maxLength={maxLength}
        onFocus={() => {
          setIsFocused(true);
          if (onFocus) onFocus();
        }}
        onBlur={() => {
          setIsFocused(false);
          if (onBlur) onBlur();
        }}
        keyboardType={keyboardType}
        placeholderTextColor={Colors.grey}
        returnKeyType={returnKeyType}
        onSubmitEditing={onSubmitEditing}
        ref={ref}
        autoFocus={autoFocus}
        selectionColor={Colors.primary}
        autoCapitalize={inputType === 'email' ? 'none' : 'sentences'}
        secureTextEntry={secureTextEntry}
        {...extraProps}
        {...props}
      />
    </View>
  );
});

const styles = StyleSheet.create({
  fieldContainer: {
    width: '100%',
    alignItems: 'flex-start',
  },
  input: {
    height: 48,
    width: '100%',
    marginVertical: 8,
    paddingVertical: 8,
    paddingHorizontal: 24,
    borderRadius: 40,
    backgroundColor: 'white',
    borderColor: Colors.grey,
    borderWidth: 2,
    fontSize: 16,
    lineHeight: 20,
    textAlign: 'left',
    ...(Platform.OS !== 'ios' && { outlineStyle: 'none' })
  },
  label: {
    fontSize: 14,
    color: Colors.grey1,
    marginTop: 8,
    fontWeight: 'bold',
  },
});

export default InputField;
