import { MaterialCommunityIcons } from '@expo/vector-icons';
import { Audio, AVPlaybackStatus } from 'expo-av';
import React, { ForwardedRef, useEffect, useImperativeHandle, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Slider } from 'react-native-elements';
import Modal from 'react-native-modal';
import Colors from '../constants/Colors';
import { setAdviceModalShown } from '../store/slices/storiesSlice';
import { useAppDispatch, useAppSelector } from '../store/store';
import Button from './Button';
import Loader from './Loader';

type AudioPlayerProps = {
  jingleUri: string;
  storyUri: string;
  musicUri: string;
  backgroundColor: string;
  storyId: number;
};

type AudioPlayerMethods = {
  stopAllAudio: () => void;
};

type LoadSoundCallback = (sound: Audio.Sound) => void;

const AudioPlayer = React.forwardRef<AudioPlayerMethods, AudioPlayerProps>(
  ({ jingleUri, storyUri, musicUri, backgroundColor, storyId }, ref: ForwardedRef<AudioPlayerMethods>) => {
    const [jingle, setJingle] = useState<Audio.Sound | null>(null);
    const [story, setStory] = useState<Audio.Sound | null>(null);
    const [music, setMusic] = useState<Audio.Sound | null>(null);
    const [playbackStatus, setPlaybackStatus] = useState<AVPlaybackStatus | null>(null);
    const [isStoryLoaded, setIsStoryLoaded] = useState(false);
    const [isMusicLoaded, setIsMusicLoaded] = useState(false);
    const [isJinglePlaying, setIsJinglePlaying] = useState(false);
    const [jinglePlayed, setJinglePlayed] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [sliderPosition, setSliderPosition] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [volume, setVolume] = useState(1);
    const dispatch = useAppDispatch();
    const [isAdviceModalVisible, setIsAdviceModalVisible] = useState(false);
    const adviceModalShown = useAppSelector(state => state.readStories.adviceModalShown[storyId]);

    const formatTime = (millis: number) => {
      const minutes = Math.floor(millis / 60000);
      const seconds = ((millis % 60000) / 1000).toFixed(0);
      return `${minutes}:${seconds.padStart(2, '0')}`;
    };

    useEffect(() => {
      const loadAudio = async () => {
        setIsLoading(true);

        try {
          await Audio.setAudioModeAsync({
            playsInSilentModeIOS: true,
            staysActiveInBackground: false,
          });

          const loadSound = async (uri: string | number, callback: LoadSoundCallback, soundType: string) => {
            try {
              console.log(`Loading ${soundType} sound...`, uri);
              let { sound, status } = await Audio.Sound.createAsync(
                typeof uri === 'string' ? { uri } : uri,
                { shouldPlay: false, isLooping: soundType === 'music' }
              );
              console.log(`${soundType} sound status after load:`, status);

              sound.setVolumeAsync(volume);
              callback(sound);
              console.log(`${soundType} sound loaded.`);
            } catch (error) {
              console.error(`Failed to load ${soundType} sound for URI: ${uri}`, error);
            }
          };

          await loadSound(jingleUri, setJingle, 'jingle');
          await loadSound(storyUri, setStory, 'story');
          await loadSound(musicUri, setMusic, 'music');
        } catch (e) {
          console.error('Failed to load audio', e);
        } finally {
          setIsLoading(false);
        }
      };

      loadAudio();

      return () => {
        jingle?.unloadAsync();
        story?.unloadAsync();
        music?.unloadAsync();
      };
    }, [jingleUri, storyUri, musicUri, volume]);

    // Trigger the modal to show when the play button is first pressed
    const playAudioWithAdvice = async () => {
      if (!adviceModalShown) {
        setIsAdviceModalVisible(true); // Show advice modal if not already shown
      } else {
        playAudio(); // Play audio directly if the advice modal has been shown
      }
    };

    // Call this function when the modal is acknowledged
    const handleModalClose = () => {
      setIsAdviceModalVisible(false);
      dispatch(setAdviceModalShown(storyId));
      playAudio(); // Play audio after closing the modal
    };

    useImperativeHandle(ref, () => ({
      stopAllAudio: () => {
        stopAudio();
      },
      skipForward: () => {
        skip(15);
      },
      skipBackward: () => {
        skip(-15);
      }
    }));

    const updateStoryPlaybackStatus = (status: AVPlaybackStatus) => {
      if (!('isLoaded' in status && status.isLoaded)) {
        return;
      }

      setPlaybackStatus(status);
      if (status.didJustFinish) {
        stopAudio();
      }
    };

    useEffect(() => {
      if (story) {
        story.setOnPlaybackStatusUpdate(updateStoryPlaybackStatus);
      }

      return () => {
        story?.setOnPlaybackStatusUpdate(null);
      };
    }, [story]);

    useEffect(() => {
      if (playbackStatus && playbackStatus.isLoaded) {
        const positionRatio = playbackStatus.durationMillis ? playbackStatus.positionMillis / playbackStatus.durationMillis : 0;
        setSliderPosition(positionRatio);
      }
    }, [playbackStatus]);

    const playAudio = async () => {
      if (!jingle || !story || !music) {
        console.log('Audio files are not loaded yet');
        return;
      }

      if (jinglePlayed) {
        if (playbackStatus && playbackStatus.isLoaded && !playbackStatus.isPlaying) {
          await story.playAsync();
          await music.playAsync();
          setIsPlaying(true);
          setIsJinglePlaying(false); // Jingle has already played
        }
      } else {
        setIsJinglePlaying(true); // Start playing the jingle
        await jingle.playAsync();
        jingle.setOnPlaybackStatusUpdate(async (status) => {
          if (status && 'didJustFinish' in status && status.didJustFinish) {
            setIsJinglePlaying(false); // Jingle finished, now play story and music
            await story.playAsync();
            await music.playAsync();
            setIsPlaying(true);
            setJinglePlayed(true);
          }
        });
      }
    };

    const onPlayPausePress = () => {
      if (!isJinglePlaying) {
        if (isPlaying) {
          pauseAudio();
        } else {
          playAudioWithAdvice();
        }
      }
    };

    const onSkipPress = (seconds: number) => {
      if (!isJinglePlaying) {
        skip(seconds);
      }
    };

    const pauseAudio = async () => {
      if (isPlaying && story && music) {
        await story.pauseAsync();
        await music.pauseAsync();
        setIsPlaying(false);
      }
    };

    const stopAudio = async () => {
      if (music) {
        await music.setIsLoopingAsync(false);
        await music.stopAsync();
      }
      if (story) await story.stopAsync();
      if (jingle) await jingle.stopAsync();

      setIsPlaying(false);
      setJinglePlayed(false);
    };

    const skip = async (seconds: number) => {
      if (playbackStatus && playbackStatus.isLoaded) {
        const newPosition = Math.min(
          Math.max(playbackStatus.positionMillis + seconds * 1000, 0),
          playbackStatus.durationMillis ?? 0
        );
        if (story) {
          await story.setPositionAsync(newPosition);
        }
        if (music) {
          await music.setPositionAsync(newPosition);
        }
      }
    };

    const currentTime = playbackStatus && 'isLoaded' in playbackStatus && playbackStatus.isLoaded
      ? formatTime(playbackStatus.positionMillis ?? 0)
      : '0:00';

    const totalTime = playbackStatus && 'isLoaded' in playbackStatus && playbackStatus.isLoaded
      ? formatTime(playbackStatus.durationMillis ?? 0)
      : '0:00';

    const handleVolumeChange = (newVolume: number) => {
      setVolume(newVolume);
      jingle?.setVolumeAsync(newVolume);
      story?.setVolumeAsync(newVolume);
      music?.setVolumeAsync(newVolume / 3);
    };

    if (isLoading) {
      return (
        <View style={[styles.centeredContainer]}>
          <Loader size="small" color="white" />
        </View>
      );
    }

    return (
      <View style={[styles.container, { backgroundColor }]}>
        <View style={styles.controlRow}>
          <Pressable
            onPress={() => onSkipPress(-15)}
            style={({ pressed }) => [
              styles.buttonPressable,
              { opacity: pressed ? 0.5 : 1 }
            ]}
          >
            <MaterialCommunityIcons
              name="rewind-15"
              size={35}
              color={isStoryLoaded && isMusicLoaded ? 'white' : Colors.disabled}
              style={{ opacity: isJinglePlaying ? 0.4 : 1 }}
            />
          </Pressable>
          <Pressable
            onPress={onPlayPausePress}
            style={({ pressed }) => [
              { opacity: pressed ? 0.5 : 1 }
            ]}
          >
            <MaterialCommunityIcons
              name={isPlaying ? 'pause-circle' : 'play-circle'}
              size={70}
              color={'white'}
              style={{ opacity: isJinglePlaying ? 0.4 : 1 }}
            />
          </Pressable>
          <Pressable
            onPress={() => onSkipPress(+15)}
            style={({ pressed }) => [
              styles.buttonPressable,
              { opacity: pressed ? 0.5 : 1 }
            ]}
          >
            <MaterialCommunityIcons
              name="fast-forward-15"
              size={35}
              color={isStoryLoaded && isMusicLoaded ? 'white' : Colors.disabled}
              style={{ opacity: isJinglePlaying ? 0.4 : 1 }}
            />
          </Pressable>
        </View>
        <Slider
          disabled={!isStoryLoaded || !isMusicLoaded}
          value={sliderPosition}
          minimumValue={0}
          maximumValue={1}
          thumbStyle={{ height: 20, width: 20, backgroundColor: 'grey' }}
          trackStyle={{ height: 2, backgroundColor: Colors.secondary }}
        />
        <View style={styles.timeContainer}>
          <Text style={styles.timeText}>{currentTime}</Text>
          <Text style={styles.timeText}>{totalTime}</Text>
        </View>
        <Modal isVisible={isAdviceModalVisible} onBackdropPress={handleModalClose}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Notre conseil</Text>
            <Text style={styles.modalText}>
              Pour une meilleure immersion (et rester loin des écrans), nous vous conseillons de diffuser l'histoire sur enceinte connectée !
            </Text>
            <Button title="Compris" onPress={handleModalClose} />
          </View>
        </Modal>
      </View>
    );
  }
);

const styles = StyleSheet.create({
  container: {
    padding: 24,
  },
  centeredContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  controlRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: 10,
  },
  timeContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    alignItems: 'center',
  },
  timeText: {
    color: 'white',
    fontSize: 14,
  },
  playPauseButtons: {
    alignItems: 'center',
    marginHorizontal: 16,
  },
  buttonPressable: {
    alignItems: 'center',
    marginHorizontal: 16,
    paddingVertical: 10,
  },
  skipButtons: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    paddingVertical: 10,
    marginHorizontal: 16,
  },
  modalContent: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default AudioPlayer;
