import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { KeyboardAvoidingView, Platform, StyleSheet, Text } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Toast from 'react-native-toast-message';
import { resetPassword } from '../../api/auth/auth.api';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import Colors from '../../constants/Colors';
import environment from '../../environments';
import { AppNavigationProps } from '../../types';

const PasswordResetScreen = () => {
  const navigation = useNavigation<AppNavigationProps<"SignIn">>();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePasswordReset = async () => {
    setIsSubmitting(true);
    if (email) {
      try {
        const resetData = {
          email: email.toLowerCase(),
          redirect_url: environment.resetPasswordUrl
        };

        await resetPassword(resetData);
        Toast.show({
          type: 'success',
          text1: 'On vous a envoyé un e-mail 💌',
          text2: 'Cliquez sur le lien de réinitialisation.'
        });

        navigation.goBack();
      } catch (error) {
        console.error('Password reset error:', error);
        Toast.show({
          type: 'error',
          text1: 'Erreur de réinitialisation',
          text2: 'Échec de l’envoi du lien. Veuillez réessayer.'
        });
      }
    } else {
      Toast.show({
        type: 'info',
        text1: 'Réinitialisation du mot de passe',
        text2: 'Veuillez entrer votre adresse e-mail.'
      });
    }
    setIsSubmitting(false);
  };

  return (
    <KeyboardAvoidingView
      style={styles.keyboardAvoidingViewContainer}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
    >
      <ScrollView contentContainerStyle={styles.scrollViewContainer}>
        <Text style={styles.titleText}>Un trou de mémoire ?</Text>
        <InputField
          label="Adresse email"
          onChangeText={setEmail}
          inputType="email"
          value={email}
          placeholder="votreemail@example.com"
          keyboardType="email-address"
        />
        <Button title="Envoyer" onPress={handlePasswordReset} disabled={isSubmitting} />
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidingViewContainer: {
    flex: 1,
    backgroundColor: Colors.primary05,
  },
  scrollViewContainer: {
    justifyContent: 'center',
    flexGrow: 1,
    paddingHorizontal: 16,
  },
  titleText: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    color: Colors.text,
    textAlign: 'center',
    marginBottom: 16,
  },
});

export default PasswordResetScreen;
