import { useNavigation } from '@react-navigation/native';
import React, { useState } from 'react';
import { ActivityIndicator, KeyboardAvoidingView, Platform, ScrollView, StyleSheet, Text, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { useDispatch } from 'react-redux';
import { handleSignIn } from '../../api/auth/auth.api';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import Colors from '../../constants/Colors';
import { globalStyles } from '../../constants/Styles';
import { signInSuccess } from '../../store/slices/appSlice';
import { AppNavigationProps } from '../../types';
import handleApiError from '../../utils/functions/apiErrorHandling';
import publicApi from "../../utils/publicApi";
import Loader from '../../components/Loader';

const SignInScreen = () => {
  const navigation = useNavigation<AppNavigationProps<"SignIn">>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailPlaceholder, setEmailPlaceholder] = useState('Adresse e-mail');
  const [passwordPlaceholder, setPasswordPlaceholder] = useState('Mot de passe');

  const dispatch = useDispatch();

  const handleSignInPress = async () => {
    try {

      const baseUrl = publicApi.defaults.baseURL;
      const signInEndpoint = `${baseUrl}/auth/sign_in`;
      console.log('Sign-in API Endpoint:', signInEndpoint);

      const userEmailAddress = email.toLowerCase();
      const { token, client, uid, firstName, lastName, email: userEmail } = await handleSignIn(userEmailAddress, password);
      dispatch(signInSuccess({
        token: token,
        client: client,
        uid: uid,
        firstName: firstName,
        lastName: lastName,
        email: userEmail,
      }));
      Toast.show({
        type: 'success',
        text1: 'Connexion réussie',
        text2: `Bienvenue, ${firstName} 👋!`
      });
    } catch (error) {
      handleApiError(error);
    }
  };

  return (
    <KeyboardAvoidingView
      style={styles.keyboardAvoidingViewContainer}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
    >
      <ScrollView
        contentContainerStyle={styles.scrollViewContainer}
        keyboardShouldPersistTaps='handled'
      >
        <Text style={styles.titleText}>Content de vous revoir 😃 !</Text>
        <InputField
          label="Adresse e-mail"
          value={email}
          onChangeText={setEmail}
          inputType="email"
          maxLength={255}
          placeholder="exemple@adresse.com"
        />
        <InputField
          label="Mot de passe"
          value={password}
          onChangeText={setPassword}
          inputType="password"
          secureTextEntry={true}
          maxLength={255}
          placeholder="••••••••"
        />
        {isLoading ? (
          <Loader />
        ) : (
          <Button title="Se connecter" onPress={handleSignInPress} />
        )}
        <Button
          title="Créer un compte"
          variant="link"
          size='small'
          onPress={() => navigation.navigate('SignUp')}
          style={{ marginVertical: 4 }}
        />
        <Button
          title="Mot de passe oublié ?"
          variant="link"
          size='small'
          onPress={() => navigation.navigate('PasswordReset')}
          style={{ marginVertical: 4 }}
        />
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  keyboardAvoidingViewContainer: {
    flex: 1,
    backgroundColor: Colors.primary05,
  },
  scrollViewContainer: {
    justifyContent: 'center',
    flexGrow: 1,
    paddingHorizontal: 16,
    paddingBottom: 16,
  },
  titleText: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    color: Colors.text,
    textAlign: 'center',
    paddingBottom: 8,
  },
});

export default SignInScreen;
