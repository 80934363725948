import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import LottieView from 'lottie-react-native';
import React, { useLayoutEffect, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Balloons from '../../../assets/animations/balloons.json';
import BearToy from '../../../assets/animations/bear-toy.json';
import Candy from '../../../assets/animations/candy.json';
import Castle from '../../../assets/animations/castle.json';
import PaperBoat from '../../../assets/animations/paper-boat.json';
import Snowman from '../../../assets/animations/snowman.json';
import Button from '../../components/Button';
import Colors from '../../constants/Colors';
import { AppNavigationProps, RootStackParamList } from '../../types';
import { updateKid } from '../../api/kid/kid.api';

const EndOnboardingScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "EndOnboarding">) => {
  const navigation = useNavigation<AppNavigationProps<"EndOnboarding">>();
  const { kidName } = route.params;

  const [animationStage, setAnimationStage] = useState(1);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'C\'est parti !',
      headerLeft: () => null,
      headerRight: () => (
        <Pressable onPress={() => navigation.goBack()} style={{ marginRight: 16 }} >
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation, kidName]);

  return (
    <View style={styles.container}>
      <View style={styles.animationContainer}>
        {animationStage === 1 && (
          <LottieView
            source={PaperBoat}
            autoPlay
            loop={false}
            onAnimationFinish={() => setAnimationStage(2)}
            style={styles.lottieAnimation}
          />
        )}
        {animationStage === 2 && (
          <LottieView
            source={Castle}
            autoPlay
            loop={false}
            onAnimationFinish={() => setAnimationStage(3)}
            style={styles.lottieAnimation}
          />
        )}
        {animationStage === 3 && (
          <LottieView
            source={Candy}
            autoPlay
            loop={false}
            onAnimationFinish={() => setAnimationStage(4)}
            style={styles.lottieAnimation}
          />
        )}
        {animationStage === 4 && (
          <LottieView
            source={Snowman}
            autoPlay
            loop={false}
            onAnimationFinish={() => setAnimationStage(5)}
            style={styles.lottieAnimation}
          />
        )}
        {animationStage === 5 && (
          <LottieView
            source={Balloons}
            autoPlay
            loop={false}
            onAnimationFinish={() => setAnimationStage(6)}
            style={styles.lottieAnimation}
          />
        )}
        {animationStage === 6 && (
          <LottieView
            source={BearToy}
            autoPlay
            loop={false}
            onAnimationFinish={() => setAnimationStage(1)}
            style={styles.lottieAnimation}
          />
        )}
      </View>
      <Text style={styles.title}>Découvrez le profil de {kidName} !</Text>
      <Text style={styles.subtitle}>
        Ajoutez des réponses à 'Son monde',{"\n"}
        elles seront utilisées aléatoirement pour créer des histoires.{"\n"}{"\n"}
        Pour créer une histoire sur un thème spécifique, choisissez 'Créer une histoire' puis 'Histoire guidée'.
      </Text>
      <Button
        variant="primary"
        title={"J'y vais"}
        onPress={async () => {
          await updateKid(route.params.kidId, { onboarded: true });
          navigation.goBack();
        }}
      />
    </View >
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
    backgroundColor: Colors.primary05,
  },
  animationContainer: {
    height: 150,
    width: 150,
    alignSelf: 'center',
    paddingBottom: 8,
  },
  lottieAnimation: {
    height: '100%',
    width: '100%',
  },
  backButton: {
    marginLeft: 15,
  },
  logo: {
    width: 164,
    height: 144,
    resizeMode: 'contain',
  },
  title: {
    fontFamily: 'GochiHandRegular',
    fontSize: 28,
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 16,
  },
  subtitle: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 40,
  },
});

export default EndOnboardingScreen;
