import TrackPlayer from 'react-native-track-player';

console.log("Playback service loaded");

const service = async function() {
  console.log("TrackPlayer service is initializing...");

  TrackPlayer.addEventListener('remote-play', async () => {
    try {
      console.log("remote-play triggered");
      await TrackPlayer.play();
    } catch (error) {
      console.error("Error handling remote-play:", error);
    }
  });

  TrackPlayer.addEventListener('remote-pause', async () => {
    try {
      console.log("remote-pause triggered");
      await TrackPlayer.pause();
    } catch (error) {
      console.error("Error handling remote-pause:", error);
    }
  });

  // Seek backward by 15 seconds
  TrackPlayer.addEventListener('remote-jump-backward', async () => {
    try {
      console.log("remote-jump-backward triggered");
      const currentPosition = await TrackPlayer.getPosition();
      await TrackPlayer.seekTo(currentPosition - 15);
    } catch (error) {
      console.error("Error handling remote-jump-backward:", error);
    }
  });

  // Seek forward by 15 seconds
  TrackPlayer.addEventListener('remote-jump-forward', async () => {
    try {
      console.log("remote-jump-forward triggered");
      const currentPosition = await TrackPlayer.getPosition();
      await TrackPlayer.seekTo(currentPosition + 15);
    } catch (error) {
      console.error("Error handling remote-jump-forward:", error);
    }
  });
};

export default service;
