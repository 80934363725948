import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import LottieView from 'lottie-react-native';
import React, { useLayoutEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import MeterMeasure from '../../../assets/animations/meter-measure.json';
import Button from '../../components/Button';
import ItemSelector from '../../components/ItemSelector';
import { useProgressBar } from '../../components/ProgressBarContext';
import { ageRangeArray } from '../../constants/Enums';
import { globalStyles } from '../../constants/Styles';
import { RootStackParamList } from '../../types';
import { formatAge } from '../../utils/functions/formatAge';

const SelectKidAgeScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "SelectKidAge">) => {
  const navigation = useNavigation();
  const [selectedAge, setSelectedAge] = useState(3);
  const { kidName } = route.params;
  const { setCurrentStep } = useProgressBar();

  useFocusEffect(
    React.useCallback(() => {
      setCurrentStep(2);
    }, [])
  );

  const handleNextPress = () => {
    navigation.navigate('SelectEmoji', { kidName, kidAge: selectedAge });
  };

  useLayoutEffect(() => {
    const headerTitle = `Son âge`;
    navigation.setOptions({
      title: headerTitle,
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('KidOverview', { kidId: route.params.kidId, kidName })} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation, kidName, route.params.kidId]);

  return (
    <View style={globalStyles.container}>
      <View style={styles.animationContainer}>
        <LottieView
          source={MeterMeasure}
          autoPlay
          loop
          style={styles.lottieAnimation}
        />
      </View>
      <ItemSelector
        selectedItem={selectedAge}
        onItemSelect={setSelectedAge}
        items={ageRangeArray.map(age => ({
          label: formatAge(age),
          value: age,
        }))}
      />
      <Button title="Suivant" onPress={handleNextPress} />
    </View>
  );
};

const styles = StyleSheet.create({
  animationContainer: {
    height: 150,
    width: 150,
    alignSelf: 'center',
    paddingBottom: 16,
  },
  lottieAnimation: {
    height: '100%',
    width: '100%',
  },
});

export default SelectKidAgeScreen;
