import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import Modal from 'react-native-modal';
import { postAnswer } from '../../api/answer/answer.api';
import { getKidAnswers } from '../../api/kid/kid.api';
import { getQuestions } from '../../api/question/question.api';
import { postKidStory } from '../../api/story/story.api';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import Loader from '../../components/Loader';
import { useProgressBar } from '../../components/ProgressBarContext';
import Colors from '../../constants/Colors';
import { StoryCreationNavigationProps, StoryCreationStackParamList } from '../../types';

const CustomQuestionScreen = ({ route }: NativeStackScreenProps<StoryCreationStackParamList, "CustomQuestion">) => {
  const navigation = useNavigation<StoryCreationNavigationProps<"CustomQuestion">>();
  const [displayedQuestions, setDisplayedQuestions] = useState<{
    example_answers: never[]; id: number, title: string
  }[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState<{
    example_answers: any;
    id?: number;
    title: string;
  } | null>(null);
  const [placeholderText, setPlaceholderText] = useState('');
  const [contextualAnswer, setContextualAnswer] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [questionIndex, setQuestionIndex] = useState(0);
  const { kidId, kidName, need, theme } = route.params;
  console.log("Screen Params:", { kidId, kidName, need, theme });

  const [showModal, setShowModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { setCurrentStep } = useProgressBar();

  useFocusEffect(
    React.useCallback(() => {
      setCurrentStep(4);
    }, [])
  );

  useEffect(() => {
    if (selectedQuestion) {
      const randomIndex = Math.floor(Math.random() * selectedQuestion.example_answers.length);
      setPlaceholderText(selectedQuestion.example_answers[randomIndex]);
    }
  }, [selectedQuestion]);

  useEffect(() => {
    fetchUnansweredQuestions();
  }, []);

  const fetchUnansweredQuestions = async () => {
    setIsLoading(true);
    try {
      const answersResponse = await getKidAnswers(kidId);
      const existingAnswers = answersResponse.data;
      const questionsResponse = await getQuestions();
      const questionsData = questionsResponse.data;

      const unansweredQuestions = questionsData.filter(question =>
        !existingAnswers.some(answer => answer.question_id === question.id) &&
        question.question_type === 'contextual'
      );

      const shuffledQuestions = shuffleArray(unansweredQuestions);
      setDisplayedQuestions(shuffledQuestions);

      if (shuffledQuestions.length > 0) {
        const firstQuestion = shuffledQuestions[0];
        setSelectedQuestion({
          id: firstQuestion.id,
          title: firstQuestion.title,
          example_answers: firstQuestion.example_answers || []
        });
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
    setIsLoading(false);
  };

  const shuffleArray = (array: any[]) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };

  const goToNextQuestion = () => {
    let newIndex = questionIndex + 1;
    if (newIndex >= displayedQuestions.length) {
      newIndex = 0; // Reset to the first question if we've reached the end
    }
    setQuestionIndex(newIndex);
    const nextQuestion = displayedQuestions[newIndex];
    setSelectedQuestion({
      id: nextQuestion.id,
      title: nextQuestion.title,
      example_answers: nextQuestion.example_answers || []
    });
  };

  const handleAnswerChange = (text: string) => {
    setContextualAnswer(text);
  };

  const handleConfirmStory = () => {
    setShowModal(false);
    handleCreateStory();
  };

  const handleCreateStory = async () => {
    if (!selectedQuestion || isButtonDisabled) {
      return;
    }

    setIsButtonDisabled(true);

    try {
      const answerPayload = {
        question_id: selectedQuestion.id ?? 0,
        content: contextualAnswer,
      };
      await postAnswer(kidId, answerPayload);

      const storyData = {
        need: need,
        kid_id: kidId,
        theme: theme,
        contextual_question_id: selectedQuestion.id ?? 0,
        contextual_question: selectedQuestion.title,
        contextual_answer: contextualAnswer,
        instant_generation: false,
      };
      console.log("Story Data:", storyData);

      const storyResponse = await postKidStory(storyData, kidId);
      const storyId = storyResponse.data.story.id;

      navigation.navigate('StoryGeneration', {
        kidId,
        kidName,
        need,
        theme,
        contextual_question: selectedQuestion.title,
        contextual_answer: contextualAnswer,
        storyId
      });

    } catch (error) {
      console.error('Error creating story:', error);
      setIsButtonDisabled(false);
    }
  };

  React.useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Une dernière question',
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('Main' as any, {
          screen: 'KidOverview',
          params: {
            kidId,
            kidName,
          }
        })} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation]);

  if (isLoading) {
    return (
      <View style={styles.loaderContainer}>
        <Loader />
      </View>
    );
  }

  return (
    <>
      <KeyboardAvoidingView
        behavior={Platform.OS === "ios" ? "padding" : "height"}
        style={{ flex: 1 }}
      >
        <ScrollView contentContainerStyle={styles.container} keyboardShouldPersistTaps='handled'>
          <View style={styles.questionContainer}>
            <Text
              style={styles.question}
              numberOfLines={3}
              ellipsizeMode="tail"
            >
              {selectedQuestion ? selectedQuestion.title : ''}
            </Text>
          </View>
          <InputField
            placeholder={placeholderText || "Votre réponse !"}
            onChangeText={handleAnswerChange}
            inputType="answer"
            maxLength={50}
            value={contextualAnswer}
            autoFocus={true}
          />
          <Button
            title="Changer de question"
            variant='secondary'
            onPress={goToNextQuestion}
          />
          <Button
            title="Valider"
            onPress={() => setShowModal(true)}
            disabled={isButtonDisabled}
            iconRight="coin"
          />
          <Text style={styles.tipText}>
            PS : réponses détaillées et farfelues bienvenues ✨!
          </Text>
        </ScrollView>
      </KeyboardAvoidingView>
      <Modal isVisible={showModal} >
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Une nouvelle histoire</Text>
          <Text style={styles.modalText}>
            Souhaitez-vous utiliser 1 crédit pour créer une histoire ?
          </Text>
          <Button title="Valider" variant="primary" onPress={handleConfirmStory} />
          <Button title="Annuler" variant="secondary" onPress={() => setShowModal(false)} />
        </View>
      </Modal >
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  questionContainer: {
    minHeight: 120,
    justifyContent: 'center',
    padding: 10,
  },
  question: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    textAlign: 'center',
  },
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary05,
  },
  tipText: {
    fontFamily: 'Gilroy-Regular',
    marginTop: 10,
    fontSize: 16,
    textAlign: 'center',
    color: Colors.grey1,
  },
  modalContent: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default CustomQuestionScreen;
