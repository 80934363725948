import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useLayoutEffect } from 'react';
import { Pressable, View } from 'react-native';
import { getEmotionsQuestionByTitle } from '../../api/question/question.api';
import Button from '../../components/Button';
import { useProgressBar } from '../../components/ProgressBarContext';
import { EmotionType } from '../../constants/Enums';
import { globalStyles } from '../../constants/Styles';
import { StoryCreationNavigationProps, StoryCreationStackParamList } from '../../types';

const EmotionSelectionScreen = ({ route }: NativeStackScreenProps<StoryCreationStackParamList, "EmotionSelection">) => {
  const navigation = useNavigation<StoryCreationNavigationProps<"EmotionSelection">>();
  const { kidId, kidName, need } = route.params;
  const { setCurrentStep } = useProgressBar();

  useFocusEffect(
    React.useCallback(() => {
      setCurrentStep(3);
    }, [])
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      title: `Que ressent ${kidName} ?`,
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('Main' as any, {
          screen: 'KidOverview',
          params: {
            kidId,
            kidName,
          }
        })
        } style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation]);

  const navigateToCustomEmotionQuestion = async (emotion: EmotionType) => {
    const emotionQuestions: Record<EmotionType, string> = {
      [EmotionType.Joy]: "Qu'est-ce qui rend votre enfant heureux ?",
      [EmotionType.Anger]: "Qu'est-ce qui met votre enfant en colère ?",
      [EmotionType.Sadness]: "Qu'est-ce qui rend votre enfant triste ?",
      [EmotionType.Fear]: "Qu'est-ce qui fait peur à votre enfant ?",
      [EmotionType.Frustration]: "Qu'est-ce qui frustre votre enfant ?",
      [EmotionType.Jealousy]: "De quoi votre enfant est-il jaloux ?",
    };

    const contextual_question = emotionQuestions[emotion];

    const emotionQuestion = await getEmotionsQuestionByTitle(contextual_question);
    if (emotionQuestion) {
      const questionId = emotionQuestion.id;

      navigation.navigate('CustomEmotion', {
        kidId,
        kidName,
        need,
        theme: emotion,
        contextual_question,
        questionId
      });
    }
  };

  return (
    <View style={globalStyles.container}>
      {Object.values(EmotionType).map((emotion) => (
        <Button
          variant="select"
          key={emotion}
          title={emotion}
          onPress={() => navigateToCustomEmotionQuestion(emotion)} />
      ))}
    </View>
  );
};

export default EmotionSelectionScreen;
