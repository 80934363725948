import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useLayoutEffect, useState } from 'react';
import { Keyboard, KeyboardAvoidingView, Platform, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { deleteAnswer, updateAnswer } from '../../api/answer/answer.api';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import Loader from '../../components/Loader';
import Colors from '../../constants/Colors';
import { AppNavigationProps, RootStackParamList } from '../../types/navigation';
import handleApiError from '../../utils/functions/apiErrorHandling';

const EditKidWorldScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "EditKidWorld">) => {
  const navigation = useNavigation<AppNavigationProps<"EditKidWorld">>();
  const { kidName, answer, question } = route.params as RootStackParamList["EditKidWorld"];
  const [inputValue, setInputValue] = useState(answer.content);
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {

    const fullTitle = `Le monde de ${kidName}`;

    const truncate = (str: string, maxLength: number) => {
      if (str.length <= maxLength) return str;
      return `${str.substring(0, maxLength - 3)}...`;
    };

    const truncatedTitle = truncate(fullTitle, 21);

    navigation.setOptions({
      title: truncatedTitle,
      headerLeft: () => null,
      headerRight: () => (
        <Pressable onPress={() => {
          navigation.goBack();
        }} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color={Colors.text} />
        </Pressable>
      ),
    });
  }, [navigation, kidName]);

  const handleSave = async () => {
    Keyboard.dismiss();
    setIsLoading(true);
    try {
      await updateAnswer(answer.id, inputValue);
      Toast.show({
        type: 'success',
        text1: 'Réponse modifiée',
        text2: 'La réponse a bien été mise à jour !'
      });
      navigation.goBack();
    } catch (error) {
      console.error('Error:', error);
      handleApiError(error);
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Erreur lors de la mise à jour de la réponse.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteAnswer(answer.id);
      Toast.show({
        type: 'success',
        text1: 'Réponse supprimée',
        text2: 'La réponse a bien été supprimée !'
      });
      navigation.goBack();
    } catch (error) {
      console.error('Error:', error);
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Erreur lors de la suppression de la réponse.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <Loader style={styles.loader} />;
  }

  return (
    <KeyboardAvoidingView
      style={{ flex: 1, backgroundColor: Colors.primary05 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
    >
      <ScrollView
        contentContainerStyle={styles.container}
        style={{ flexGrow: 1, backgroundColor: Colors.primary05 }}
        keyboardShouldPersistTaps='handled'
      >
        <View style={styles.questionContainer}>
          <Text style={styles.question}>{question.title}</Text>
        </View>
        <InputField
          value={inputValue}
          onChangeText={setInputValue}
          inputType="answer"
          maxLength={50}
          placeholder="Votre réponse ici !"
        />
        <View style={styles.buttonContainer}>
          <Button title="Valider" onPress={handleSave} variant='primary' />
          <Button title="Supprimer" onPress={handleDelete} variant='danger' />
        </View>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  question: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    marginBottom: 20,
    textAlign: 'center',
  },
  questionContainer: {
    height: 120,
    justifyContent: 'center',
    marginTop: 20,
  },
  buttonContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary05,
  },
});

export default EditKidWorldScreen;
