import LottieView from 'lottie-react-native';
import React from 'react';
import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native';

interface LoaderProps {
  size?: 'small' | 'large';
  color?: 'white';
  style?: StyleProp<ViewStyle>;
}

const Loader: React.FC<LoaderProps> = ({ size = 'large', color, style }) => {
  const animationSize = size === 'large' ? 50 : 25;
  const animationSource = color === 'white'
    ? require('../../assets/animations/spinner-half-circles-white.json')
    : require('../../assets/animations/spinner-half-circles.json');

  return (
    <View style={[styles.loaderContainer, style]}>
      <LottieView
        source={animationSource}
        autoPlay
        loop
        style={{ width: animationSize, height: animationSize }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default Loader;
