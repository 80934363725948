import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useLayoutEffect, useMemo } from 'react';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { getKidStories } from '../../api/kid/kid.api';
import Loader from '../../components/Loader';
import StoryCard from '../../components/StoryCard';
import Colors from '../../constants/Colors';
import { AppNavigationProps, RootStackParamList } from '../../types';
import useLoadData from '../../utils/hooks/useLoadData';

const KidStoriesScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "KidStories">) => {
  const navigation = useNavigation<AppNavigationProps<"KidStories">>();
  const { kidId, kidName } = route.params;
  const { data: storyData, isLoading, error } = useLoadData(() => getKidStories(kidId));

  const truncate = (str: string, maxLength: number) => {
    if (str.length <= maxLength) return str;
    return `${str.substring(0, maxLength - 3)}...`;
  };

  useLayoutEffect(() => {
    const fullTitle = `Les histoires de ${kidName}`;
    const truncatedTitle = truncate(fullTitle, 25);

    navigation.setOptions({
      title: truncatedTitle,
    });
  }, [navigation, kidName]);

  const groupedStories = useMemo(() => {
    if (!storyData?.stories) return [];

    // Group stories by date
    const groups = storyData.stories.reduce((acc, story) => {
      const date = new Date(story.created_at).toISOString().split('T')[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(story);
      return acc;
    }, {});

    // Convert groups to array, sort by date, and then sort stories within each date
    const sortedGroups = Object.entries(groups).sort((a, b) => {
      return b[0].localeCompare(a[0]); // Sort groups by date in descending order
    }).map(([date, stories]) => {
      // Sort stories within each group by time in descending order
      const sortedStories = stories.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return {
        title: new Date(date).toLocaleDateString('fr-FR'), // Format date in European format
        data: sortedStories
      };
    });

    return sortedGroups;
  }, [storyData]);

  const onStorySelect = (storyId: number) => {
    const selectedStory = storyData?.stories.find(story => story.id === storyId);
    if (selectedStory) {
      navigation.navigate('StoryOverview', {
        kidId: kidId,
        storyId: storyId,
        story: selectedStory,
      });
    }
  };

  const renderStories = () => {
    return groupedStories.map(group => (
      <View key={group.title} style={{ alignItems: 'center', width: '100%' }}>
        <Text style={styles.sectionHeader}>{group.title}</Text>
        {group.data.map(story => (
          <Pressable
            key={story.id}
            onPress={() => onStorySelect(story.id)}
            style={({ pressed }) => [
              styles.storyItem,
              { opacity: pressed ? 0.5 : 1 },
            ]}
          >
            <StoryCard story={story} />
          </Pressable>
        ))}
      </View>
    ));
  };

  if (isLoading) {
    return (
      <View style={styles.centered}>
        <Loader />
      </View>
    );
  }

  if (error) {
    return (
      <View style={styles.centered}>
        <Text style={styles.bodyText}>Impossible de charger les histoires !</Text>
      </View>
    );
  }

  if (!isLoading && groupedStories.length === 0) {
    return (
      <View style={styles.centered}>
        <Text style={styles.textCentered}>{`${kidName} n'a pas encore d'histoire, à vous d'en créer une !`}</Text>
      </View>
    );
  }

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.contentContainer}
      showsVerticalScrollIndicator={false}
    >
      {renderStories()}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.primary05,
  },
  contentContainer: {
    padding: 10,
    alignItems: 'center',
  },
  storyItem: {
    width: '100%',
    marginVertical: 10,
    alignItems: 'center',
  },
  listContent: {
    alignItems: 'center',
  },
  sectionHeader: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    marginTop: 20,
    marginBottom: 10,
    textAlign: 'center',
  },
  centered: {
    flex: 1,
    padding: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  textCentered: {
    fontSize: 18,
    textAlign: 'center',
  },
  bodyText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    color: '#333'
  }
});

export default KidStoriesScreen;
