import axios from "axios";
import Toast from "react-native-toast-message";

import { isApiError } from "../../types/apiTypes";

const unknownError: Error = {
  name: "errors.unknownErrorName",
  message: "errors.unknownErrorMessage",
};

const defaultMessageCallBack = (message: string) => {
  Toast.show({
    type: "error",
    text1: "Une erreur est survenue",
    text2: message,
  });
};

const handleApiError = (error: unknown, messageCallBack?: (message: string) => void) => {
  if (axios.isAxiosError(error)) {
    let errorMessage = "";

    // Check for network errors or CORS issues
    if (!error.response) {
      errorMessage = "Problème de réseau 🔌. Vérifiez la connexion et l'URL du serveur.";
    } else if (isApiError(error.response.data)) {
      // Handle custom API error messages
      errorMessage = error.response.data.message || "";
    } else {
      // Handle standard HTTP status codes
      switch (error.response.status) {
        case 400:
          errorMessage = "Requête invalide. Veuillez vérifier vos données.";
          break;
        case 401:
          errorMessage = "Identifiants incorrects. Veuillez réessayer.";
          break;
        case 403:
          errorMessage = "Accès refusé. Vous n'avez pas les autorisations nécessaires.";
          break;
        case 404:
          errorMessage = "Ressource non trouvée. Veuillez vérifier l'URL.";
          break;
        case 500:
          errorMessage = "Erreur interne du serveur. Veuillez réessayer plus tard.";
          break;
        default:
          errorMessage = unknownError.message;
          break;
      }
    }

    if (messageCallBack) {
      messageCallBack(errorMessage);
    } else {
      defaultMessageCallBack(errorMessage);
    }
  } else if (messageCallBack) {
    messageCallBack(unknownError.message);
  } else {
    defaultMessageCallBack(unknownError.message);
  }
};


export default handleApiError;
