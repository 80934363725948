import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import TrackPlayer from 'react-native-track-player';
import AudioPlayerPlayback from '../../components/AudioPlayerPlayback';
import Colors from '../../constants/Colors';
import { setStoryRead } from '../../store/slices/storiesSlice';
import { useAppDispatch } from '../../store/store';
import { RootStackParamList } from '../../types';
import { getMusicDetails } from '../../api/music/music.api';

const StoryReadPlaybackScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "StoryReadPlayback">) => {
  const navigation = useNavigation();
  const { story, audioUrl, backgroundColor: initialBackgroundColor, artworkUrl } = route.params;
  const [backgroundColor, setBackgroundColor] = useState(initialBackgroundColor || Colors.grey);
  const [mixedAudioUrl, setMixedAudioUrl] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchAndMixAudio = async () => {
      // Check if music_id and audio_key are present
      if (!story.music_id || !story.audio_key) return;

      try {
        // Fetch music details
        const musicDetailsResponse = await getMusicDetails(story.music_id);
        if (!musicDetailsResponse.data || !musicDetailsResponse.data.file_path) {
          console.error("Music details fetch failed or file path missing.");
          return;
        }
        const musicCloudinaryId = musicDetailsResponse.data.file_path.split("/musics/")[1].split(".")[0];
        console.log("Music Cloudinary ID:", musicCloudinaryId);

        // Use the story audio key directly from the story object now available
        const storyCloudinaryId = story.audio_key;
        console.log("Story Audio Cloudinary ID:", storyCloudinaryId);

        // Construct the URL as before
        const cloudName = 'dzcevvxsn';
        const mixedUrl = `https://res.cloudinary.com/${cloudName}/video/upload/l_audio:musics:${musicCloudinaryId}/e_volume:-98/fl_layer_apply,so_10.0/production/${storyCloudinaryId}`;
        console.log("Mixed audio URL:", mixedUrl);

        // Update the state with the new URL
        setMixedAudioUrl(mixedUrl);
      } catch (error) {
        console.error("Error mixing audio tracks:", error);
      }
    };

    fetchAndMixAudio();
  }, [story.music_id, story.audio_key]);

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        TrackPlayer.stop();
      };
    }, [])
  );

  useLayoutEffect(() => {
    const truncatedTitle = story?.title ? story.title.slice(0, 23) + (story.title.length > 23 ? '...' : '') : '';
    navigation.setOptions({
      title: truncatedTitle,
      headerStyle: {
        backgroundColor: backgroundColor,
      },
      headerTitleStyle: {
        color: 'white',
        fontFamily: 'GochiHandRegular',
        fontSize: 24,
      },
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerLeft: () => null,
      headerRight: () => (
        <Pressable onPress={() => {
          dispatch(setStoryRead(story.id));
          navigation.goBack();
        }} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="white" />
        </Pressable>
      ),
    });
  }, [navigation, story.id, dispatch, backgroundColor]);

  if (!story) {
    return <Text>L'histoire n'a pu être chargée !</Text>;
  }

  return (
    <View style={[styles.readingContainer, { backgroundColor }]}>
      <ScrollView style={styles.scrollView}>
        <Text style={styles.textContent}>
          <Text style={styles.dropCap}>{story.content.charAt(0)}</Text>
          {story.content.slice(1)}
        </Text>
        <View style={styles.divider} />
      </ScrollView>
      <View style={{ height: 200 }}>
        <AudioPlayerPlayback
          key={story.id}
          jingleUri="https://res.cloudinary.com/dzcevvxsn/video/upload/v1711369722/musics/jingle_rup8dh.mp3"
          storyId={story.id}
          storyTitle={story.title}
          storyUri={mixedAudioUrl || audioUrl}
          outroUri="https://res.cloudinary.com/dzcevvxsn/video/upload/v1711625659/musics/outro_jingle_p8sn70.mp3"
          artworkUri={artworkUrl}
          backgroundColor={backgroundColor}
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  readingContainer: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
    padding: 32,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    position: 'relative',
  },
  textContentContainer: {
    alignItems: 'center',
  },
  textContent: {
    fontFamily: 'SourceSerif4-Regular',
    fontSize: 26,
    lineHeight: 60,
    color: Colors.white,
    textAlign: 'center',
  },
  dropCap: {
    fontFamily: 'GochiHandRegular',
    fontSize: 60,
    lineHeight: 60,
    color: Colors.white,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary05,
  },
  divider: {
    height: 24,
  },
});

export default StoryReadPlaybackScreen;
