import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View } from 'react-native';
import Colors from '../constants/Colors';

const ProgressBar = ({ currentStep, totalSteps }: { currentStep: number, totalSteps: number }) => {
  const animation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.spring(animation, {
      toValue: (currentStep / totalSteps),
      useNativeDriver: false,
      bounciness: 10, // Adjust the bounciness as needed
    }).start();
  }, [currentStep, totalSteps]);

  const widthInterpolated = animation.interpolate({
    inputRange: [0, 1],
    outputRange: ['0%', '100%'],
  });

  return (
    <View style={styles.progressBarContainer}>
      <Animated.View style={[styles.progressBar, { width: widthInterpolated }]} />
    </View>
  );
};


const styles = StyleSheet.create({
  progressBarContainer: {
    height: 4,
    width: '100%',
    overflow: 'hidden',
  },
  progressBar: {
    height: '100%',
    backgroundColor: Colors.secondary,
  },
});


export default ProgressBar;
