const faqData = [
  {
    question: "Comment les histoires Fabulo sont-elles imaginées ?",
    answer: "Les histoires sont générées par une combinaison d'intelligence artificielle et de créativité humaine. " +
      "Notre IA, utilisant des algorithmes avancés, analyse les préférences et les intérêts de l'enfant " +
      "pour créer non seulement des contes uniques et captivants mais également la voix d'une personne qui " +
      "les raconte et une image pour l'illustrer. Cette approche offre une expérience immersive " +
      "et stimulante pour l'enfant."
  },
  {
    question: "Les histoires Fabulo sont-elles adaptées à tous les âges ?",
    answer: "Fabulo est spécialement conçu pour les enfants de 3 à 7 ans. L'interface de l'application " +
      "est destinée aux parents, qui peuvent renseigner des informations concernant l'enfant pour " +
      "personnaliser les histoires. Nous suggérons aux parents de diffuser l'histoire sur une enceinte " +
      "connectée, plutôt que de laisser le téléphone dans les mains de l'enfant. Cette façon de faire favorise " +
      "une immersion profonde dans l'histoire et évite les distractions liées à l'utilisation de l'écran."
  },
  {
    question: "Je suis déçu par l'histoire qui a été créée. Que puis-je faire ?",
    answer: "Les technologies d'intelligence artificielle peuvent parfois produire des résultats inattendus, " +
      "et nous ne pouvons pas garantir la qualité d'une histoire à chaque fois. Si une histoire ne vous plaît pas, " +
      "nous vous encourageons à essayer de générer une nouvelle histoire. Nous travaillons constamment à " +
      "améliorer nos algorithmes pour offrir des contenus de meilleure qualité et plus pertinents."
  },
  {
    question: "Comment puis-je m'assurer que le contenu de l'histoire sera approprié pour mon enfant ?",
    answer: "Nous entraînons nos intelligences artificielles avec des directives strictes pour éviter les thèmes " +
      "inappropriés et l'utilisation de mots non adéquats pour un public enfantin. Chaque histoire est conçue " +
      "pour être adaptée à l'âge spécifié de l'enfant et évite les contenus inappropriés. Cependant, nous " +
      "recommandons toujours la supervision d'un adulte lors de l'utilisation de notre application."
  },
  {
    question: "Que se passe-t-il si une réponse inappropriée est saisie par un utilisateur ?",
    answer: "Les réponses fournies par les utilisateurs sont filtrées et analysées. Si une réponse est jugée " +
      "offensante ou inadaptée, elle sera ignorée dans la création de l'histoire. Nous nous réservons le droit " +
      "de supprimer les comptes qui saisissent volontairement des contenus offensants, conformément à nos " +
      "conditions générales d'utilisation, notre priorité étant d'assurer un environnement sûr et positif pour " +
      "tous nos utilisateurs."
  },
  {
    question: "L'histoire qui a été créée me semble trop courte. Pourquoi ?",
    answer: "La longueur des histoires générées par notre intelligence artificielle peut varier en fonction de nombreux facteurs, " +
      "y compris les données d'entrée fournies et les spécificités du modèle utilisé. L'IA est imprévisible sur la longueur " +
      "des textes qu'elle produit car elle tente de créer une histoire cohérente et complète dans les limites de ce qu'elle " +
      "a appris de ses données d'entraînement. Parfois, cela peut résulter en des histoires plus courtes que prévu. Nous " +
      "continuons à travailler sur l'amélioration de notre modèle pour qu'il puisse générer des contenus qui répondent au mieux " +
      "à vos attentes de longueur et de richesse narrative."
  },
  {
    question: "Comment utilisez-vous mes données personnelles ?",
    answer: "Nous sommes pleinement conscients de l'importance de la protection des données, surtout lorsqu'il " +
      "s'agit de vos enfants. C'est pourquoi nous ne demandons pas de date de naissance précise, de nom de " +
      "famille, ni de photo de l'enfant. Les données que vous saisissez sont utilisées uniquement pour " +
      "personnaliser les histoires et améliorer votre expérience. Elles ne sont jamais partagées avec des " +
      "tiers, à l'exception de l'utilisation de la fonctionnalité de partage d'histoire quand vous " +
      "choisissez d'utiliser."
  },
  {
    question: "J'ai des idées pour améliorer l'application !",
    answer: "Nous sommes conscients que Fabulo n'en est qu'à ses débuts et qu'il y a de nombreuses améliorations " +
      "à y faire. Nous sommes preneurs de vos retours, c'est pourquoi, après " +
      "chaque histoire créée, vous avez la possibilité de la noter et de partager vos impressions si elle vous a déçu. " +
      "Nous sommes également demandeurs de vos idées pour enrichir et améliorer votre expérience. " +
      "N'hésitez pas à nous faire part de vos suggestions en nous contactant via le bouton ci-dessous !"
  },
];

export default faqData;
