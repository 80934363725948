import { KidStoryType, PostStoryType, StoryType } from '../../types/story';
import privateApi from '../../utils/privateApi';

export const postKidStory = async (payload: PostStoryType, kidId: number) => {
  return privateApi.post<KidStoryType>(
    `/api/v1/kids/${kidId}/stories`,
    payload
  );
};

export const getStory = async (kidId: number, storyId: number) => {
  try {
    const response = await privateApi.get<StoryType>(`/api/v1/kids/${kidId}/stories/${storyId}`);
    // console.log("Fetched Story Details:", response.data);
    return response;
  } catch (error) {
    console.error('Error fetching story:', error);
    throw error; // Re-throw the error for further handling
  }
};

export const voteOnStory = async (kidId: number, storyId: number, vote: 'up' | 'down') => {
  return privateApi.post(`/api/v1/kids/${kidId}/stories/${storyId}/vote`, { vote });
};

export const sendFeedback = async (kidId: number, storyId: number, feedback: string) => {
  return privateApi.post(`/api/v1/kids/${kidId}/stories/${storyId}/feedback`, { feedback });
};

export const deleteStory = async (kidId: number, storyId: number) => {
  // console.log("Deleting story with ID:", storyId, "for kid ID:", kidId);
  return privateApi.delete(`/api/v1/kids/${kidId}/stories/${storyId}`);
};
