import { useState, useEffect, useRef } from 'react';
import { Cable } from '@rails/actioncable';
import { ActionCableCallbacks, ChannelData, ReceivedData } from '../../types/actionCableTypes';
import { StoryUpdateData } from '../../types/story';

export default function useChannel(actionCable: Cable) {
  const [connected, setConnected] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const channelRef = useRef<any>(null);

  const subscribe = (data: ChannelData, callbacks: ActionCableCallbacks) => {
    const channel = actionCable.subscriptions.create(data, {
      received: (x: StoryUpdateData) => callbacks.received?.(x),
      initialized: () => {
        setSubscribed(true);
        callbacks.initialized?.();
      },
      connected: () => {
        setConnected(true);
        callbacks.connected?.();
      },
      disconnected: () => {
        setConnected(false);
        callbacks.disconnected?.();
      },
    });
    channelRef.current = channel;
  };

  const unsubscribe = () => {
    if (channelRef.current) {
      actionCable.subscriptions.remove(channelRef.current);
      channelRef.current = null;
    }
    setSubscribed(false);
  };

  useEffect(() => {
    return () => unsubscribe();
  }, []);

  return { subscribe, unsubscribe };
}
