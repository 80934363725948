export default {
  primary: '#5EC5FE',
  primary05: '#F6F9FE',
  primary10: '#ECF2FC',
  primary15: '#E7F6FF',
  primary25: '#CAE9FD',
  primaryDark: '#2B8FC7',
  primaryDarker: '#0D2938',
  secondary: '#F4C94A',
  secondary10: '#FEFAED',
  tertiary: '#F15A6B',
  tertiary10: '#FEEEF0',
  text: '#000000',
  primaryButtonText: '#000',
  secondaryButtonText: '#000',
  ButtonText: '#fff',
  grey: '#ccc',
  grey0: '#333333',
  grey1: '#4d4d4d',
  grey2: '#666666',
  grey3: '#808080',
  grey4: '#999999',
  grey5: '#b3b3b3',
  grey6: '#cccccc',
  grey7: '#e6e6e6',
  red: '#D32F2F',
  success: '#36c590',
  error: '#d6874c',
  disabled: '#fff',
  white: '#fff',
  readingBackground: '#1F1D7B',
  readerBackgroundColors: [
    '#2c0076', '#351e1a', '#411020', '#13250e', '#1a2c32', '#03301f'
  ],
};
