import React from 'react';
import { Image, StyleSheet, View } from 'react-native';
import LogoBlueClouds from '../../assets/images/logo_blue_clouds.png';

const Logo = ({ numKids }: { numKids: number }) => {
  const logoStyle = numKids > 2 ? styles.smallLogo : styles.logo;

  return (
    <View style={styles.container}>
      <Image source={LogoBlueClouds} style={logoStyle} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 32,
  },
  logo: {
    width: 180,
    height: 140,
    resizeMode: 'contain',
  },
  smallLogo: {
    width: 135,
    height: 105,
    resizeMode: 'contain',
  }
});

export default Logo;
