import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import LottieView from 'lottie-react-native';
import React, { useLayoutEffect, useState } from 'react';
import { Pressable, StyleSheet, View } from 'react-native';
import Avatar from '../../../assets/animations/avatar.json';
import { postFamilyKid } from '../../api/family/family.api';
import Button from '../../components/Button';
import EmojiSelector from '../../components/EmojiSelector';
import { useProgressBar } from '../../components/ProgressBarContext';
import { Emoji } from '../../constants/Enums';
import { globalStyles } from '../../constants/Styles';
import { RootStackParamList } from '../../types';
import handleApiError from '../../utils/functions/apiErrorHandling';

const SelectEmojiScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "SelectEmoji">) => {
  const navigation = useNavigation();
  const [selectedEmoji, setSelectedEmoji] = useState(Emoji.Unicorn);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { kidName, kidAge } = route.params;
  const { setCurrentStep } = useProgressBar();

  useFocusEffect(
    React.useCallback(() => {
      setCurrentStep(3);
    }, [])
  );

  const handleCreateKid = async () => {
    setIsButtonDisabled(true);
    try {
      const response = await postFamilyKid({ first_name: kidName, age: kidAge, emoji: selectedEmoji.toString() });
      const kidId = response.data.kid.id;
      // showToast(`Le profil ${startsWithVowel(kidName) ? "d'" : "de "}${kidName} a été bien ajouté 👌`);
      navigation.navigate('KidOverview', { kidId: kidId, kidName: kidName });
    } catch (error) {
      setIsButtonDisabled(false);
      handleApiError(error);
    }
  };

  // const showToast = (message: string) => {
  //   Toast.show({
  //     type: 'success',
  //     text1: 'Enfant ajouté !',
  //     text2: message,
  //   });
  // };

  const startsWithVowel = (name: string) => {
    return /^[aeiouh]/i.test(name);
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Son emoji',
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('MyFamily')} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });

  }, [navigation]);

  return (
    <View style={globalStyles.container}>
      <View style={styles.animationContainer}>
        <LottieView
          source={Avatar}
          autoPlay
          loop
          style={styles.lottieAnimation}
        />
      </View>
      <EmojiSelector selectedEmoji={selectedEmoji} onEmojiSelect={(emoji: string) => setSelectedEmoji(emoji as Emoji)} />
      <Button title="Valider" onPress={handleCreateKid} disabled={isButtonDisabled} />
    </View>
  );
};

const styles = StyleSheet.create({
  animationContainer: {
    height: 150,
    width: 150,
    alignSelf: 'center',
    paddingBottom: 32,
  },
  lottieAnimation: {
    height: '100%',
    width: '100%',
  },
});

export default SelectEmojiScreen;
