import privateApi from '../../utils/privateApi';
import { QuestionType } from '../../types/question';

export const getQuestions = async () => {
  return privateApi.get<QuestionType[]>('/api/v1/questions');
};

export const getQuestionById = async (questionId: number): Promise<QuestionType | null> => {
  try {
    const response = await privateApi.get<QuestionType>(`/api/v1/questions/${questionId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching question details:", error);
    return null;
  }
};

export const getEmotionsQuestionByTitle = async (title: string): Promise<QuestionType | null> => {
  try {
    const response = await privateApi.get<QuestionType[]>('/api/v1/questions');
    const questions = response.data;
    const matchingQuestion = questions.find(question => question.title === title && question.question_type === 'emotions');
    return matchingQuestion || null;
  } catch (error) {
    console.error("Error fetching question details:", error);
    return null;
  }
};

export const getEmotionsQuestionById = async (questionId: number): Promise<QuestionType | null> => {
  try {
    const response = await privateApi.get<QuestionType>(`/api/v1/questions/${questionId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching question details:", error);
    return null;
  }
};
