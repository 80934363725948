import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

export type Kid = {
  id: string;
  firstName: string;
  age: number;
  familyId: number;
  emoji: string;
};

export type KidsState = {
  kids: Kid[];
  isLoading: boolean;
  error: string | null;
};

const initialState: KidsState = {
  kids: [],
  isLoading: false,
  error: null,
};

export const fetchKidsData = createAsyncThunk('kids/fetchKidsData', async (familyId, thunkAPI) => {
  const state = thunkAPI.getState() as { app: { accessToken: string } };
  const token = state.app.accessToken; // Get the token from state
  try {
    const response = await makeApiCall('get', `/api/v1/families/${familyId}/kids`, null, token) as any;
    if (response && response.data) {
      return response.data as Kid[];
    } else {
      throw new Error('Failed to fetch data');
    }
  } catch (error) {
    throw error;
  }
});

const kidsSlice = createSlice({
  name: 'kids',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchKidsData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchKidsData.fulfilled, (state, action: PayloadAction<Kid[]>) => {
        state.isLoading = false;
        state.kids = action.payload;
        state.error = null;
      })
      .addCase(fetchKidsData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch data';
      });
  },
});

export const kidsReducer = kidsSlice.reducer;
export const selectAllKids = (state: { kids: KidsState }) => state.kids.kids;
export const selectKidById = (kidId: string) => (state: { kids: KidsState }) =>
  state.kids.kids.find((kid) => kid.id === kidId);

export default kidsSlice;
function makeApiCall(arg0: string, arg1: string, arg2: null, token: any) {
  throw new Error('Function not implemented.');
}
