import LottieView from 'lottie-react-native';
import React, { useEffect, useRef } from 'react';
import { Animated, StyleSheet, View } from 'react-native';

const WalkingDogAnimation = () => {
  const moveAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const startAnimation = () => {
      moveAnimation.setValue(0);
      Animated.timing(moveAnimation, {
        toValue: 1,
        duration: 11000,
        useNativeDriver: true,
      }).start(() => startAnimation());
    };

    startAnimation();
  }, [moveAnimation]);

  return (
    <View style={styles.animationContainer}>
      <Animated.View
        style={{
          ...styles.lottieAnimation,
          transform: [
            {
              translateX: moveAnimation.interpolate({
                inputRange: [0, 1],
                outputRange: [-200, 400],
              }),
            },
          ],
        }}
      >
        <LottieView
          source={require('../../assets/animations/walking-with-dog.json')}
          autoPlay
          loop
          style={{ width: 200, height: 100 }}
        />
      </Animated.View>
    </View>
  );
};

const styles = StyleSheet.create({
  animationContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 100,
  },
  lottieAnimation: {
    width: '100%',
    height: '100%',
  },
});

export default WalkingDogAnimation;
