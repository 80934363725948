import { AxiosResponse } from "axios";
import { useCallback, useEffect, useState } from "react";

import handleApiError from "../functions/apiErrorHandling";

function useLoadData<T>(loadFunction: () => Promise<AxiosResponse<T | null>>) {
  const [data, setData] = useState<T | null>(null);
  const [refreshing, setRefreshing] = useState(false);

  const loadData = async () => {
    try {
      const res = await loadFunction();
      setData(res.data);
    } catch (error) {
      // If it fails, handle the api message error if the error is actually an api error.
      handleApiError(error);
    }
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await loadData();
    setRefreshing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { refreshing, loadData, onRefresh, data };
}

export default useLoadData;
