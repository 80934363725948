import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  shuffledQuestions: [],
  currentIndex: 0,
};

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setShuffledQuestions: (state, action: PayloadAction<{ id: number, title: string }[]>) => {
      state.shuffledQuestions = action.payload;
      state.currentIndex = 0;
    },
    setCurrentQuestionIndex: (state, action: PayloadAction<number>) => {
      state.currentIndex = action.payload;
    },
  },
});

export const { setShuffledQuestions, setCurrentQuestionIndex } = questionsSlice.actions;

export default questionsSlice.reducer;
