import React, { useEffect, useState } from 'react';
import { Animated, StyleSheet, View } from 'react-native';

interface WaitingPhrasesProps {
  need?: string;
  theme?: string;
  kidName: string;
}

const WaitingPhrases: React.FC<WaitingPhrasesProps> = ({ need, theme, kidName }) => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const fadeAnim = useState(new Animated.Value(1))[0];

  const determinePreposition = (name: string) => {
    const vowels = ['a', 'e', 'i', 'o', 'u', 'h'];
    const firstLetter = name[0].toLowerCase();
    return vowels.includes(firstLetter) ? "d'" : "de ";
  };

  const staticPhrases = [
    "La magie de Fabulo opère 🪄",
    need ? `Génération d'une histoire pour ${need}` : "",
    theme ? `Sur le thème "${theme}"` : "",
    "L'enregistrement de la voix prend jusqu'à 5 minutes ⏱️",
    "Le saviez-vous 💡?"
  ];

  const randomPhrases = [
    `On relit les histoires préférées ${determinePreposition(kidName)}${kidName}`,
    "La guitare a été inventée en Espagne vers le 15e siècle 🎸🇪🇸",
    "Avant le 17ème siècle, les carottes étaient principalement de couleur pourpre 🥕",
    "Raconter des histoires stimule l'imagination et encourage la créativité des enfants 🌈",
    "Les koalas dorment jusqu'à 22 heures par jour 🐨",
    "Le café est la deuxième marchandise la plus échangée au monde après le pétrole ☕️",
    "Les poissons rouges n'ont pas de paupières, ils dorment donc les yeux ouverts 🐟",
    "La lumière du Soleil met environ 8 minutes et 20 secondes entre le Soleil et la Terre 🌞",
    "Il est impossible d'éternuer les yeux ouverts. Essayez, si vous osez ! 🤧",
    "Les contes avant le coucher améliorent le sommeil en instaurant une routine apaisante 🌜",
    "Au Japon, on compte 1 distributeur automatique pour 23 habitants 🇯🇵",
    "L'exposition précoce aux contes favorise l'apprentissage de la lecture 📖",
    "Le coeur d'une crevette est situé dans sa tête 🦐",
    "La Statue de la Liberté était de couleur cuivre mais a viré au vert en raison de l'oxydation 🗽",
    "Écouter des histoires développe les compétences linguistiques et enrichit le vocabulaire 📚",
    "Les bananes sont courbées parce qu'elles poussent vers le soleil 🍌",
    "Les flamants roses naissent blancs, leur couleur vient du bêta-carotène qu'elles consomment 🦩",
    "Les séances de lecture en groupe augmentent les compétences sociales de 24% 🧑‍🤝‍🧑",
    "Du miel trouvé dans les tombes des pharaons égyptiens est toujours comestible 🍯",
    "Le plus long mot palindrome en français est 'ressasser' 🔄",
    "Un groupe de hiboux est appelé un parlement. Cela reflète peut-être leur sagesse collective 🦉 ?",
    "Les enfants qui écoutent des contes montrent une pensée critique avancée 🤔",
    "Les guépards sont les seuls félins qui ne peuvent pas rétracter leurs griffes 🐆",
    "La plus ancienne recette connue est celle de la bière, datant de plus de 5 000 ans 🍺",
    "Les contes exposent les enfants à différentes cultures, augmentant leur ouverture d'esprit 🌍",
    "Le mot 'long' est plus court que 'court'. Ironique non ? 🔍",
    "La Tour Eiffel peut être 15 cm plus haute pendant l'été à cause de la chaleur 🗼",
    "Les vaches ont des amis et peuvent être stressées lorsqu'elles sont séparées 🐄",
    "Entendre des histoires développe les compétences d'écoute et de concentration 🧐",
    "Le premier appel téléphonique a dit : 'Monsieur Watson, venez ici, je veux vous voir' 📞",
    "Les bananes sont courbées parce qu'elles grandissent en direction du soleil 🍌",
    "Il y a plus de langues parlées à New York qu'il n'y a de pays dans le monde 🗽",
    "Les dents de requin sont remplacées toutes les deux semaines 🦈",
    "L'Islande a la consommation de Coca-Cola par habitant la plus élevée au monde 🇮🇸",
    "Les étoiles de mer n'ont pas de cerveau 🌟",
    "Un escargot peut dormir pendant trois ans sans interruption 🐌",
    "Les fourmis s'étirent au réveil le matin 🐜",
    "Le Canada a le plus grand nombre de lacs au monde (2 millions) 🇨🇦",
    "Un humain moyen mange environ 70 insectes par an pendant son sommeil 🦟 😴",
    "Les humains et les girafes ont le même nombre de dents 🦷",
    "Le pays le plus petit du monde est le Vatican, qui mesure seulement 44 hectares 🇻🇦",
    "La ville de Istanbul est située sur deux continents : l'Europe et l'Asie 🇹🇷",
    "Les papillons goûtent avec leurs pieds 🦋",
    "Le cœur humain bat environ 100 000 fois par jour 💓",
    "La France est le pays avec le plus grand nombre de fuseaux horaires 🌍",
    "Les fourmis ne dorment jamais et n'ont pas de poumons 🐜",
    "Il existe une ville appelée 'Rome' sur chaque continent 🌍",
    "Un kilogramme de plumes pèse autant qu'un kilogramme de plomb 🪶⚖️",
    "Un humain moyen produit assez de salive dans sa vie pour remplir deux piscines 🏊",
  ];

  const shuffleArray = (array: string[]) => array.sort(() => Math.random() - 0.5);
  const shuffledRandomPhrases = React.useMemo(() => shuffleArray(randomPhrases), []);

  const combinedPhrases = [...staticPhrases, ...shuffledRandomPhrases];

  useEffect(() => {
    const phraseTimer = setInterval(() => {
      Animated.timing(fadeAnim, {
        toValue: 0,
        duration: 500,
        useNativeDriver: true,
      }).start(() => {
        setCurrentPhraseIndex(prevIndex => (prevIndex + 1) % combinedPhrases.length);
        Animated.timing(fadeAnim, {
          toValue: 1,
          duration: 500,
          useNativeDriver: true,
        }).start();
      });
    }, 4000);

    return () => clearInterval(phraseTimer);
  }, [combinedPhrases, fadeAnim]);

  return (
    <View style={styles.container}>
      <Animated.Text
        style={[styles.text, { opacity: fadeAnim }]}
        numberOfLines={3}
        ellipsizeMode="tail"
      >
        {combinedPhrases[currentPhraseIndex]}
      </Animated.Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 16,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    marginTop: 32,
    fontFamily: 'Gilroy-Regular',
    fontSize: 20,
    lineHeight: 30,
    textAlign: 'center',
    height: 120,
  },
});

export default WaitingPhrases;
