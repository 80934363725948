import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform, Pressable, ScrollView, StyleSheet, Text, TouchableWithoutFeedback, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { postAnswer } from '../../api/answer/answer.api';
import { getKidAnswers } from '../../api/kid/kid.api';
import { getQuestions } from '../../api/question/question.api';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import Loader from '../../components/Loader';
import Colors from '../../constants/Colors';
import { AppNavigationProps, QuestionType, RootStackParamList } from '../../types';

const shuffleQuestions = (questions: any[]) => {
  for (let i = questions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [questions[i], questions[j]] = [questions[j], questions[i]];
  }
  return questions;
};

const CreateKidWorldScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "CreateKidWorld">) => {
  const navigation = useNavigation<AppNavigationProps<"CreateKidWorld">>();
  const { kidId, kidName, sectionKey } = route.params as RootStackParamList["CreateKidWorld"];
  const [answer, setAnswer] = useState('');
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<QuestionType | null>(null);
  const [placeholderText, setPlaceholderText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setAnswer('');
  }, [currentQuestion]);

  useLayoutEffect(() => {

    const fullTitle = `Le monde de ${kidName}`;

    const truncate = (str: string, maxLength: number) => {
      if (str.length <= maxLength) return str;
      return `${str.substring(0, maxLength - 3)}...`;
    };

    const truncatedTitle = truncate(fullTitle, 21);

    navigation.setOptions({
      title: truncatedTitle,
      headerLeft: () => null,
      headerRight: () => (
        <Pressable onPress={() => {
          navigation.goBack();
        }} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color={Colors.text} />
        </Pressable>
      ),
    });
  }, [navigation, kidName]);

  useEffect(() => {
    const fetchAnswersAndQuestions = async () => {
      try {
        const answersResponse = await getKidAnswers(kidId);
        const existingAnswers = answersResponse.data;
        const questionsResponse = await getQuestions();
        const allQuestions = questionsResponse.data;
        const hasAnswers = existingAnswers.length > 0;
        let filteredQuestions = allQuestions;

        if (hasAnswers) {
          filteredQuestions = allQuestions.filter(question =>
            !existingAnswers.some(answer => answer.question_id === question.id)
          );

          if (sectionKey) {
            filteredQuestions = filteredQuestions.filter(q => q.question_type === sectionKey);
          } else {
            // Exclude questions with question_type as 'contextual' and 'emotions'
            filteredQuestions = filteredQuestions.filter(q => q.question_type !== 'contextual' && q.question_type !== 'emotions');
          }
        }

        // Shuffle the filtered questions
        const shuffledQuestions = shuffleQuestions(filteredQuestions);
        setQuestions(shuffledQuestions);
        setCurrentQuestion(shuffledQuestions[0]);

        setIsLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setIsLoading(false);
      }
    };

    fetchAnswersAndQuestions();
  }, [kidId, sectionKey]);

  useEffect(() => {
    if (currentQuestion) {
      const randomIndex = Math.floor(Math.random() * currentQuestion.example_answers.length);
      setPlaceholderText(currentQuestion.example_answers[randomIndex]);
    }
  }, [currentQuestion]);

  const handleRefreshQuestion = () => {
    if (questions.length > 0) {
      const randomIndex = Math.floor(Math.random() * questions.length);
      setCurrentQuestion(questions[randomIndex]);
    }
  };

  const handleSave = async () => {
    if (answer.trim() === '') {
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Veuillez entrer une réponse avant de valider.'
      });
      return;
    }

    setIsLoading(true);
    try {
      if (currentQuestion) {
        await postAnswer(kidId, { question_id: currentQuestion.id, content: answer.trim() });
        Toast.show({
          type: 'success',
          text1: `Réponse ajoutée pour ${kidName} !`,
          text2: 'Merci d\'avoir complété son monde 🙏'
        });
        navigation.goBack();
      }
    } catch (error) {
      console.error('Error:', error);
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Erreur lors de l\'ajout de la réponse.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1, backgroundColor: Colors.primary05 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
    >
      <ScrollView
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps='handled'
      >
        <TouchableWithoutFeedback>
          <View style={{ flexGrow: 1, backgroundColor: Colors.primary05 }}>
            {isLoading ? (
              <View style={styles.loader}>
                <Loader />
              </View>
            ) : questions.length > 0 ? (
              <View>
                <View style={styles.questionContainer}>
                  <Text style={styles.question}
                    numberOfLines={3}
                    ellipsizeMode="tail">
                    {currentQuestion ? currentQuestion.title : ''}
                  </Text>
                </View>
                <View style={{ width: '100%' }}>
                  <InputField
                    value={answer}
                    onChangeText={setAnswer}
                    inputType="answer"
                    maxLength={50}
                    placeholder={placeholderText || 'Votre réponse !'}
                    autoFocus={true}
                  />
                  <Text style={styles.tipText}>
                    PS : les réponses détaillées et farfelues sont les bienvenues ✨!
                  </Text>
                  <Button title="Valider" onPress={handleSave} variant='primary' />
                  <Button
                    title="Changer de question"
                    variant='link'
                    onPress={handleRefreshQuestion}
                  />
                </View>
              </View>
            ) : (
              <View style={styles.noQuestionsContainer}>
                <Text style={styles.noQuestionsText}>
                  {sectionKey
                    ? `Il n'y a pas de questions disponibles dans la section "${sectionKey}".`
                    : "Il n'y a pas de questions disponibles pour le moment."
                  }
                </Text>
                <Button title="Ok" onPress={() => navigation.goBack()} variant='primary' />
              </View>
            )}
          </View>
        </TouchableWithoutFeedback>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  question: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    textAlign: 'center',
  },
  questionContainer: {
    marginTop: 16,
    height: 88,
    justifyContent: 'center',
  },
  refreshButton: {
    alignItems: 'center',
    marginVertical: 16,
  },
  buttonContainer: {
    flexWrap: 'wrap',
    flexDirection: 'row',
    marginTop: 16,
  },
  noQuestionsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  noQuestionsText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 16,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary05,
  },
  tipText: {
    fontFamily: 'Gilroy-Regular',
    marginTop: 4,
    marginBottom: 16,
    fontSize: 16,
    textAlign: 'center',
    color: Colors.grey1,
  },
});

export default CreateKidWorldScreen;
