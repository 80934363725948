import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import CustomBackButton from '../components/CustomBackButton';
import ProgressBar from '../components/ProgressBar';
import { useProgressBar } from '../components/ProgressBarContext';
import Colors from '../constants/Colors';
import { globalStyles } from '../constants/Styles';
import EnterKidNameScreen from '../screens/kidCreation/EnterKidNameScreen';
import SelectEmojiScreen from '../screens/kidCreation/SelectEmojiScreen';
import SelectKidAgeScreen from '../screens/kidCreation/SelectKidAgeScreen';

const KidCreationStack = createStackNavigator();

const KidCreationNavigator = () => {
  const { currentStep } = useProgressBar();
  const totalSteps = 4;

  const commonOptions = {
    headerShown: true,
    headerBackImage: () => <CustomBackButton />,
    headerTitleStyle: globalStyles.headerTitleStyle,
    headerTitleAlign: 'center',
    headerTintColor: Colors.text,
    headerStyle: {
      backgroundColor: Colors.primary05,
      borderBottomWidth: 1,
      borderBottomColor: 'black',
    },
  };

  return (
    <>
      <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      <KidCreationStack.Navigator
        screenOptions={{
          headerShown: false,
          headerBackTitleVisible: false,
          cardStyle: { backgroundColor: Colors.primary05 },
        }}
      >
        <KidCreationStack.Screen name="EnterKidName" component={EnterKidNameScreen} options={commonOptions} />
        <KidCreationStack.Screen name="SelectKidAge" component={SelectKidAgeScreen} options={commonOptions} />
        <KidCreationStack.Screen name="SelectEmoji" component={SelectEmojiScreen} options={commonOptions} />
      </KidCreationStack.Navigator>
    </>
  );
};

export default KidCreationNavigator;
