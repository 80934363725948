import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as Haptics from 'expo-haptics';
import React from 'react';
import { Platform, Pressable, StyleSheet } from 'react-native';

const CustomBackButton: React.FC = () => {
  const navigation = useNavigation();

  const handleHapticFeedback = () => {
    if (Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
    }
  };

  return (
    <Pressable
      onPress={() => {
        handleHapticFeedback();
        navigation.goBack();
      }}
      style={styles.customBackButton}
    >
      <MaterialCommunityIcons name="chevron-left" size={32} />
    </Pressable>
  );
};

const styles = StyleSheet.create({
  customBackButton: {
    marginLeft: Platform.select({ ios: 8, android: 0 }),
  },
});

export default CustomBackButton;
