import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import LottieView from 'lottie-react-native';
import React, { useLayoutEffect } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Button from '../../components/Button';
import Colors from '../../constants/Colors';
import { AppNavigationProps, RootStackParamList } from '../../types';
import Family from '../../../assets/animations/family.json';

const KidOnboardingScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "KidOnboarding">) => {
  const navigation = useNavigation<AppNavigationProps<"KidOnboarding">>();
  const { kidId, kidName } = route.params;

  const determinePreposition = (name: string) => {
    const vowels = ['a', 'e', 'i', 'o', 'u', 'h'];
    const firstLetter = name[0].toLowerCase();
    return vowels.includes(firstLetter) ? "d'" : "de ";
  };

  const handleAddElement = () => {
    navigation.replace('CreateWorldOnboarding', {
      kidId,
      kidName,
    });
  };

  const handleBackPress = () => {
    navigation.navigate('MyFamily');
  };

  useLayoutEffect(() => {

    // const truncate = (str: string, maxLength: number) => {
    //   if (str.length > maxLength) {
    //     return str.substring(0, maxLength - 3) + '...';
    //   }
    //   return str;
    // };

    // const formattedKidName = truncate(`Bienvenue ${kidName} !`, 21);

    navigation.setOptions({
      title: 'Son monde',
      headerLeft: () => (
        <Pressable onPress={handleBackPress} style={{ marginLeft: 16 }}>
          <MaterialCommunityIcons name="arrow-left" size={24} color="black" />
        </Pressable>
      ),
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('MyFamily')} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation, kidName]);

  return (
    <View style={styles.container}>
      <View style={styles.animationContainer}>
        <LottieView
          source={Family}
          autoPlay
          loop
          style={styles.lottieAnimation}
        />
      </View>
      <Text style={styles.title}>Le monde {determinePreposition(kidName)}{kidName}</Text>
      <Text style={styles.subtitle}>Avant de créer sa première histoire,{"\n"}
        nous avons besoin d’en savoir{"\n"}un peu plus sur {kidName} !</Text>
      <Button
        variant="secondary"
        title="Ajouter un élément"
        onPress={handleAddElement} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
    backgroundColor: Colors.primary05,
  },
  animationContainer: {
    height: 150,
    width: 150,
    alignSelf: 'center',
    paddingBottom: 8,
  },
  lottieAnimation: {
    height: '100%',
    width: '100%',
  },
  title: {
    fontFamily: 'GochiHandRegular',
    fontSize: 28,
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 16,
  },
  subtitle: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    marginBottom: 40,
  },
});

export default KidOnboardingScreen;
