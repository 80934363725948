import { useNavigation } from '@react-navigation/native';
import LottieView from 'lottie-react-native';
import React, { useLayoutEffect } from 'react';
import { KeyboardAvoidingView, Linking, ScrollView, StyleSheet, Text, View } from 'react-native';
import Button from '../../components/Button';
import FAQItem from '../../components/FAQItem';
import Colors from '../../constants/Colors';
import faqData from '../../data/faqData';

const HelpAndContactScreen = () => {
  const navigation = useNavigation();

  const handleEmailPress = async () => {
    const subject = encodeURIComponent('Fabulo');
    const body = encodeURIComponent('Il était une fois...');
    const url = `mailto:contact@fabulo.app?subject=${subject}&body=${body}`;

    // Check if the link can be opened
    const canOpen = await Linking.canOpenURL(url);
    if (canOpen) {
      Linking.openURL(url);
    } else {
      // Handle the situation when the link cannot be opened
      alert('Unable to open email client');
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Aide et contact',
    });
  }, [navigation]);

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior="padding">
      <ScrollView contentContainerStyle={styles.container}>
        <View style={styles.divider} />
        <View style={styles.headerRow}>
          <Text style={styles.headerText}>Foire aux questions</Text>
        </View>
        {faqData.map((faq, index) => (
          <View style={styles.shadowsContainer} key={index}>
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          </View>
        ))}
        <View style={styles.animationContainer}>
          <LottieView
            source={require('../../../assets/animations/artificial-intelligence.json')}
            autoPlay
            loop
            style={styles.lottieAnimation}
          />
        </View>
        <View style={styles.headerRow}>
          <Text style={styles.headerText}>Encore des questions ?</Text>
        </View>
        <Button title="Contactez-nous !" variant="primary" onPress={handleEmailPress} />
        <View style={styles.divider} />
      </ScrollView>
    </KeyboardAvoidingView>
  );
}

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    alignItems: 'center',
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  divider: {
    height: 24,
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  headerText: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 18,
    marginBottom: 0,
    textAlign: 'left',
  },
  shadowsContainer: {
    shadowOffset: {
      width: 2,
      height: 3
    },
    shadowOpacity: 1,
    shadowRadius: 0,
    elevation: 5
  },
  emailContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
  },
  emailText: {
    fontFamily: 'Gilroy-Regular',
    marginLeft: 10,
    fontSize: 16,
  },
  animationContainer: {
    height: 100,
    width: 100,
    paddingTop: 8,
  },
  lottieAnimation: {
    width: '100%',
    height: '100%',
  },
});

export default HelpAndContactScreen;
