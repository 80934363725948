import React, { useEffect, useState } from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import Colors from '../constants/Colors';
import { StoryType } from '../types';
import Loader from './Loader';

interface StoryCardProps {
  story: StoryType;
}

const StoryCard: React.FC<StoryCardProps> = ({ story }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate image loading time
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1000); // Adjust time as needed

    return () => clearTimeout(timer);
  }, []);

  const cloudName = 'dzcevvxsn';
  const transformationString = 'w_600,h_600,c_fill,g_auto';
  const imagePublicId = story.image_public_id;
  const imageFormat = 'jpg';
  const imageSource = imagePublicId
    ? { uri: `https://res.cloudinary.com/${cloudName}/image/upload/${transformationString}/${imagePublicId}.${imageFormat}` }
    : require('../../assets/images/story_placeholder_square.png');

  return (
    <View style={styles.card}>
      <View style={styles.imageContainer}>
        {isLoading && (
          <Loader style={styles.loader} />
        )}
        <Image
          source={imageSource}
          style={styles.cardImage}
          resizeMode="cover"
          onLoadEnd={() => setIsLoading(false)} // Hide loader once the image is loaded
        />
      </View>
      <View style={styles.textContainer}>
        <Text style={styles.cardTitle} numberOfLines={1} ellipsizeMode="tail">
          {story.title}
        </Text>
        <Text style={styles.cardSynopsis} numberOfLines={2} ellipsizeMode="tail">
          {story.synopsis}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    width: 300,
    aspectRatio: 1,
    borderWidth: 2,
    borderRadius: 25,
    backgroundColor: 'white',
    // Shadow properties for iOS
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 0,
    // Elevation for Android
    elevation: 3,
  },
  imageContainer: {
    height: 210,
    width: '100%',
    borderTopLeftRadius: 23,
    borderTopRightRadius: 23,
    overflow: 'hidden',
  },
  loader: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'rgba(255, 255, 255, 0.6)',
  },
  cardImage: {
    width: '100%',
    height: 210,
    borderTopLeftRadius: 23,
    borderTopRightRadius: 23,
  },
  cardTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 22,
  },
  textContainer: {
    paddingHorizontal: 16,
    paddingTop: 8,
    borderTopWidth: 2,
  },
  cardSynopsis: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 14,
    fontWeight: '400',
    color: Colors.grey1,
    paddingTop: 4,
    lineHeight: 18,
    overflow: 'hidden',
  },
});

export default StoryCard;
