import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { KeyboardAvoidingView, Platform, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { postAnswer } from '../../api/answer/answer.api';
import { getKidAnswers } from '../../api/kid/kid.api';
import { getQuestions } from '../../api/question/question.api';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import Loader from '../../components/Loader';
import ProgressBar from '../../components/ProgressBar';
import Colors from '../../constants/Colors';
import { AppNavigationProps, QuestionType, RootStackParamList } from '../../types';

const shuffleQuestions = (questions: any[]) => {
  for (let i = questions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [questions[i], questions[j]] = [questions[j], questions[i]];
  }
  return questions;
};

const CreateWorldOnboardingScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "CreateWorldOnboarding">) => {
  const navigation = useNavigation<AppNavigationProps<"CreateWorldOnboarding">>();
  const { kidId, kidName } = route.params;
  const [answer, setAnswer] = useState('');
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<QuestionType | null>(null);
  const [placeholderText, setPlaceholderText] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const totalQuestions = 3;

  const [answeredQuestionIds, setAnsweredQuestionIds] = useState(new Set());

  const fetchQuestions = async () => {
    setIsLoading(true);
    try {
      const answersResponse = await getKidAnswers(kidId);
      const existingAnswers = answersResponse.data.map(a => a.question_id);
      const questionsResponse = await getQuestions();
      let allQuestions = questionsResponse.data;

      // Exclude 'contextual' questions and previously answered questions
      allQuestions = allQuestions.filter(question =>
        question.question_type !== 'contextual' &&
        !existingAnswers.includes(question.id)
      );

      // Shuffle and select questions
      const shuffledQuestions = shuffleQuestions(allQuestions);
      setQuestions(shuffledQuestions);
      setCurrentQuestion(shuffledQuestions[0]);
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions(); // Call fetchQuestions when component mounts
  }, [kidId]); // Dependency array

  const handleSave = async () => {
    if (answer.trim() === '') {
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Veuillez entrer une réponse avant de valider.'
      });
      return;
    }

    setIsLoading(true);
    try {
      if (currentQuestion) {
        await postAnswer(kidId, { question_id: currentQuestion.id, content: answer.trim() });

        // Add the current question ID to the set of answered questions
        setAnsweredQuestionIds(prevSet => new Set(prevSet.add(currentQuestion.id)));

        const newCount = currentQuestionIndex + 1;
        if (newCount >= totalQuestions) {
          navigation.navigate('StoryOnboarding', { kidId, kidName });
        } else {
          setCurrentQuestion(questions[newCount]);
          setCurrentQuestionIndex(newCount);
        }
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRefreshQuestion = () => {
    // Filter out answered questions
    const availableQuestions = questions.filter(q => !answeredQuestionIds.has(q.id));
    if (availableQuestions.length > 0) {
      const randomIndex = Math.floor(Math.random() * availableQuestions.length);
      setCurrentQuestion(availableQuestions[randomIndex]);
    }
  };

  useEffect(() => {
    setAnswer('');
  }, [currentQuestion]);

  useEffect(() => {
    if (currentQuestion) {
      const randomIndex = Math.floor(Math.random() * currentQuestion.example_answers.length);
      setPlaceholderText(currentQuestion.example_answers[randomIndex]);
    }
  }, [currentQuestion]);

  useLayoutEffect(() => {
    const titles = ['Première question...', 'Une seconde...', 'Et une dernière !'];
    navigation.setOptions({
      title: titles[currentQuestionIndex] || `Le monde de ${kidName}`,
      headerLeft: () => null,
      headerRight: () => (
        <Pressable onPress={() => navigation.goBack()} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color={Colors.text} />
        </Pressable>
      ),
    });
  }, [navigation, kidName, currentQuestionIndex]);

  if (isLoading) {
    return <Loader style={styles.loader} />;
  }

  return (
    <KeyboardAvoidingView
      style={{ flex: 1, backgroundColor: Colors.primary05 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
    >
      <ProgressBar currentStep={currentQuestionIndex + 1} totalSteps={totalQuestions + 1} />
      <ScrollView
        contentContainerStyle={styles.container}
        keyboardShouldPersistTaps='handled'
        style={{ flexGrow: 1, backgroundColor: Colors.primary05 }}
      >
        {
          currentQuestion && (
            <View>
              <View style={styles.questionContainer}>
                <Text style={styles.question}
                  numberOfLines={3}
                  ellipsizeMode="tail">
                  {currentQuestion.title}
                </Text>
              </View>
              <View style={{ width: '100%' }}>
                <InputField
                  value={answer}
                  onChangeText={setAnswer}
                  inputType="answer"
                  maxLength={50}
                  placeholder={placeholderText || 'Votre réponse !'}
                  autoFocus={true}
                />
                <Text style={styles.tipText}>
                  PS : les réponses détaillées et farfelues sont les bienvenues ✨!
                </Text>
                <Button title="Valider" onPress={handleSave} variant='primary' />
                <Button
                  title="Changer de question"
                  variant='link'
                  onPress={handleRefreshQuestion}
                />
              </View>
            </View>
          )
        }
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  question: {
    fontSize: 24,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  questionContainer: {
    marginTop: 16,
    height: 88,
    justifyContent: 'center',
  },
  tipText: {
    fontFamily: 'Gilroy-Regular',
    marginTop: 4,
    marginBottom: 16,
    fontSize: 16,
    textAlign: 'center',
    color: Colors.grey1,
  },
});

export default CreateWorldOnboardingScreen;
