import privateApi from '../../utils/privateApi';

export const getMusicDetails = async (musicId) => {
  try {
    const response = await privateApi.get(`/api/v1/musics/${musicId}`);
    return response;
  } catch (error) {
    console.error('Error fetching music details:', error);
    throw error;
  }
};

export const getAllMusics = async () => {
  try {
    const response = await privateApi.get('/api/v1/musics');
    return response;
  } catch (error) {
    console.error('Error fetching all musics:', error);
    throw error;
  }
};

export default {
  getMusicDetails,
  getAllMusics,
};
