import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StoriesState {
  readStories: { [storyId: number]: boolean };
  adviceModalShown: boolean;
}

const initialState: StoriesState = {
  readStories: {},
  adviceModalShown: false,
};

export const storiesSlice = createSlice({
  name: 'readStories',
  initialState,
  reducers: {
    setStoryRead: (state, action: PayloadAction<number>) => {
      state.readStories[action.payload] = true;
    },
    setAdviceModalShown: (state) => {
      state.adviceModalShown = true;
    },
  },
});

export const { setStoryRead, setAdviceModalShown } = storiesSlice.actions;

export default storiesSlice.reducer;
