import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { useKeepAwake } from 'expo-keep-awake';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Animated, Pressable, StyleSheet, Text, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import AnimatedStoryStep from '../../components/AnimatedStoryStep';
import Button from '../../components/Button';
import { useProgressBar } from '../../components/ProgressBarContext';
import StoryProgressBar from '../../components/StoryProgressBar';
import WaitingPhrases from '../../components/WaitingPhrases';
import Colors from '../../constants/Colors';
import { globalStyles } from '../../constants/Styles';
import environment from '../../environments';
import { setDiscoverBlockVisibility } from '../../store/slices/discoverBlockSlice';
import { RootState } from '../../store/store';
import { StoryCreationNavigationProps, StoryCreationStackParamList } from '../../types';
import { StepMap } from '../../types/actionCableTypes';
import { StoryUpdateData } from '../../types/story';
import useActionCable from '../../utils/hooks/useActionCable';
import useChannel from '../../utils/hooks/useChannel';

const InstantStoryGenerationScreen = ({ route }: NativeStackScreenProps<StoryCreationStackParamList, "InstantStoryGeneration">) => {
  const navigation = useNavigation<StoryCreationNavigationProps<"InstantStoryGeneration">>();
  const { kidId, kidName, storyId, need, theme } = route.params;
  const [dataReceived, setDataReceived] = useState([false, false, false]);
  const [isLoading, setIsLoading] = useState(true);
  const discoverFadeAnim = new Animated.Value(0);
  const dispatch = useDispatch();
  const showDiscover = useSelector((state: RootState) => state.discoverBlock.isVisible);
  const { actionCable } = useActionCable(environment.wsUrl);
  const { subscribe } = useChannel(actionCable);

  const currentStepIndex = dataReceived.filter(received => received).length;
  let currentStepType: 'text' | 'voice' | 'image';
  if (currentStepIndex === 0) {
    currentStepType = 'text';
  } else if (currentStepIndex === 1) {
    currentStepType = 'voice';
  } else {
    currentStepType = 'image';
  }

  const { setCurrentStep } = useProgressBar();
  useKeepAwake();

  useFocusEffect(
    React.useCallback(() => {
      setCurrentStep(4);
    }, [])
  );

  useEffect(() => {
    let discoverTimer = setTimeout(() => {
      console.log("Attempting to set discover block to visible.");
      dispatch(setDiscoverBlockVisibility(true));
    }, 8000);

    subscribe(
      { channel: 'StoryChannel', id: storyId },
      {
        received: (data: StoryUpdateData) => {
          console.log('Received data:', data);

          const stepToIndexMap: StepMap = {
            text: 0,
            audio: 1,
            image: 2,
          };

          const indexToUpdate = stepToIndexMap[data.step as keyof StepMap];
          if (indexToUpdate !== undefined) {
            setDataReceived(prevState => {
              const updatedState = prevState.map((item, index) => index === indexToUpdate ? true : item);
              if (updatedState.every(item => item)) {
                setIsLoading(false);
                setTimeout(() => {
                  navigation.navigate('CreateWorldInstant', { kidId, kidName, storyId })
                }, 2000);
              }
              return updatedState;
            });
          }
        },
      }
    );

    return () => {
      clearTimeout(discoverTimer);
      dispatch(setDiscoverBlockVisibility(false));
    };
  }, [storyId, kidName, navigation, subscribe, dispatch]);

  const currentStep = dataReceived.filter(received => received).length;

  useLayoutEffect(() => {
    navigation.setOptions({
      title: `Fabulo crée l’histoire`,
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerLeft: () => null,
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('CreateWorldInstant', { kidId, kidName, storyId })}
          style={{
            marginRight: 16
          }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation, kidName]);

  return (
    <View style={globalStyles.container}>
      <AnimatedStoryStep stepType={currentStepType} />
      <View style={styles.progressBarContainer}>
        <StoryProgressBar currentStep={currentStep + 1} totalSteps={dataReceived.length + 1} />
      </View>
      <WaitingPhrases need={need} theme={theme} kidName={kidName} />
      <View style={{ height: 100 }}>
        {
          showDiscover && (
            <Animated.View
              style={{
                ...styles.discoverBlock,
                opacity: discoverFadeAnim,
              }}
            >
              <Text style={styles.discoverText}>
                Votre histoire est bientôt prête,
              </Text>
              <Text style={styles.discoverText}>
                explorez l'application pendant que Fabulo la termine !
              </Text>
              <View style={styles.discoverButton}>
                <Button
                  title="Je découvre"
                  variant="secondary"
                  onPress={() => navigation.navigate('Main' as any, { screen: 'KidOverview', params: { kidId, kidName } })}
                  style={{ marginTop: 10 }}
                />
              </View>
            </Animated.View>
          )
        }
      </View>
    </View >
  );
};

const styles = StyleSheet.create({
  progressBarContainer: {
    height: 64,
    width: '100%',
    overflow: 'hidden',
  },
  discoverBlock: {
    position: 'absolute',
    bottom: 20,
    left: 0,
    right: 0,
    alignSelf: 'center',
  },
  discoverText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    textAlign: 'center',
    color: Colors.text,
  },
  discoverButton: {
    paddingVertical: 16,
  },
});

export default InstantStoryGenerationScreen;
