import axios from 'axios';
import environment from '../environments';

const publicApi = axios.create({
  baseURL: environment.apiUrl,
});

publicApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: unknown) => {
    // If token has expired, then we logout...
    /*if (axios.isAxiosError(error) && error?.response?.status === 401) {
      store.dispatch(appSlice.actions.resetAppState());
    }*/
    return Promise.reject(error);
  }
);

export default publicApi;
