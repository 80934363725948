import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useLayoutEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Modal from 'react-native-modal';
import { postKidStory } from '../../api/story/story.api';
import Button from '../../components/Button';
import CustomHeaderTitle from '../../components/CustomHeader';
import { useProgressBar } from '../../components/ProgressBarContext';
import StoryTypeCard from '../../components/StoryTypeCard';
import Colors from '../../constants/Colors';
import { globalStyles } from '../../constants/Styles';
import { PostStoryType, StoryCreationNavigationProps, StoryCreationStackParamList } from '../../types';

const StoryTypeScreen = ({ route }: NativeStackScreenProps<StoryCreationStackParamList, "StoryType">) => {
  const navigation = useNavigation<StoryCreationNavigationProps<"StoryType">>();
  const { kidId, kidName } = route.params;
  const [showModal, setShowModal] = useState(false);
  const { setCurrentStep } = useProgressBar();

  useFocusEffect(
    React.useCallback(() => {
      setCurrentStep(1);
    }, [])
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: () => <CustomHeaderTitle title={`Quel type d'histoire ?`} style={globalStyles.headerTitleStyle} />,
      headerShadowVisible: false,
      headerBackTitleVisible: false,
    });
  }, [navigation, kidName]);

  const handleGuidedStoryCreation = () => {
    navigation.navigate('NeedSelection', { kidId, kidName }
    );
  }

  const handleConfirmInstantStory = () => {
    setShowModal(false);
    CreateInstantStory();
  };

  const CreateInstantStory = async () => {
    try {
      const storyPayload: PostStoryType = {
        need: "⚡️ Histoire instantanée",
        theme: "None",
        kid_id: kidId,
        instant_generation: true,
      };

      const response = await postKidStory(storyPayload, kidId);
      const storyId = response.data.story.id;

      navigation.navigate('InstantStoryGeneration', { kidId, kidName, storyId });
    } catch (error) {
      console.error('Error creating instant story:', error);
    }
  };

  return (
    <>
      <View style={globalStyles.container}>
        <StoryTypeCard
          title="Histoire guidée 🧭"
          description="Je réponds à quelques questions pour créer une histoire sur mesure."
          buttonTitle="J'y vais"
          onPress={handleGuidedStoryCreation}
          buttonColor={Colors.secondary}
          showIcon={true}
        />
        <StoryTypeCard
          title="Histoire surprise ⚡️"
          description="Je créé une histoire personnalisée surprise."
          buttonTitle="Surprenez-moi"
          onPress={() => setShowModal(true)}
          buttonColor={Colors.primary}
          showIcon={true}
        />
      </View>
      <Modal isVisible={showModal} >
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Une nouvelle histoire</Text>
          <Text style={styles.modalText}>
            Souhaitez-vous utiliser 1 crédit pour créer une histoire instantanée ?
          </Text>
          <Button title="Valider" variant="primary" onPress={handleConfirmInstantStory} />
          <Button title="Annuler" variant="secondary" onPress={() => setShowModal(false)} />
        </View>
      </Modal >
    </>
  );
}

const styles = StyleSheet.create({
  modalContent: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default StoryTypeScreen;
