import React, { createContext, useContext, useState } from 'react';

const ProgressBarContext = createContext({
  currentStep: 1,
  setCurrentStep: (step: number) => {},
});

export const useProgressBar = () => useContext(ProgressBarContext);

export const ProgressBarProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <ProgressBarContext.Provider value={{ currentStep, setCurrentStep }}>
      {children}
    </ProgressBarContext.Provider>
  );
};
