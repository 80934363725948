import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Colors from '../constants/Colors';
import Button from './Button';

interface StoryTypeCardProps {
  title: string;
  description: string;
  buttonTitle: string;
  onPress: () => void;
  buttonColor: string;
  showIcon?: boolean;
}

const StoryTypeCard: React.FC<StoryTypeCardProps> = ({
  title,
  description,
  buttonTitle,
  onPress,
  buttonColor,
  showIcon = false,
}) => {
  return (
    <View style={styles.typeCard}>
      <View style={styles.textContainer}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <Text style={styles.description}>{description}</Text>
      <Button
        title={buttonTitle}
        onPress={onPress}
        variant="primary"
        iconRight={showIcon ? "coin" : undefined}
        style={[styles.button, { backgroundColor: buttonColor }]}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  typeCard: {
    width: '100%',
    height: 200,
    padding: 20,
    borderWidth: 2,
    borderRadius: 25,
    backgroundColor: Colors.white,
    marginBottom: 20,
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 0,
    elevation: 3,
    justifyContent: 'space-between',
  },
  textContainer: {

  },
  title: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 18,
    color: Colors.text,
  },
  description: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    color: Colors.text,
    lineHeight: 24,
    marginVertical: 8,
  },
  button: {
    marginTop: 8,
  },
});

export default StoryTypeCard;
