import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import CustomBackButton from '../components/CustomBackButton';
import ProgressBar from '../components/ProgressBar';
import { useProgressBar } from '../components/ProgressBarContext';
import Colors from '../constants/Colors';
import { globalStyles } from '../constants/Styles';
import CustomEmotionScreen from '../screens/storyCreation/CustomEmotionScreen';
import CustomQuestionScreen from '../screens/storyCreation/CustomQuestionScreen';
import EmotionSelectionScreen from '../screens/storyCreation/EmotionSelectionScreen';
import InstantStoryGenerationScreen from '../screens/storyCreation/InstantStoryGenerationScreen';
import NeedSelectionScreen from '../screens/storyCreation/NeedSelectionScreen';
import StoryGenerationScreen from '../screens/storyCreation/StoryGenerationScreen';
import StoryTypeScreen from '../screens/storyCreation/StoryTypeScreen';
import SubThemeSelectionScreen from '../screens/storyCreation/SubThemeSelectionScreen';
import ThemeSelectionScreen from '../screens/storyCreation/ThemeSelectionScreen';

const StoryCreationStack = createStackNavigator();

const StoryCreationNavigator = () => {
  const { currentStep } = useProgressBar();
  const totalSteps = 5;

  return (
    <>
      <ProgressBar currentStep={currentStep} totalSteps={totalSteps} />
      <StoryCreationStack.Navigator
        screenOptions={{
          headerShown: true,
          headerBackImage: () => <CustomBackButton />,
          headerTitleStyle: globalStyles.headerTitleStyle,
          headerTitleAlign: 'center',
          headerTintColor: Colors.text,
          headerStyle: {
            backgroundColor: Colors.primary05,
            borderBottomWidth: 1,
            borderBottomColor: 'black',
          },
        }}
      >
        <StoryCreationStack.Screen name="CustomEmotion" component={CustomEmotionScreen} />
        <StoryCreationStack.Screen name="CustomQuestion" component={CustomQuestionScreen} />
        <StoryCreationStack.Screen name="EmotionSelection" component={EmotionSelectionScreen} />
        <StoryCreationStack.Screen name="InstantStoryGeneration" component={InstantStoryGenerationScreen} />
        <StoryCreationStack.Screen name="NeedSelection" component={NeedSelectionScreen} />
        <StoryCreationStack.Screen name="StoryGeneration" component={StoryGenerationScreen} />
        <StoryCreationStack.Screen name="StoryType" component={StoryTypeScreen} />
        <StoryCreationStack.Screen name="ThemeSelection" component={ThemeSelectionScreen} />
        <StoryCreationStack.Screen name="SubThemeSelection" component={SubThemeSelectionScreen} />
      </StoryCreationStack.Navigator>
    </>
  );
};

export default StoryCreationNavigator;
