import * as Haptics from 'expo-haptics';
import React, { useEffect, useRef } from 'react';
import { Animated, Platform, StyleSheet, View } from 'react-native';
import Colors from '../constants/Colors';

const StoryProgressBar = ({ currentStep, totalSteps }: { currentStep: number; totalSteps: number }) => {
  const animationProgress = useRef(new Animated.Value(0)).current;
  const shimmerAnimation = useRef(new Animated.Value(0)).current;

  // Start the shimmer animation immediately on mount and loop infinitely
  useEffect(() => {
    Animated.loop(
      Animated.timing(shimmerAnimation, {
        toValue: 1,
        duration: 2000,
        useNativeDriver: true,
      })
    ).start();
  }, []);

  // Update the progress animation on step change
  useEffect(() => {
    Animated.timing(animationProgress, {
      toValue: currentStep / totalSteps,
      duration: 500,
      useNativeDriver: true,
    }).start(() => {
      if (Platform.OS !== 'web') {
        Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Medium);
      }
    });
  }, [currentStep, totalSteps]);

  const progressScaleX = animationProgress.interpolate({
    inputRange: [0, 1],
    outputRange: [0, 1],
  });

  const shimmerTranslateX = shimmerAnimation.interpolate({
    inputRange: [0, 1],
    outputRange: [-500, 1200],
  });

  return (
    <View style={styles.progressBarContainer}>
      <Animated.View
        style={[
          styles.progressBar,
          {
            transform: [{ scaleX: progressScaleX }],
            width: '100%',
          },
        ]}
      >
        <Animated.View
          style={[
            styles.shimmerOverlay,
            {
              transform: [
                { translateX: shimmerTranslateX },
                { rotate: '-45deg' },
              ],
              width: '800%',
              left: '-300%',
            },
          ]}
        />
      </Animated.View>
      <View
        style={{
          ...styles.progressBar,
          backgroundColor: Colors.grey6,
          width: `${100 - (currentStep / totalSteps) * 100}%`,
          position: 'absolute',
          right: 0,
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  progressBarContainer: {
    height: 24,
    width: '100%',
    backgroundColor: Colors.grey6,
    borderRadius: 20,
    overflow: 'hidden',
    marginVertical: 16,
    borderWidth: 2,
    borderColor: Colors.text,
  },
  progressBar: {
    height: '100%',
    backgroundColor: Colors.success,
    borderRadius: 20,
    transformOrigin: 'left center',
  },
  shimmerOverlay: {
    position: 'absolute',
    height: '100%',
    backgroundColor: 'rgba(255,255,255,0.4)',
  },
});

export default StoryProgressBar;
