import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface DiscoverBlockState {
  isVisible: boolean;
}

const initialState: DiscoverBlockState = {
  isVisible: false,
};

export const discoverBlockSlice = createSlice({
  name: 'discoverBlock',
  initialState,
  reducers: {
    setDiscoverBlockVisibility: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
  },
});

export const { setDiscoverBlockVisibility } = discoverBlockSlice.actions;

export default discoverBlockSlice.reducer;
