import privateApi from '../../utils/privateApi';
import { PostAnswerType } from '../../types/answer';

export const postAnswer = async (kidId: number, payload: PostAnswerType) => {
  return privateApi.post(`/api/v1/kids/${kidId}/answers`, payload);
};

export const updateAnswer = async (answerId: number, newContent: string) => {
  try {
    const response = await privateApi.put(`/api/v1/answers/${answerId}`, {
      content: newContent,
    });

    return response.data;
  } catch (error) {
    console.error('Error updating answer:', error);
    throw error;
  }
};

export const deleteAnswer = async (answerId: number) => {
  try {
    const response = await privateApi.delete(`/api/v1/answers/${answerId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting answer:', error);
    throw error;
  }
};

