import privateApi from '../../utils/privateApi';

interface UserData {
  first_name: string;
  last_name: string;
  email: string
}

export const updateUser = async (userData: UserData) => {
  try {
    const response = await privateApi.put('/auth', { user: userData });
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

export const deleteUserProfile = async () => {
  try {
    const response = await privateApi.delete('/auth');
    return response.data;
  } catch (error) {
    console.error('Error deleting user profile:', error);
    throw error;
  }
};

interface PasswordUpdateData {
  current_password: string;
  password: string;
  password_confirmation: string
}

export const updatePassword = async (passwordUpdateData: PasswordUpdateData) => {
  try {
    const response = await privateApi.put('/auth', { user: passwordUpdateData });
    return response.data;
  } catch (error) {
    console.error('Error updating password:', error);
    throw error;
  }
};

export const getUserCredits = async () => {
  return privateApi.get('/api/v1/credits');
};
