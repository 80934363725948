import LottieView from 'lottie-react-native';
import React, { useEffect, useRef } from 'react';
import { Animated } from 'react-native';

const CarAnimation = () => {
  const moveAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    const startAnimation = () => {
      moveAnimation.setValue(0);
      Animated.timing(moveAnimation, {
        toValue: 1,
        duration: 8000,
        useNativeDriver: true,
      }).start(() => startAnimation());
    };

    startAnimation();
  }, [moveAnimation]);

  return (
    <Animated.View
      style={{
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: 100,
        transform: [
          {
            translateX: moveAnimation.interpolate({
              inputRange: [0, 1],
              outputRange: [-200, 500],
            }),
          },
        ],
      }}
    >
      <LottieView
        source={require('../../assets/animations/car.json')}
        autoPlay
        loop
        style={{ width: 200, height: 100 }}
      />
    </Animated.View>
  );
};

export default CarAnimation;
