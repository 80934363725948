import { MaterialCommunityIcons } from '@expo/vector-icons';
import * as Haptics from 'expo-haptics';
import React from 'react';
import { Image, Platform, Pressable, StyleProp, StyleSheet, Text, TextStyle, View, ViewStyle } from 'react-native';
import Colors from '../constants/Colors';

interface ButtonProps {
  title?: string;
  onPress: () => void;
  variant?: 'primary' | 'primary-outline' | 'secondary' | 'tertiary' | 'danger' | 'disabled' | 'link' | 'select' | 'circular' | 'circular-small';
  icon?: string;
  size?: 'small' | 'large';
  style?: StyleProp<ViewStyle>;
  disabled?: boolean;
  iconColor?: string;
  iconRight?: string;
  textOverrideStyle?: StyleProp<TextStyle>;
}

const Button: React.FC<ButtonProps> = ({
  title,
  onPress,
  variant = 'primary',
  icon,
  size = 'large',
  style,
  disabled,
  iconColor,
  iconRight,
  textOverrideStyle,
}) => {
  const shadowProperties = size === 'small'
    ? { shadowOffset: { width: 2, height: 3 }, shadowOpacity: 1, shadowRadius: 0, elevation: 5 }
    : { shadowOffset: { width: 3, height: 4 }, shadowOpacity: 1, shadowRadius: 0, elevation: 5 };

  const buttonStyles = {
    primary: {
      button: {
        backgroundColor: Colors.primary,
        borderWidth: 2,
        borderColor: Colors.text,
        borderRadius: 40,
        shadowColor: '#000000',
        ...shadowProperties,
      },
      text: {
        color: Colors.primaryButtonText,
      },
    },
    'primary-outline': {
      button: {
        backgroundColor: 'white',
        borderWidth: 2,
        borderColor: Colors.primaryDark,
        borderRadius: 40,
        shadowColor: Colors.primaryDark,
        ...shadowProperties,
      },
      text: {
        color: Colors.primaryDark,
      },
    },
    secondary: {
      button: {
        backgroundColor: Colors.secondary,
        borderWidth: 2,
        borderColor: Colors.text,
        borderRadius: 40,
        shadowColor: '#000000',
        ...shadowProperties,
      },
      text: {
        color: Colors.text,
      },
    },
    tertiary: {
      button: {
        backgroundColor: Colors.tertiary,
        borderWidth: 2,
        borderColor: Colors.text,
        borderRadius: 40,
        shadowColor: '#000000',
        ...shadowProperties,
      },
      text: {
        color: Colors.text,
      },
    },
    danger: {
      button: {
        backgroundColor: 'transparent',
        borderWidth: 2,
        borderColor: 'transparent',
        borderRadius: 40,
      },
      text: {
        color: Colors.red,
      },
    },
    disabled: {
      button: {
        backgroundColor: Colors.grey4,
        borderWidth: 2,
        borderColor: 'transparent',
        borderRadius: 40,
        shadowColor: '#000000',
        ...shadowProperties,
      },
      text: {
        color: Colors.grey3,
      },
    },
    link: {
      button: {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      },
      text: {
        color: Colors.text,
        textDecorationLine: 'underline',
      },
    },
    select: {
      button: {
        backgroundColor: 'white',
        borderColor: Colors.text,
        borderWidth: 2,
        borderRadius: 40,
        shadowColor: '#000000',
        ...shadowProperties,
      },
      text: {
        color: Colors.text,
      },
    },
    circular: {
      button: {
        backgroundColor: 'white',
        borderRadius: 25,
        width: 50,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderColor: Colors.text,
        shadowColor: '#000000',
        ...shadowProperties,
      },
      text: {
        fontSize: 24,
        color: Colors.text,
      },
      iconSize: 24,
    },
    'circular-small': {
      button: {
        backgroundColor: 'white',
        borderRadius: 20,
        width: 30,
        height: 30,
        alignItems: 'center',
        justifyContent: 'center',
        borderWidth: 2,
        borderColor: Colors.text,
        shadowColor: '#000000',
        ...shadowProperties,
      },
      iconSize: 16,
    },
  };

  // Determine the correct button and text styles based on the variant
  const buttonStyle = buttonStyles[variant]?.button || buttonStyles['primary'].button;
  const textStyle = buttonStyles[variant]?.text || buttonStyles['primary'].text;
  const textAlignmentStyle = iconRight ? { justifyContent: 'flex-start' } : {};
  const iconRightStyle = iconRight ? styles.iconRightWithMargin : {};

  // Apply specific styles for size, but exclude circular and circular-small variants
  let sizeStyle = {};
  if (variant !== 'circular' && variant !== 'circular-small') {
    sizeStyle = size === 'small' ? styles.small : styles.large;
  }

  const handleHapticFeedback = () => {
    if (Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
  };

  return (
    <Pressable
      style={({ pressed }) => [
        styles.button,
        buttonStyle,
        sizeStyle,
        style,
        disabled && buttonStyles['disabled'].button,
        (variant !== 'circular' && variant !== 'circular-small') && { paddingHorizontal: 16 },
        {
          opacity: pressed ? 0.5 : 1,
        },
      ]}
      onPress={() => {
        if (!disabled) {
          handleHapticFeedback();
          onPress();
        }
      }}
      disabled={disabled}
    >
      <View style={styles.buttonContent}>
        {icon && (
          <MaterialCommunityIcons
            name={icon}
            size={variant === 'circular-small' ? buttonStyles['circular-small'].iconSize : buttonStyles['circular'].iconSize}
            color={iconColor || Colors.text}
          />
        )}
        {title && (
          <Text style={[styles.text, textStyle, size === 'small' ? styles.smallText : styles.largeText, textAlignmentStyle, textOverrideStyle]}>
            {title}
          </Text>
        )}
        {iconRight && (
          <View style={[styles.iconRightBase, iconRightStyle]}>
            <Image source={require('../../assets/images/coin_border.png')} style={styles.iconImage} />
          </View>
        )}
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  button: {
    marginVertical: 8,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonContent: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    flex: 1,
  },
  iconRightStyle: {
    width: 20,
    height: 20,
    marginLeft: 4,
  },
  selectButtonContent: {
    justifyContent: 'space-between',
  },
  text: {
    fontFamily: 'Gilroy-Bold',
    flex: 1,
    textAlign: 'center',
  },
  small: {
    height: 36,
    paddingHorizontal: 16,
    fontSize: 14,
  },
  large: {
    height: 48,
    paddingHorizontal: 16,
  },
  smallText: {
    fontSize: 14,
  },
  largeText: {
    fontSize: 16,
  },
  iconRightContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: 10,
  },
  iconRight: {
    width: 20,
    height: 20,
  },
  iconRightBase: {
    padding: 5,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconRightWithMargin: {
    paddingTop: 7,
    marginRight: -10,
    alignSelf: 'center',
  },
  iconImage: {
    width: 20,
    height: 20,
    alignSelf: 'center',
  },
});

export default Button;
