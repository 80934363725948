import React from "react";
import { StyleSheet, Text, View } from "react-native";
import Colors from "../constants/Colors";
import Logo from './Logo';

type FamilyScreenHeaderProps = {
  userFirstName: string;
  introText: string;
  numKids: number;
};

const FamilyScreenHeader = ({ userFirstName, introText, numKids }: FamilyScreenHeaderProps) => {
  return (
    <View>
      <Logo numKids={numKids} />
      <View style={styles.introBlock}>
        <Text style={styles.navTitle}>Bonjour {userFirstName} !</Text>
        <Text style={styles.introText}>{introText}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  introBlock: {
    height: 'auto',
    paddingBottom: 16,
  },
  navTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 28,
    color: Colors.primaryDarker,
    textAlign: 'center',
    flexGrow: 1,
    marginHorizontal: 50,
  },
  introText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 24,
    color: Colors.text,
    paddingHorizontal: 16,
    marginTop: 10,
    textAlign: 'center',
  },
});

export default FamilyScreenHeader;
