import { CommonActions, NavigationContainerRef } from '@react-navigation/native';
import * as React from 'react';
import { RootStackParamList } from '../../types';

export const navigationRef = React.createRef<NavigationContainerRef<RootStackParamList>>();

export const navigate = (name: keyof RootStackParamList, params?: any) => {
  navigationRef.current?.navigate(name, params);
};

export const reset = (routes: Array<{ name: keyof RootStackParamList; params?: object }>, index: number = 0) => {
  navigationRef.current?.dispatch(
    CommonActions.reset({
      index,
      routes: routes.map((route) => ({ name: route.name, params: route.params || undefined })),
    })
  );
};
