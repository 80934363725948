import React from 'react';
import { Text, StyleSheet } from 'react-native';
import Colors from '../constants/Colors';

const CriteriaText = ({ isMet, children }: { isMet: boolean, children: React.ReactNode }) => (
  <Text style={[styles.criteriaText, isMet ? styles.criteriaMet : null]}>
    {children}
  </Text>
);

const styles = StyleSheet.create({
  criteriaText: {
    color: Colors.error,
  },
  criteriaMet: {
    color: Colors.success,
  },
});

export default CriteriaText;
