  import AsyncStorage from '@react-native-async-storage/async-storage';
  import { configureStore } from '@reduxjs/toolkit';
  import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
  import { combineReducers } from 'redux';
  import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
  import { appReducer } from '../store/slices/appSlice';
  import answersReducer from './slices/answersSlice';
  import { kidsReducer } from './slices/kidsSlice';
  import questionsReducer from './slices/questionsSlice';
  import storiesReducer from "./slices/storiesSlice"
  import discoverBlockReducer from './slices/discoverBlockSlice';

  // Combine reducers
  const rootReducer = combineReducers({
    app: appReducer,
    kids: kidsReducer,
    questions: questionsReducer,
    answers: answersReducer,
    readStories: storiesReducer,
    discoverBlock: discoverBlockReducer,
  });

  // Configuration for redux-persist
  const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    whitelist: ['app'],
  };

  // Create a persisted reducer
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    devTools: process.env.NODE_ENV !== 'production',
  });

  export const persistor = persistStore(store);

  // Infer the `RootState` and `AppDispatch` types from the store itself
  export type RootState = ReturnType<typeof store.getState>;
  export type AppDispatch = typeof store.dispatch;

  // Export the hooks with typed dispatch and selector
  export const useAppDispatch = () => useDispatch<AppDispatch>();
  export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
