import React, { useEffect, useRef } from 'react';
import { Animated, Dimensions, Image, ImageSourcePropType, StyleSheet, View } from 'react-native';

const screenHeight = Dimensions.get('window').height;

interface IconInfo {
  source: ImageSourcePropType;
  type: 'rotate' | 'comet' | 'rocket' | 'other';
}

interface AnimatedIcon extends IconInfo {
  x: Animated.Value;
  y: Animated.Value;
  rotation: Animated.Value;
}

const icons: IconInfo[] = [
  { source: require('../../assets/background_icons/comet.png'), type: 'comet' },
  { source: require('../../assets/background_icons/shuttle.png'), type: 'other' },
  { source: require('../../assets/background_icons/cat.png'), type: 'rotate' },
  { source: require('../../assets/background_icons/earth.png'), type: 'rotate' },
  { source: require('../../assets/background_icons/star.png'), type: 'comet' },
  { source: require('../../assets/background_icons/satellite.png'), type: 'rotate' },
  { source: require('../../assets/background_icons/astronaut.png'), type: 'rotate' },
  { source: require('../../assets/background_icons/comet_2.png'), type: 'comet' },
  { source: require('../../assets/background_icons/death_star.png'), type: 'rotate' },
  { source: require('../../assets/background_icons/alien.png'), type: 'rotate' },
  { source: require('../../assets/background_icons/bike.png'), type: 'other' },
  { source: require('../../assets/background_icons/planet.png'), type: 'rotate' },
  { source: require('../../assets/background_icons/rocket.png'), type: 'rocket' },
  { source: require('../../assets/background_icons/sun.png'), type: 'rotate' },
  { source: require('../../assets/background_icons/space_invader.png'), type: 'other' },
  { source: require('../../assets/background_icons/star_wars_2.png'), type: 'other' },
  { source: require('../../assets/background_icons/ufo_2.png'), type: 'other' },
];

interface AnimatedBackgroundProps {
  animationKey: number;
}

const AnimatedBackground: React.FC<AnimatedBackgroundProps> = ({ animationKey }) => {
  const iconRefs = useRef<AnimatedIcon[]>([]);

  useEffect(() => {
    iconRefs.current = icons.map(iconInfo => ({
      ...iconInfo,
      x: new Animated.Value(Math.random() > 0.5 ? -100 : 1000),
      y: new Animated.Value(Math.random() * screenHeight - (screenHeight / 2)),
      rotation: new Animated.Value(0),
    }));

    const animateIcon = (icon: AnimatedIcon, delay = 0) => {
      setTimeout(() => {
        let startX, endX;
        if (icon.type === 'comet' || icon.type === 'rocket') {
          const isRightToLeft = icon.type === 'comet';
          startX = isRightToLeft ? Math.random() * (1000 - 500) + 500 : -100; // Start from the right half for comets
          endX = isRightToLeft ? -100 : Math.random() * (500) + 500; // Move to the left half for comets
        } else {
          // For other types, you might want a different pattern or keep as is
          startX = Math.random() > 0.5 ? 1000 : -100;
          endX = startX === 1000 ? -100 : 1000;
        }

        // Adjust startY and endY for full screen height
        const startY = Math.random() * screenHeight;
        const endY = Math.random() * screenHeight;

        icon.x.setValue(startX);
        icon.y.setValue(startY);

        const animations = [
          Animated.timing(icon.x, {
            toValue: endX,
            duration: 10000,
            useNativeDriver: true,
          }),
          Animated.timing(icon.y, {
            toValue: endY,
            duration: 10000,
            useNativeDriver: true,
          }),
        ];

        if (icon.type === 'rotate') {
          animations.push(
            Animated.loop(
              Animated.timing(icon.rotation, {
                toValue: 1,
                duration: 20000,
                useNativeDriver: true,
              }),
              { iterations: -1 }
            )
          );
        }

        Animated.parallel(animations).start();
      }, delay);
    };

    iconRefs.current.forEach((icon, index) => {
      animateIcon(icon, index * 1500);
    });
  }, [animationKey]);

  return (
    <View style={styles.container}>
      {iconRefs.current.map((icon, index) => (
        <Animated.View
          key={index}
          style={[
            styles.icon,
            {
              transform: [
                { translateX: icon.x },
                { translateY: icon.y },
                {
                  rotate: icon.rotation.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['0deg', '360deg'],
                  }),
                },
              ],
            },
          ]}
        >
          <Image source={icon.source} style={{ width: 50, height: 50 }} />
        </Animated.View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: -1,
    opacity: 0.6,
  },
  icon: {
    position: 'absolute',
  },
});

export default AnimatedBackground;
