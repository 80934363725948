import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import LottieView from 'lottie-react-native';
import React, { useCallback, useLayoutEffect, useState } from 'react';
import { Dimensions, StyleSheet, Text, View } from 'react-native';
import Modal from 'react-native-modal';
import { useDispatch } from 'react-redux';
import Button from '../../components/Button';
import Colors from '../../constants/Colors';
import { resetAppState } from '../../store/slices/appSlice';
import { AppNavigationProps, RootStackParamList } from '../../types';
import { reset } from '../../utils/functions/navigationRef';
import { Linking } from 'react-native';

const SettingsScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "Settings">) => {
  const navigation = useNavigation<AppNavigationProps<"Settings">>();
  const dispatch = useDispatch();
  const [animationPosition, setAnimationPosition] = useState<'left' | 'right' | 'bottom'>('right');
  const [isInitialDeleteModalVisible, setInitialDeleteModalVisible] = useState(false);
  const [isFinalDeleteModalVisible, setFinalDeleteModalVisible] = useState(false);

  const handleSignOut = () => {
    dispatch(resetAppState());
    reset([{ name: 'Main' }]);
  };

  const handleDeleteProfileInitial = () => {
    setInitialDeleteModalVisible(true);
  };

  const handleDeleteProfileFinal = () => {
    setInitialDeleteModalVisible(false);
    setFinalDeleteModalVisible(true);
  };

  const handleFinalDeleteConfirmation = async () => {
    try {
      // Your delete logic here
      setFinalDeleteModalVisible(false);
      // Handle successful deletion, e.g., navigating back or resetting the app state
    } catch (error) {
      console.error('Error deleting user profile:', error);
      // Optionally handle the error, e.g., through an alert
    }
  };

  const openCGU = () => {
    Linking.openURL('https://www.fabulo.app/cgu')
      .catch(err => console.error("Failed to open URL:", err));
  }

  const openPrivacyPolicy = () => {
    Linking.openURL('https://www.fabulo.app/privacy')
      .catch(err => console.error("Failed to open URL:", err));
  }

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Paramètres',
    });
  }, [navigation]);

  useFocusEffect(
    useCallback(() => {
      const positions: ('left' | 'right' | 'bottom')[] = ['left', 'right', 'bottom'];
      const randomPosition = positions[Math.floor(Math.random() * positions.length)];
      setAnimationPosition(randomPosition);
    }, [])
  );

  const getAnimationStyle = () => {
    let baseStyle = {
      animationBoundingContainer: {
        ...styles.animationBoundingContainer,
      },
      lottieAnimation: {
        ...styles.lottieAnimation,
        transform: [...(styles.lottieAnimation.transform || [])],
      },
    };

    const screenHeight = Dimensions.get('window').height;
    const containerHeight = screenHeight * 0.33;
    const randomOffset = () => Math.random() * (containerHeight - styles.animationContainer.height);

    switch (animationPosition) {
      case 'left':
        return {
          ...baseStyle,
          animationContainer: {
            ...styles.animationContainer,
            left: -24,
            bottom: randomOffset(),
            right: undefined,
          },
          lottieAnimation: {
            ...baseStyle.lottieAnimation,
            transform: [{ scaleX: -1 }],
          },
        };
      case 'bottom':
        return {
          ...baseStyle,
          animationContainer: {
            ...styles.animationContainer,
            bottom: -24,
            left: randomOffset(),
            right: undefined,
          },
          lottieAnimation: {
            ...baseStyle.lottieAnimation,
            transform: [{ rotate: '90deg' }],
          },
        };
      case 'right':
      default:
        return {
          ...baseStyle,
          animationContainer: {
            ...styles.animationContainer,
            right: -24,
            bottom: randomOffset(),
            left: undefined,
          },
          lottieAnimation: {
            ...baseStyle.lottieAnimation,
            transform: [{ scaleX: 1 }],
          },
        };
    }
  };

  return (
    <View style={styles.container}>
      <View style={styles.divider} />
      <Button
        title="Gérer mes enfants"
        variant="select"
        onPress={() => navigation.navigate('KidsSettings')}
      />
      <Button
        title="Mes informations personnelles"
        variant="select"
        onPress={() => navigation.navigate('AccountSettings')}
      />
      <Button
        title="Mon mot de passe"
        variant="select"
        onPress={() => navigation.navigate('PasswordSettings')}
      />
      <Button
        title="Aide et contact"
        variant="select"
        onPress={() => navigation.navigate('HelpAndContact')}
      />
      <Button
        title="Conditions générales d'utilisation"
        variant="link"
        onPress={openCGU}
        style={{ marginVertical: 4 }}
      />
      <Button
        title="Politique de confidentialité"
        variant="link"
        onPress={openPrivacyPolicy}
        style={{ marginVertical: 4 }}
      />
      <Button
        title="Se déconnecter"
        variant="link"
        onPress={handleSignOut}
        style={{ marginVertical: 4 }}
      />
      <Button
        title="Supprimer votre compte"
        variant="danger"
        onPress={handleDeleteProfileInitial}
        style={{ marginVertical: 4 }}
      />
      <Modal isVisible={isInitialDeleteModalVisible}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Confirmer la suppression</Text>
          <Text style={styles.modalText}>
            Êtes-vous sûr de vouloir supprimer votre profil ainsi que toutes vos données ?
          </Text>
          <Button title="Oui" variant="tertiary" onPress={handleDeleteProfileFinal} />
          <Button title="Annuler" variant="secondary" onPress={() => setInitialDeleteModalVisible(false)} />
        </View>
      </Modal>
      <Modal isVisible={isFinalDeleteModalVisible}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Êtes-vous absolument sûr ?</Text>
          <Text style={styles.modalText}>
            Cette action supprimera définitivement votre compte et toutes les données associées. Cette action est irréversible.
          </Text>
          <Button title="Supprimer définitivement" variant="tertiary" onPress={handleFinalDeleteConfirmation} />
          <Button title="Annuler" variant="secondary" onPress={() => setFinalDeleteModalVisible(false)} />
        </View>
      </Modal>
      <View style={styles.animationBoundingContainer}>
        <View style={getAnimationStyle().animationContainer}>
          <LottieView
            source={require('../../../assets/animations/woodpecker.json')}
            autoPlay
            loop
            style={getAnimationStyle().lottieAnimation}
          />
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  divider: {
    height: 24,
  },
  animationBoundingContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    height: '33%',
    overflow: 'hidden',
  },
  animationContainer: {
    position: 'absolute',
    right: -24,
    width: 75,
    height: 75,
  },
  lottieAnimation: {
    width: '100%',
    height: '100%',
    opacity: 0.8,
  },
  modalContent: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default SettingsScreen;
