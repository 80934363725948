import React, { useEffect, useRef } from 'react';
import { Animated, Image, StyleSheet, ViewStyle } from 'react-native';

interface RotatingStarProps {
  color: 'primary' | 'secondary' | 'tertiary' | 'black';
  size: 'small' | 'large';
  style?: ViewStyle;
  rotationDirection: 'clockwise' | 'counterclockwise';
}

const RotatingStar: React.FC<RotatingStarProps> = ({ color, size, style, rotationDirection }) => {
  const rotateValue = useRef(new Animated.Value(0)).current;

  useEffect(() => {

    const duration = color === 'secondary' ? 10000 : 5000;

    const toValue = rotationDirection === 'clockwise' ? 1 : -1;

    Animated.loop(
      Animated.timing(rotateValue, {
        toValue: toValue,
        duration: duration,
        useNativeDriver: true,
        easing: (t) => t,
      })
    ).start();

    return () => rotateValue.setValue(0);
  }, [rotateValue, color, rotationDirection]);

  const rotate = rotateValue.interpolate({
    inputRange: [-1, 1],
    outputRange: ['-360deg', '360deg'],
  });

  const starSize = size === 'small' ? { width: 12.5, height: 12.5 } : { width: 25, height: 25 };

  const starImage = () => {
    switch (color) {
      case 'primary':
        return require('../../assets/images/star_blue.png');
      case 'secondary':
        return require('../../assets/images/star_yellow.png');
      case 'tertiary':
        return require('../../assets/images/star_red.png');
      case 'black':
        return require('../../assets/images/star_black.png');
      default:
        return require('../../assets/images/star_blue.png');
    }
  };

  return (
    <Animated.View style={[styles.starContainer, { transform: [{ rotate }] }, style]}>
      <Image source={starImage()} style={[styles.star, starSize]} />
    </Animated.View>
  );
};

const styles = StyleSheet.create({
  starContainer: {
    alignItems: 'center',
  },
  star: {},
});

export default RotatingStar;
