import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as Haptics from 'expo-haptics';
import LottieView from 'lottie-react-native';
import React, { useLayoutEffect, useState } from 'react';
import { FlatList, Image, Linking, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import Modal from 'react-native-modal';
import Button from '../../components/Button';
import Colors from '../../constants/Colors';
import { CreditPacks } from '../../constants/Enums';
import { useAppSelector } from '../../store/store';
import { RootStackParamList } from '../../types';

const CreditsScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "StoryRead">) => {
  const navigation = useNavigation();
  const credits = useAppSelector((state) => state.app.credits);
  const [isModalVisible, setIsModalVisible] = useState(false);

  useLayoutEffect(() => {
    navigation.setOptions({
      title: `Vos crédits`,
      headerLeft: () => null,
      headerRight: () => (
        <Pressable onPress={() => navigation.goBack()} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color={Colors.text} />
        </Pressable>
      ),
    });
  }, [navigation]);

  const handleShowModal = () => {
    handleHapticFeedback();
    setIsModalVisible(true);
  };

  const handleSendEmail = () => {
    const subject = encodeURIComponent('Mes retours sur Fabulo');
    const mailtoUrl = `mailto:contact@fabulo.app?subject=${subject}`;
    Linking.openURL(mailtoUrl);
  };

  const handleHapticFeedback = () => {
    if (Platform.OS !== 'web') {
      Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
    }
  };

  const ListHeaderComponent = () => (
    <View style={styles.introContainer}>
      {credits === 0 ? (
        <Text style={styles.intro}>Vous n'avez plus de crédit 😢</Text>
      ) : (
        <View style={styles.creditsContainer}>
          <Text style={styles.creditsText}>
            {credits != null ? `${credits}` : '?'}
          </Text>
          <View style={styles.animationContainer}>
            <LottieView
              source={require('../../../assets/animations/coin.json')}
              autoPlay
              loop
              style={platformStyles.lottieAnimation}
            />
          </View>
        </View>
      )}
      <Text style={styles.rechargeExplanation}>Un crédit donne accès à une histoire Fabulo avec son texte, son enregistrement audio et son image.</Text>
      <Text style={styles.rechargeTitle}>Recharger</Text>
    </View>
  );

  return (
    <View style={{ flex: 1 }}>
      <FlatList
        data={CreditPacks}
        renderItem={({ item }) => (
          <Pressable style={styles.creditCard} onPress={handleShowModal}>
            <View style={styles.creditsInfoContainer}>
              <Text style={styles.cardCredits}>{item.credits}</Text>
              <Image source={require('../../../assets/images/coin.png')} style={platformStyles.cardCoinImage} />
            </View>
            <Text style={styles.cardPrice}>{item.price}</Text>
            <Text style={styles.cardPricePerCredit}>{`${item.pricePerCredit} par crédit`}</Text>
          </Pressable>
        )}
        keyExtractor={(item, index) => `credit-pack-${index}`}
        numColumns={2}
        ListHeaderComponent={<ListHeaderComponent />}
        contentContainerStyle={styles.creditCardsContainer}
        columnWrapperStyle={styles.columnWrapper}
      />
      <Modal isVisible={isModalVisible} onBackdropPress={() => setIsModalVisible(false)}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Bientôt disponible ⏰</Text>
          <Text style={styles.modalText}>En attendant, envoyez vos retours à info@fabulo.app et nous vous donnerons du crédit supplémentaire !</Text>
          <Button title="J'écris un mail !" variant="primary" onPress={handleSendEmail} />
          <Button title="Fermer" variant="secondary" onPress={() => setIsModalVisible(false)} />
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  creditCardsContainer: {
    paddingHorizontal: 16,
  },
  columnWrapper: {
    justifyContent: 'space-around',
  },
  introContainer: {
    marginVertical: 16,
    width: '100%',
    alignItems: 'center',
  },
  creditsContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 16,
  },
  animationContainer: {
    height: 56,
    width: 56,
    alignSelf: 'center',
    marginLeft: 4,
  },
  creditsText: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 56,
    color: Colors.secondary,
    paddingTop: 8,
    textShadowColor: Colors.text,
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 0,
  },
  rechargeTitle: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 20,
    color: Colors.text,
    marginTop: 24,
    marginHorizontal: 8,
    alignSelf: 'flex-start',
  },
  rechargeExplanation: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    color: Colors.text,
    marginHorizontal: 8,
    alignSelf: 'flex-start',
  },
  creditCard: {
    backgroundColor: Colors.white,
    borderWidth: 2,
    borderRadius: 25,
    paddingVertical: 16,
    margin: 8,
    width: '45%',
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 1,
    shadowRadius: 0,
    elevation: 3,
  },
  intro: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    marginVertical: 32,
    color: Colors.text,
    textAlign: 'center',
  },
  creditsInfoContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 4,
  },
  cardCredits: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    color: Colors.secondary,
    textShadowColor: Colors.text,
    textShadowOffset: { width: 1, height: 1 },
    textShadowRadius: 0,
  },
  cardPrice: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 16,
    color: Colors.text,
    paddingBottom: 4,
  },
  cardPricePerCredit: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 12,
    color: Colors.grey2,
  },
  modalContent: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
});

const platformStyles = StyleSheet.create({
  lottieAnimation: {
    height: '100%',
    width: '100%',
    marginTop: Platform.OS === 'android' ? 3 : 0,
  },
  cardCoinImage: {
    width: 24,
    height: 24,
    marginTop: Platform.OS === 'android' ? 4 : 2,
    marginLeft: 4,
  },
});

export default CreditsScreen;
