import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import LottieView from 'lottie-react-native';
import React, { useLayoutEffect, useState } from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { postKidStory } from '../../api/story/story.api';
import Button from '../../components/Button';
import Colors from '../../constants/Colors';
import { PostStoryType, StoryCreationNavigationProps, StoryCreationStackParamList } from '../../types';
import BookReveal from '../../../assets/animations/book-reveal.json';
import BookOpen from '../../../assets/animations/book-open.json';
import BookFlutter from '../../../assets/animations/book-flutter.json';

const StoryOnboardingScreen = ({ route }: NativeStackScreenProps<StoryCreationStackParamList, "StoryOnboarding">) => {
  const navigation = useNavigation<StoryCreationNavigationProps<"StoryOnboarding">>();
  const { kidId, kidName } = route.params;

  const [animationStage, setAnimationStage] = useState(1);

  const determinePreposition = (name: string) => {
    const vowels = ['a', 'e', 'i', 'o', 'u', 'h'];
    const firstLetter = name[0].toLowerCase();
    return vowels.includes(firstLetter) ? "d'" : "de ";
  };

  const handleCreateFirstStory = async () => {
    try {
      const storyPayload: PostStoryType = {
        need: "⚡️ Histoire instantanée",
        theme: "None",
        kid_id: kidId,
        instant_generation: true,
      };
      const response = await postKidStory(storyPayload, kidId);
      const storyId = response.data.story.id;
      navigation.navigate('StoryCreationFlow' as any, {
        screen: 'InstantStoryGeneration',
        params: { kidId, kidName, storyId },
      });
    } catch (error) {
      console.error('Error creating instant story:', error);

      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'La création de l\'histoire instantanée a échoué. Veuillez réessayer.',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
      });
    }
  };

  const handleBackPress = () => {
    navigation.navigate('Main' as any, { screen: 'MyFamily' })
  };

  useLayoutEffect(() => {

    // const truncate = (str: string, maxLength: number) => {
    //   if (str.length > maxLength) {
    //     return str.substring(0, maxLength - 3) + '...';
    //   }
    //   return str;
    // };

    // const formattedKidName = truncate(`Que raconter à ${kidName} !`, 21);

    navigation.setOptions({
      title: 'Sa première histoire',
      headerLeft: () => (
        <Pressable onPress={handleBackPress} style={styles.backButton}>
          <MaterialCommunityIcons name="arrow-left" size={24} color="black" />
        </Pressable>
      ),
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('Main' as any, { screen: 'MyFamily' })} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation, kidName]);

  return (
    <View style={styles.container}>
      <View style={styles.animationContainer}>
        {animationStage === 1 && (
          <LottieView
            source={BookReveal}
            autoPlay
            loop={false}
            onAnimationFinish={() => setAnimationStage(2)}
            style={styles.lottieAnimation}
          />
        )}
        {animationStage === 2 && (
          <LottieView
            source={BookOpen}
            autoPlay
            loop={false}
            onAnimationFinish={() => setAnimationStage(3)}
            style={styles.lottieAnimation}
          />
        )}
        {animationStage === 3 && (
          <LottieView
            source={BookFlutter}
            autoPlay
            loop
            style={styles.lottieAnimation}
          />
        )}
      </View>
      <Text style={styles.title}>Merci pour ces réponses !</Text>
      <Text style={styles.subtitle}>Vous les retrouverez sur le profil {determinePreposition(kidName)}{kidName}, vous pourrez les modifier et en ajouter à tout moment !</Text>
      <Button
        variant="primary"
        title="Créer sa première histoire"
        onPress={handleCreateFirstStory} />
    </View >
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 32,
    backgroundColor: Colors.primary05,
  },
  animationContainer: {
    height: 150,
    width: 150,
    alignSelf: 'center',
    paddingBottom: 8,
  },
  lottieAnimation: {
    height: '100%',
    width: '100%',
  },
  backButton: {
    marginLeft: 15,
  },
  logo: {
    width: 164,
    height: 144,
    resizeMode: 'contain',
  },
  title: {
    fontFamily: 'GochiHandRegular',
    fontSize: 28,
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 16,
  },
  subtitle: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 24,
    textAlign: 'center',
    marginTop: 8,
    marginBottom: 40,
  },
});

export default StoryOnboardingScreen;
