import { useNavigation } from '@react-navigation/native';
import LottieView from 'lottie-react-native';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import Modal from 'react-native-modal';
import { getFamilyKids } from '../../api/family/family.api';
import { deleteKid, updateKid } from '../../api/kid/kid.api';
import Button from '../../components/Button';
import EmojiSelector from '../../components/EmojiSelector';
import InputField from '../../components/InputField';
import ItemSelector from '../../components/ItemSelector';
import Loader from '../../components/Loader';
import Colors from '../../constants/Colors';
import { Emoji, ageRangeArray } from '../../constants/Enums';
import { PostKidType } from '../../types/kid';
import { formatAge } from '../../utils/functions/formatAge';

const KidsSettingsScreen = () => {
  const navigation = useNavigation();
  const [kidProfiles, setKidProfiles] = useState<PostKidType[]>([]);
  const [selectedKid, setSelectedKid] = useState<PostKidType | null>(null);
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);
  const [selectedAge, setSelectedAge] = useState<number>(3);
  const [selectedKidId, setSelectedKidId] = useState<number | null>(null);
  const [selectedKidName, setSelectedKidName] = useState<string | null>(null);
  const [isEmojiEditModalVisible, setEmojiEditModalVisible] = useState(false);

  useEffect(() => {
    fetchKids();
  }, []);

  const fetchKids = async () => {
    setLoading(true);
    try {
      const response = await getFamilyKids();
      setKidProfiles(response.data.kids);
    } catch (error) {
      console.error('Error fetching kids:', error);
    }
    setLoading(false);
  };

  const handleEditKid = (kid: PostKidType) => {
    setSelectedKid(kid);
    setSelectedEmoji(kid.emoji);
    setEditModalVisible(true);
  };

  const handleEditEmoji = (kid: PostKidType) => {
    setSelectedKid(kid);
    setSelectedEmoji(kid.emoji);
    setEmojiEditModalVisible(true);
  };

  const handleEmojiSelect = (emoji: Emoji) => {
    setSelectedEmoji(emoji);
    setSelectedKid((prevKid) => prevKid ? { ...prevKid, emoji: emoji } : null);
  };

  const handleDeleteKid = async (kidId: number) => {
    setSelectedKidId(kidId);
    const selectedKid = kidProfiles.find((kid) => kid.id === kidId);
    setSelectedKidName(selectedKid?.first_name || '');
    setDeleteModalVisible(true);
  };

  const confirmDeletion = async () => {
    if (selectedKidId) {
      try {
        await deleteKid(selectedKidId);
        fetchKids();
      } catch (error) {
        console.error('Error deleting kid profile:', error);
      }
      setDeleteModalVisible(false);
    }
  };

  const handleSaveChanges = async () => {
    if (selectedKid) {
      try {
        await updateKid(selectedKid.id, {
          first_name: selectedKid.first_name,
          age: selectedKid.age,
          emoji: selectedKid.emoji,
        });
        fetchKids();
        setEditModalVisible(false);

        setEmojiEditModalVisible(false);
      } catch (error) {
        console.error('Error updating kid profile:', error);
      }
    }
  };

  const determinePreposition = (name: string | string[]) => {
    const vowels = ['a', 'e', 'i', 'o', 'u', 'h'];
    const firstLetter = name[0].toLowerCase();
    return vowels.includes(firstLetter) ? "d'" : "de ";
  };

  useLayoutEffect(() => {
    const kidsCount = kidProfiles.length;
    let title = 'Gérer mes enfants';
    if (kidsCount === 0) {
      title = 'Pas encore d\'enfant';
    } else if (kidsCount === 1) {
      title = 'Mon enfant';
    } else {
      title = `Mes ${kidsCount} enfants`;
    }

    navigation.setOptions({
      title: title,
    });
  }, [navigation, kidProfiles.length]);


  if (isLoading) {
    return (
      <View style={styles.centered}>
        <Loader />
      </View>
    );
  }

  const truncate = (str: string, maxLength: number) => {
    return str.length > maxLength ? str.substring(0, maxLength - 3) + '...' : str;
  };

  return (
    <View style={styles.container}>
      <View style={styles.divider} />
      <FlatList
        data={kidProfiles}
        keyExtractor={(item) => item.id.toString()}
        renderItem={({ item }) => (
          <View style={styles.kidProfileContainer}>
            <Text style={styles.kidName}>
              {item.emoji}  {truncate(item.first_name, 10)}, {formatAge(item.age)}
            </Text>
            <View style={styles.iconsContainer}>
              <Button variant="circular-small" onPress={() => handleEditKid(item)} icon="square-edit-outline" iconColor={Colors.text} />
              <View style={{ width: 16 }} />
              <Button variant="circular-small" onPress={() => handleEditEmoji(item)} icon="emoticon-happy-outline" iconColor={Colors.text} />
              <View style={{ width: 16 }} />
              <Button variant="circular-small" onPress={() => handleDeleteKid(item.id)} icon="trash-can-outline" iconColor={Colors.tertiary} />
            </View>
          </View>
        )}
        ListFooterComponent={() => (
          <View style={styles.animationContainer}>
            <LottieView
              source={require('../../../assets/animations/jumping-rope.json')}
              autoPlay
              loop
              style={styles.lottieAnimation}
            />
          </View>
        )}
      />
      {
        selectedKid && (
          <Modal isVisible={isEditModalVisible}>
            <View style={styles.modalContent}>
              <InputField
                value={selectedKid?.first_name}
                onChangeText={(text) => setSelectedKid((prevKid) => prevKid ? { ...prevKid, first_name: text } : null)}
                inputType="name"
                placeholder="Prénom de l'enfant"
              />
              <ItemSelector
                selectedItem={selectedKid?.age || selectedAge}
                onItemSelect={(age) => setSelectedKid((prevKid) => prevKid ? { ...prevKid, age } : null)}
                items={ageRangeArray.map(age => ({
                  label: formatAge(age),
                  value: age,
                }))}
              />
              <Button title="Valider" variant="primary" onPress={handleSaveChanges} />
              <Button title="Annuler" variant="secondary" onPress={() => setEditModalVisible(false)} />
            </View>
          </Modal>
        )
      }
      {
        selectedKid && (
          <Modal isVisible={isEmojiEditModalVisible}>
            <View style={styles.modalContent}>
              <EmojiSelector
                selectedEmoji={selectedEmoji ?? Emoji.Unicorn}
                onEmojiSelect={handleEmojiSelect}
              />
              <Button title="Valider" variant="primary" onPress={handleSaveChanges} />
              <Button title="Annuler" variant="secondary" onPress={() => setEmojiEditModalVisible(false)} />
            </View>
          </Modal>
        )
      }
      {
        selectedKidName && (
          <Modal isVisible={isDeleteModalVisible}>
            <View style={styles.modalContent}>
              <Text style={styles.modalTitle}>Confirmer la suppression</Text>
              <Text style={styles.modalText}>
                Êtes-vous sûr de vouloir supprimer le profil {selectedKidName ? determinePreposition(selectedKidName) : ''}{selectedKidName} ainsi que toutes ses réponses et histoires ?
              </Text>
              <Button title="Supprimer" variant="tertiary" onPress={confirmDeletion} />
              <Button title="Annuler" variant="secondary" onPress={() => setDeleteModalVisible(false)} />
            </View>
          </Modal>
        )
      }
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  divider: {
    height: 24,
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  headerRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
  },
  headerText: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 0,
    textAlign: 'left',
  },
  kidProfileContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: 'lightgray',
  },
  kidName: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 18,
  },
  iconsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  modalContent: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
  animationContainer: {
    width: '100%',
    alignItems: 'center',
    overflow: 'hidden',
    paddingTop: 64,
  },
  lottieAnimation: {
    width: '100%',
    height: 100,
  },
});

export default KidsSettingsScreen;
