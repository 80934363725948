import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback } from 'react';
import { Pressable, View } from 'react-native';
import Button from '../../components/Button';
import CustomHeaderTitle from '../../components/CustomHeader';
import { useProgressBar } from '../../components/ProgressBarContext';
import { ProgramType } from '../../constants/Enums';
import { globalStyles } from '../../constants/Styles';
import { StoryCreationNavigationProps, StoryCreationStackParamList } from '../../types';

const NeedSelectionScreen = ({ route }: NativeStackScreenProps<StoryCreationStackParamList, "NeedSelection">) => {
  const navigation = useNavigation<StoryCreationNavigationProps<"NeedSelection">>();
  const { kidId, kidName } = route.params;
  const { setCurrentStep } = useProgressBar();

  useFocusEffect(
    React.useCallback(() => {
      setCurrentStep(2);
    }, [])
  );

  const fullTitle = `Que veut faire ${kidName} ?`;

  const truncate = (str: string, maxLength: number) => {
    if (str.length <= maxLength) return str;
    return `${str.substring(0, maxLength - 3)}...`;
  };

  const truncatedTitle = truncate(fullTitle, 25);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: () => <CustomHeaderTitle title={truncatedTitle} style={globalStyles.headerTitleStyle} />,
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('Main' as any, {
          screen: 'KidOverview',
          params: {
            kidId,
            kidName,
          }
        })
        } style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation, kidName]);

  const navigateToScreen = useCallback((need: ProgramType) => {
    switch (need) {
      case ProgramType.TakeCare:
      case ProgramType.FallAsleep:
        navigation.navigate('ThemeSelection', { kidId, kidName, need });
        break;
      case ProgramType.ManageEmotions:
        navigation.navigate('EmotionSelection', { kidId, kidName, need });
        break;
      default:
        console.log(`Unhandled need: ${need}`);
    }
  }, [kidId, navigation]);

  return (
    <View style={globalStyles.container}>
      {Object.values(ProgramType).map((need) => (
        <Button
          variant="select"
          key={need}
          title={need}
          onPress={() => navigateToScreen(need)}
        />
      ))}
    </View>
  );
}

export default NeedSelectionScreen;
