if (typeof window !== 'undefined') {
  window.process = window.process || { env: { NODE_ENV: 'development' } };
}

TrackPlayer.registerPlaybackService(() => playbackService);
console.log("TrackPlayer playback service registration attempted");

import { NavigationContainer } from '@react-navigation/native';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import * as SplashScreen from 'expo-splash-screen';
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React, { useEffect } from 'react';
import { EventRegister } from 'react-native-event-listeners';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Toast from 'react-native-toast-message';
import TrackPlayer, { Capability } from 'react-native-track-player';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import CustomToast from './src/components/CustomToast';
import AppNavigator from './src/navigators/AppNavigator';
import { persistor, store } from './src/store/store';
import { navigationRef } from './src/utils/functions/navigationRef';
import playbackService from './src/utils/service';

const firebaseConfig = {
  apiKey: "AIzaSyB4tPjiUvmOBqd6rQiaBOMvZjkwU-JbAmI",
  authDomain: "fabulo-2a82b.firebaseapp.com",
  projectId: "fabulo-2a82b",
  storageBucket: "fabulo-2a82b.appspot.com",
  messagingSenderId: "109419398296",
  appId: "1:109419398296:web:7211c5421a18b03aad7ced",
  measurementId: "G-11XSCWW8P8"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

global.addEventListener = EventRegister.addEventListener;
global.removeEventListener = EventRegister.removeEventListener;

// Call preventAutoHideAsync immediately to keep the splash screen visible
SplashScreen.preventAutoHideAsync();

const imageAssets = [
  require('./assets/images/logo_no_clouds.png'),
  require('./assets/images/logo_blue_text.png'),
  require('./assets/images/logo_blue_clouds.png'),
  require('./assets/images/star_blue.png'),
  require('./assets/images/star_yellow.png'),
  require('./assets/images/star_red.png'),
  require('./assets/images/star_black.png'),
  require('./assets/images/star_blue.png'),
  require('./assets/images/coin_border.png'),
  require('./assets/images/coin.png'),
  require('./assets/background_icons/alien.png'),
  require('./assets/background_icons/astronaut.png'),
  require('./assets/background_icons/bike.png'),
  require('./assets/background_icons/cat.png'),
  require('./assets/background_icons/comet_2.png'),
  require('./assets/background_icons/comet.png'),
  require('./assets/background_icons/death_star.png'),
  require('./assets/background_icons/earth.png'),
  require('./assets/background_icons/planet.png'),
  require('./assets/background_icons/rocket.png'),
  require('./assets/background_icons/satellite.png'),
  require('./assets/background_icons/shuttle.png'),
  require('./assets/background_icons/space_invader.png'),
  require('./assets/background_icons/star_wars_2.png'),
  require('./assets/background_icons/star_wars.png'),
  require('./assets/background_icons/star.png'),
  require('./assets/background_icons/sun.png'),
  require('./assets/background_icons/ufo_2.png'),
  require('./assets/background_icons/ufo.png'),
  require('./assets/images/story_placeholder_landscape.png'),
  require('./assets/images/story_placeholder_square.png'),
];

const fetchFonts = () => {
  return Font.loadAsync({
    'SourceSerif4-Regular': require('./assets/fonts/SourceSerif4-VariableFont.ttf'),
    'SourceSerif4-Italic': require('./assets/fonts/SourceSerif4-Italic-VariableFont.ttf'),
    'GochiHandRegular': require('./assets/fonts/GochiHand-Regular.ttf'),
    'Gilroy-Regular': require('./assets/fonts/Gilroy-Regular.ttf'),
    'Gilroy-Bold': require('./assets/fonts/Gilroy-Bold.ttf'),
    'Gilroy-Italic': require('./assets/fonts/Gilroy-Italic.ttf'),
  });
};

// Initialize TrackPlayer
async function setupTrackPlayer() {
  await TrackPlayer.setupPlayer();
  await TrackPlayer.updateOptions({
    capabilities: [
      Capability.Play,
      Capability.Pause,
      Capability.SeekTo,
      Capability.JumpForward,
      Capability.JumpBackward,
    ],
    compactCapabilities: [
      Capability.Play,
      Capability.Pause
    ],
  });
}

const App: React.FC = () => {
  useEffect(() => {
    async function prepare() {
      try {
        // Load fonts
        await fetchFonts();

        // Load images
        const imagePromises = imageAssets.map(image => Asset.fromModule(image).downloadAsync());
        await Promise.all(imagePromises);

        // Setup TrackPlayer
        setupTrackPlayer();

        // Inform the splash screen to hide
        await SplashScreen.hideAsync();
      } catch (e) {
        console.warn("Failed to load resources:", e);
      }
    }

    prepare();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SafeAreaProvider>
          <NavigationContainer ref={navigationRef}>
            <AppNavigator />
            <Toast config={CustomToast} />
          </NavigationContainer>
        </SafeAreaProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
