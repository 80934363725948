import privateApi from '../../utils/privateApi';
import { KidStoryType } from '../../types/story';
import { AnswerType } from '../../types/answer';
import { KidType, PostKidType, UpdateKidType } from '../../types/kid';

export const getKid = async (kidId: number) => {
  return privateApi.get<KidType>(`/api/v1/kids/${kidId}`);
};

export const updateKid = async (kidId: number, payload: UpdateKidType) => {
  return privateApi.put<KidType>(`/api/v1/kids/${kidId}`, payload);
};

export const deleteKid = async (kidId: number) => {
  return privateApi.delete(`/api/v1/kids/${kidId}`);
};

export const getKidLastFiveStories = async (kidId: number) => {
  return privateApi.get<KidStoryType>(`/api/v1/kids/${kidId}/stories/last`);
};

export const getKidStories = async (kidId: number) => {
  return privateApi.get<KidStoryType>(`/api/v1/kids/${kidId}/stories`);
};

export const getKidAnswers = async (kidId: number) => {
  return privateApi.get<AnswerType[]>(`/api/v1/kids/${kidId}/answers`);
};
