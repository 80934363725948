import { Audio } from 'expo-av';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import Toast from 'react-native-toast-message';
import Colors from '../constants/Colors';
import Button from './Button';
import { useNavigation } from '@react-navigation/native';
import Modal from 'react-native-modal';

const digitsToWords = ['un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf'];

type ParentalGateProps = {
  onCorrectAnswer: () => void;
  onClose: () => void;
};

const ParentalGate: React.FC<ParentalGateProps> = ({ onCorrectAnswer, onClose }) => {
  const navigation = useNavigation();
  const [digits, setDigits] = useState<number[]>([]);
  const [userInput, setUserInput] = useState<number[]>([]);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    generateRandomDigits();
    playAudio();
  }, []);

  const playAudio = async () => {
    const { sound } = await Audio.Sound.createAsync(
      { uri: "https://res.cloudinary.com/dzcevvxsn/video/upload/v1711557050/musics/adult_needed_ojqhyf.mp3" },
      { shouldPlay: true }
    );
    await sound.playAsync();
  };

  const generateRandomDigits = () => {
    const newDigits: number[] = [];
    while (newDigits.length < 3) {
      const rand = Math.floor(Math.random() * 9) + 1;
      if (!newDigits.includes(rand)) {
        newDigits.push(rand);
      }
    }
    setDigits(newDigits);
  };

  const handleDigitPress = (digit: number) => {
    const updatedInput = [...userInput, digit];
    setUserInput(updatedInput);

    if (updatedInput.length === 3) {
      const isCorrect = digits.every((digit, index) => digit === updatedInput[index]);
      if (isCorrect) {
        onCorrectAnswer();
      } else {
        setShowErrorModal(true);
        setUserInput([]);
      }
    }
  };

  const formattedSubtitle = digits.map((digit, index, arr) => {
    const word = digitsToWords[digit - 1];
    const isLast = index === arr.length - 1;
    return (
      <React.Fragment key={index}>
        {index > 0 && <Text>{index === arr.length - 1 ? ' puis ' : ', '}</Text>}
        <Text style={styles.boldText}>{word}</Text>
      </React.Fragment>
    );
  });

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Demande à un adulte</Text>
      <View style={styles.subtitleWrapper}>
        <Text style={styles.subtitle}>
          Pour continuer,
        </Text>
        <Text style={styles.subtitle}>tapez sur {formattedSubtitle}.</Text>
      </View>
      <View style={styles.grid}>
        {Array.from({ length: 9 }, (_, i) => i + 1).map((number) => (
          <Button
            key={number}
            variant="circular"
            title={number.toString()}
            onPress={() => handleDigitPress(number)}
            style={styles.buttonStyle}
            textOverrideStyle={{ fontSize: 24 }}
          />
        ))}
      </View>
      <Button
        title="Fermer"
        variant="primary"
        onPress={onClose}
        style={{ marginTop: 16 }}
      />
      <Modal
        isVisible={showErrorModal}
        onBackdropPress={() => setShowErrorModal(false)}
        backdropTransitionOutTiming={0}
      >
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Raté 🤦‍♂️</Text>
          <Text style={styles.modalText}>Réponse incorrecte ! Essayez à nouveau.</Text>
          <Button title="OK" variant="primary" onPress={() => setShowErrorModal(false)} />
        </View>
      </Modal>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 20,
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  title: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
  },
  subtitleWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 16,
  },
  subtitle: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 18,
    lineHeight: 20,
    textAlign: 'center',
  },
  boldText: {
    fontFamily: 'Gilroy-Bold',
  },
  grid: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  buttonStyle: {
    margin: 8,
    width: '25%',
    aspectRatio: 1,
    borderRadius: 50,
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalContent: {
    backgroundColor: 'white',
    padding: 22,
    borderRadius: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 18,
    lineHeight: 20,
    marginBottom: 20,
    textAlign: 'center',
  },
});

export default ParentalGate;
