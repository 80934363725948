import { StyleSheet } from 'react-native';
import Colors from './Colors';

export const globalStyles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center', // This will distribute kidren evenly
    alignItems: 'center', // This will align kidren horizontally
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  tabBarLabel: {
    // fontFamily: 'Gilroy-Regular',
    fontSize: 12,
  },
  headerTitleStyle: {
    fontFamily: 'GochiHandRegular',
    color: Colors.text,
    fontSize: 24,
  },
});
