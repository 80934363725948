import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { AVPlaybackStatus } from 'expo-av';
import { useKeepAwake } from 'expo-keep-awake';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { getMusicDetails } from '../../api/music/music.api';
import AudioPlayer from '../../components/AudioPlayer';
import Loader from '../../components/Loader';
import Colors from '../../constants/Colors';
import { setStoryRead } from '../../store/slices/storiesSlice';
import { useAppDispatch } from '../../store/store';
import { RootStackParamList } from '../../types';
import handleApiError from '../../utils/functions/apiErrorHandling';
import useLoadData from '../../utils/hooks/useLoadData';

interface AudioPlayerMethods {
  stopAllAudio: () => void;
}

const StoryReadScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "StoryRead">) => {
  const navigation = useNavigation();
  const { story, audioUrl, backgroundColor: initialBackgroundColor } = route.params;
  const [backgroundColor, setBackgroundColor] = useState(initialBackgroundColor || Colors.grey);
  const { data: music, loadData: loadMusic } = useLoadData(() => getMusicDetails(story.music_id));
  const audioPlayerRef = useRef<AudioPlayerMethods>(null);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const scrollViewRef = useRef<ScrollView>(null);
  const [scrollViewHeight, setScrollViewHeight] = useState(0);
  const [totalContentHeight, setTotalContentHeight] = useState(0);
  const [playbackProgress, setPlaybackProgress] = useState(0);

  useKeepAwake();

  useFocusEffect(
    React.useCallback(() => {
      return () => {
        audioPlayerRef.current?.stopAllAudio();
      };
    }, [])
  );

  useEffect(() => {
    const fetchMusic = async () => {
      if (story.music_id && !music) {
        try {
          await loadMusic();
          setIsLoading(false);
        } catch (err) {
          console.error("Error fetching music:", err);
          handleApiError(err);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    fetchMusic();
  }, [story.music_id, loadMusic, music]);

  useEffect(() => {
    console.log("Story URI:", audioUrl);
    console.log("Music URI:", music ? music.file_path : '');
  }, [audioUrl, music]);

  if (!story) {
    return <Text>L'histoire n'a pu être chargée !</Text>;
  }

  const handlePlaybackStatusUpdate = (status: AVPlaybackStatus) => {
    if (status.isLoaded) {
      const progress = status.positionMillis / status.durationMillis;
      setPlaybackProgress(progress);
      const scrollToPosition = progress * (totalContentHeight - scrollViewHeight);
      scrollViewRef.current?.scrollTo({ y: scrollToPosition, animated: true });
    }
  };

  useLayoutEffect(() => {
    const truncatedTitle = story?.title ? story.title.slice(0, 22) + (story.title.length > 22 ? '...' : '') : '';
    navigation.setOptions({
      title: truncatedTitle,
      headerStyle: {
        backgroundColor: backgroundColor,
      },
      headerTitleStyle: {
        color: 'white',
        fontFamily: 'GochiHandRegular',
        fontSize: 24,
      },
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerLeft: () => null,
      headerRight: () => (
        <Pressable onPress={() => {
          dispatch(setStoryRead(story.id));
          navigation.goBack();
        }} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="white" />
        </Pressable>
      ),
    });
  }, [navigation, story.id, dispatch, backgroundColor]);

  return (
    <View style={[styles.readingContainer, { backgroundColor }]}>
      {isLoading ? (
        <Loader style={styles.loader} />
      ) : (
        <ScrollView
          ref={scrollViewRef}
          style={styles.scrollView}
          onLayout={(event) => {
            const { height } = event.nativeEvent.layout;
            setScrollViewHeight(height);
          }}
        >
          <View
            onLayout={(event) => {
              const { height } = event.nativeEvent.layout;
              setTotalContentHeight(height);
            }}
            style={styles.textContentContainer}
          >
            <Text style={styles.textContent}>
              <Text style={styles.dropCap}>{story.content.charAt(0)}</Text>
              {story.content.slice(1)}
            </Text>
          </View>
          <View style={styles.divider} />
        </ScrollView>
      )}
      <View style={{ height: 200 }}>
        <AudioPlayer
          ref={audioPlayerRef}
          jingleUri="https://res.cloudinary.com/dzcevvxsn/video/upload/v1707260054/musics/jingle_idcoxs.mp3"
          storyUri={audioUrl}
          musicUri={music ? music.file_path : ''}
          backgroundColor={backgroundColor}
          storyId={story.id}
          onPlaybackStatusUpdate={handlePlaybackStatusUpdate}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  readingContainer: {
    flex: 1,
  },
  scrollView: {
    flex: 1,
    padding: 32,
  },
  content: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    position: 'relative',
  },
  textContentContainer: {
    alignItems: 'center',
  },
  textContent: {
    fontFamily: 'SourceSerif4-Regular',
    fontSize: 26,
    lineHeight: 60,
    color: Colors.white,
    textAlign: 'center',
  },
  dropCap: {
    fontFamily: 'GochiHandRegular',
    fontSize: 60,
    lineHeight: 60,
    color: Colors.white,
  },
  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary05,
  },
  divider: {
    height: 24,
  },
});

export default StoryReadScreen;
