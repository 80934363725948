import Slider from '@react-native-community/slider';
import LottieView from 'lottie-react-native';
import React, { useEffect, useRef, useState } from 'react';
import { Pressable, StyleSheet, Text, View, Image, Platform } from 'react-native';
import Modal from 'react-native-modal';
import TrackPlayer, {
  Event,
  State,
  usePlaybackState,
  useProgress,
  useTrackPlayerEvents
} from 'react-native-track-player';
import seekBackwardAnimation from '../../assets/animations/double-play-backward.json';
import seekForwardAnimation from '../../assets/animations/double-play-forward.json';
import togglePlaybackAnimation from '../../assets/animations/play-pause-circle.json';
import Colors from '../constants/Colors';
import { setAdviceModalShown } from '../store/slices/storiesSlice';
import { useAppDispatch, useAppSelector } from '../store/store';
import Button from './Button';
import Loader from './Loader';

type AudioPlayerProps = {
  jingleUri: string;
  storyId: number;
  outroUri: string;
  storyTitle: string;
  storyUri: string;
  backgroundColor: string;
  artworkUri?: string;
};

const AudioPlayerPlayback: React.FC<AudioPlayerProps> = ({
  jingleUri,
  storyUri,
  outroUri,
  storyTitle,
  backgroundColor,
  artworkUri,
}) => {
  const playbackState = usePlaybackState();
  const { position, duration } = useProgress(500);
  const [isPlayerReady, setIsPlayerReady] = useState<boolean>(false);
  const [trackTitle, setTrackTitle] = useState<string>('');
  const [trackArtist, setTrackArtist] = useState<string>('');
  const [trackArtwork, setTrackArtwork] = useState<string | undefined>('');
  const [isAdviceModalVisible, setIsAdviceModalVisible] = useState(false);
  const adviceModalShown = useAppSelector(state => state.readStories.adviceModalShown);
  const dispatch = useAppDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const seekBackwardRef = useRef<LottieView>(null);
  const togglePlaybackRef = useRef<LottieView>(null);
  const seekForwardRef = useRef<LottieView>(null);
  const [isPlayingAnimation, setIsPlayingAnimation] = useState(false);
  const [animationSpeed, setAnimationSpeed] = useState(0);
  const animationRef = useRef<any>(null);

  useEffect(() => {
    async function setupAndLoadTracks() {
      await TrackPlayer.reset();
      setIsPlayerReady(false);

      const tracks = [
        {
          id: 'jingle',
          url: jingleUri,
          title: 'Jingle',
          artist: 'Fabulo',
          artwork: artworkUri || undefined,
        },
        {
          id: 'story',
          url: storyUri,
          title: storyTitle,
          artist: 'Fabulo',
          artwork: artworkUri || undefined,
        },
        {
          id: 'outro',
          url: outroUri,
          title: 'Outro Jingle',
          artist: 'Fabulo',
          artwork: artworkUri || undefined,
        },
      ];

      await TrackPlayer.add(tracks);
      setIsPlayerReady(true);
    }

    if (jingleUri && storyUri && outroUri) {
      setupAndLoadTracks();
    }
  }, [jingleUri, storyUri, outroUri, storyTitle, artworkUri]);

  useTrackPlayerEvents([Event.PlaybackActiveTrackChanged], async (event) => {
    if (event.track) {
      console.log(`Playing active track: ${event.track.title}`);
      setTrackTitle(event.track.title || "Unknown Title");
      setTrackArtist(event.track.artist || "Unknown Artist");
      setTrackArtwork(event.track.artwork);
    } else {
      console.log("No active track.");
      setTrackTitle("");
      setTrackArtist("");
      setTrackArtwork(undefined);
    }
  });

  useTrackPlayerEvents([Event.PlaybackError], (event) => {
    console.error(`Playback Error: ${event.code} - ${event.message}`);
  });

  useEffect(() => {
    console.log(`Playback State Changed: ${playbackState}`);
  }, [playbackState]);

  let isActivePlayback = false;
  if (playbackState?.state !== undefined) {
    isActivePlayback = playbackState.state === State.Playing || playbackState.state === State.Buffering;
  } else {
    isActivePlayback = false;
  }

  useEffect(() => {
    if (animationRef.current) {
      (animationRef.current as LottieView).reset();
      (animationRef.current as LottieView).play(0, 0);
    }
  }, []);

  const togglePlayback = async () => {
    // Temporarily disabling the Advice Modal check
    // if (!adviceModalShown) {
    //   setIsAdviceModalVisible(true);
    //   dispatch(setAdviceModalShown());
    // } else {
    // Start playback immediately if modal has been shown before
    if (isActivePlayback) {
      console.log('Pausing playback');
      await TrackPlayer.pause();
      setShowLoader(false);
      setAnimationSpeed(-2);
    } else {
      console.log('Starting playback');
      await TrackPlayer.play();
      debounceSetLoader(true, 500);
      setAnimationSpeed(2);
    }
    // }
  };

  // Debounce function to set loader visibility with delay
  const debounceSetLoader = ((delay) => {
    let timer: number;
    return (show: boolean | ((prevState: boolean) => boolean), delay: number) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        setShowLoader(show);
      }, delay);
    };
  })();

  useEffect(() => {
    if (togglePlaybackRef.current) {
      if (isPlayingAnimation) {
        togglePlaybackRef.current.play();
      } else {
        togglePlaybackRef.current.play();
      }
    }
  }, [isPlayingAnimation]);

  // Use isLoading state for determining whether to show loader
  const isLoading = playbackState && (playbackState.state === State.Buffering || playbackState.state === State.Loading) && showLoader;

  const seekBackward = async () => {
    if (!isPlayerReady) return;
    const newPosition = Math.max(position - 15, 0);
    await TrackPlayer.seekTo(newPosition);
    console.log('Seeked backward by 15 seconds');
  };

  const seekForward = async () => {
    if (!isPlayerReady) return;
    const newPosition = Math.min(position + 15, duration);
    await TrackPlayer.seekTo(newPosition);
    console.log('Seeked forward by 15 seconds');
  };

  const onSeek = async (value: number) => {
    if (!isPlayerReady) return;
    await TrackPlayer.seekTo(value);
    console.log('Seeked to', value);
  };

  const handleModalClose = async () => {
    console.log(`handleModalClose: Modal is closing. Advice modal shown: ${adviceModalShown}`);
    setIsAdviceModalVisible(false);
    if (!adviceModalShown) {
      console.log('handleModalClose: Advice modal not shown before. Attempting to play...');
      dispatch(setAdviceModalShown());
      await TrackPlayer.play().then(() => {
        console.log('Playback started successfully after closing modal.');
      }).catch(error => {
        console.error('Error starting playback after closing modal:', error);
      });
    } else {
      console.log('handleModalClose: Advice modal was already shown. Not starting playback.');
    }
  };

  let playPauseButton;

  if (Platform.OS === 'android') {
    const source = isActivePlayback ? require('../../assets/images/pause_static.png') : require('../../assets/images/play_static.png');
    playPauseButton = (
      <Pressable onPress={togglePlayback}>
        <Image source={source} style={{ width: 100, height: 100 }} />
      </Pressable>
    );
  } else {
    // iOS uses the Lottie animation as before
    playPauseButton = (
      <Pressable onPress={togglePlayback}>
        <LottieView
          ref={togglePlaybackRef}
          source={togglePlaybackAnimation}
          autoPlay={false}
          loop={false}
          speed={animationSpeed}
          style={styles.lottiePlayControl}
        />
      </Pressable>
    );
  }

  return (
    <View style={[styles.container, { backgroundColor }]}>
      <View
        style={[
          styles.overlayContainer,
          isLoading ? styles.overlayContainer : styles.noPointerEvents,
          { backgroundColor: isLoading ? backgroundColor : 'transparent' }
        ]}
      >
        {isLoading && (
          <Loader color="white" />
        )}
      </View>
      <View style={styles.controls}>
        <Pressable onPress={() => {
          seekBackward();
          seekBackwardRef.current?.play();
        }}>
          <LottieView
            ref={seekBackwardRef}
            source={seekBackwardAnimation}
            autoPlay={false}
            loop={false}
            speed={2}
            style={styles.lottieControl}
          />
        </Pressable>
        {playPauseButton}
        <Pressable onPress={() => {
          seekForward();
          seekForwardRef.current?.play();
        }}>
          <LottieView
            ref={seekForwardRef}
            source={seekForwardAnimation}
            autoPlay={false}
            loop={false}
            speed={2}
            style={styles.lottieControl}
          />
        </Pressable>
      </View>
      <Slider
        style={styles.slider}
        minimumValue={0}
        maximumValue={duration}
        value={position}
        onSlidingComplete={onSeek}
        minimumTrackTintColor="white"
        maximumTrackTintColor="grey"
        thumbTintColor="white"
      />
      <View style={styles.timing}>
        <Text style={styles.timingText}>
          {Math.floor(position / 60)}:{Math.floor(position % 60).toString().padStart(2, '0')}
        </Text>
        <Text style={styles.timingText}>
          {Math.floor(duration / 60)}:{Math.floor(duration % 60).toString().padStart(2, '0')}
        </Text>
      </View>
      <Modal isVisible={isAdviceModalVisible} onBackdropPress={handleModalClose}>
        <View style={styles.modalContent}>
          <Text style={styles.modalTitle}>Notre conseil</Text>
          <Text style={styles.modalText}>
            Pour une meilleure immersion, nous vous conseillons de diffuser l'histoire sur enceinte connectée !
          </Text>
          <Button title="Compris" onPress={handleModalClose} />
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  overlayContainer: {
    ...StyleSheet.absoluteFillObject,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  noPointerEvents: {
    pointerEvents: 'none',
  },
  loaderOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    alignItems: 'center',
    paddingTop: 16,
  },
  centeredContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  controls: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '80%',

  },
  lottieControl: {
    width: 50,
    height: 50,
  },
  lottiePlayControl: {
    width: 100,
    height: 100,
  },
  slider: {
    width: 300,
    height: 40,
  },
  timing: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
  },
  timingText: {
    color: 'white',
  },
  modalContent: {
    backgroundColor: Colors.primary05,
    padding: 22,
    borderRadius: 25,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 2,
    borderColor: 'black',
  },
  modalTitle: {
    fontFamily: 'GochiHandRegular',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  modalText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginTop: 10,
    marginBottom: 10,
    textAlign: 'center',
  },
});

export default AudioPlayerPlayback;
