import React from 'react';
import { Image, StyleSheet, Text, View } from 'react-native';
import LogoImage from '../../../assets/images/logo_blue_text.png';
import Button from '../../components/Button';
import Colors from '../../constants/Colors';
import { globalStyles } from '../../constants/Styles';
import { useNavigation } from '@react-navigation/native';
import { AppNavigationProps } from '../../types';
import RotatingStar from '../../components/RotatingStar';

const WelcomeScreen = () => {
  const navigation = useNavigation<AppNavigationProps<"Welcome">>();

  const starsDetails: {
    color: 'primary' | 'secondary' | 'tertiary' | 'black',
    size: 'small' | 'large',
    vertical: number,
    horizontal: number,
    rotationDirection: 'clockwise' | 'counterclockwise',
  }[] = [
    { color: 'primary', size: 'small', vertical: 10, horizontal: Math.random() * 100, rotationDirection: 'clockwise' },
    { color: 'secondary', size: 'large', vertical: 15, horizontal: Math.random() * 100, rotationDirection: 'counterclockwise' },
    { color: 'tertiary', size: 'small', vertical: 20, horizontal: Math.random() * 100, rotationDirection: 'clockwise' },
    { color: 'black', size: 'small', vertical: 30, horizontal: Math.random() * 100, rotationDirection: 'clockwise' },
  ];

  return (
    <View style={[globalStyles.container, styles.containerWithStars]}>
      {starsDetails.map((star, index) => (
        <RotatingStar
          color={star.color}
          size={star.size}
          rotationDirection={star.rotationDirection}
          style={{
            left: `${star.horizontal}%`,
            top: `${star.vertical}%`,
            position: 'absolute',
          }}
          key={index}
        />
      ))}
      <View style={styles.logoContainer}>
        <Image source={LogoImage} style={styles.logo} />
      </View>
      <Text style={styles.text}>Quand votre enfant devient le héros de sa propre histoire !</Text>
      <Button
        title="S'inscrire"
        onPress={() => navigation.navigate('SignUp')}
        variant="primary"
      />
      <Button
        title="Se connecter"
        onPress={() => navigation.navigate('SignIn')}
        variant="secondary"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  containerWithStars: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logoContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
  },
  logo: {
    width: 180,
    height: 140,
    resizeMode: 'contain',
  },
  text: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 22,
    color: Colors.text,
    textAlign: 'center',
    marginVertical: 16,
  },
});

export default WelcomeScreen;
