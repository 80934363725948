import privateApi from '../../utils/privateApi';
import { FamilyKidsType, PostKidType } from '../../types/kid';

export const getFamilyKids = async () => {
  return privateApi.get<FamilyKidsType>(
    '/api/v1/kids',
  );
};

export const postFamilyKid = async (payload: PostKidType) => {
  return privateApi.post(
    '/api/v1/kids', payload
  );
};

export const deleteFamily = async (familyId: number) => {
  return privateApi.delete(`/api/v1/families/${familyId}`);
};
