import { useNavigation } from '@react-navigation/native';
import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Animated, StyleSheet, Text, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { updatePassword } from '../../api/user/user.api';
import Button from '../../components/Button';
import CarAnimation from '../../components/CarAnimation';
import CriteriaText from '../../components/CriteriaText';
import InputField from '../../components/InputField';
import Colors from '../../constants/Colors';

const PasswordSettingsScreen = () => {
  const navigation = useNavigation();
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const moveAnimation = new Animated.Value(0);

  const [passwordCriteria, setPasswordCriteria] = useState({
    containsDigit: false,
    containsUpper: false,
    containsLower: false,
    containsSpecial: false,
    minLength: false,
  });

  const updatePasswordCriteria = (password: string) => {
    setPasswordCriteria({
      containsDigit: /\d/.test(password),
      containsUpper: /[A-Z]/.test(password),
      containsLower: /[a-z]/.test(password),
      containsSpecial: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      minLength: password.length >= 6,
    });
  };

  const startAnimation = useCallback(() => {
    moveAnimation.setValue(0);
    Animated.timing(moveAnimation, {
      toValue: 1,
      duration: 8000,
      useNativeDriver: true,
    }).start(() => startAnimation());
  }, []);

  useEffect(() => {
    startAnimation();
  }, [startAnimation]);

  const handleSavePasswordChanges = async () => {
    if (newPassword !== confirmNewPassword) {
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Le nouveau mot de passe et la confirmation ne correspondent pas.',
      });
      return;
    }

    try {
      const passwordData = {
        current_password: currentPassword,
        password: newPassword,
        password_confirmation: confirmNewPassword,
      };

      await updatePassword(passwordData);
      Toast.show({
        type: 'success',
        text1: 'Succès',
        text2: 'Votre mot de passe a été mis à jour avec succès.',
      });
      navigation.goBack();
    } catch (error) {
      Toast.show({
        type: 'error',
        text1: 'Erreur',
        text2: 'Impossible de mettre à jour le mot de passe. Veuillez réessayer.',
      });
    }
  };

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Mon mot de passe',
    });
  }, [navigation]);

  return (
    <View style={styles.container}>
      <View style={styles.divider} />
      <InputField
        label="Mot de passe actuel"
        value={currentPassword}
        onChangeText={setCurrentPassword}
        inputType="password"
        placeholder="••••••••"
        secureTextEntry
      />
      <InputField
        label="Nouveau mot de passe"
        value={newPassword}
        onChangeText={(text) => {
          setNewPassword(text);
          updatePasswordCriteria(text);
        }}
        inputType="password"
        placeholder="••••••••"
        secureTextEntry
      />
      <Text style={styles.instructionText}>
        Le mot de passe doit être de
        <CriteriaText isMet={passwordCriteria.minLength}>
          {' '}<Text style={styles.boldText}>minimum 6 caractères</Text>
        </CriteriaText>, avec au moins
        <CriteriaText isMet={passwordCriteria.containsDigit}>
          {' '}<Text style={styles.boldText}>un chiffre</Text>
        </CriteriaText>, une
        <CriteriaText isMet={passwordCriteria.containsUpper}>
          {' '}<Text style={styles.boldText}>lettre majuscule</Text>
        </CriteriaText> et un
        <CriteriaText isMet={passwordCriteria.containsSpecial}>
          {' '}<Text style={styles.boldText}>caractère spécial</Text>.
        </CriteriaText>
      </Text>
      <InputField
        label="Confirmation du nouveau mot de passe"
        value={confirmNewPassword}
        onChangeText={setConfirmNewPassword}
        inputType="password"
        placeholder="••••••••"
        secureTextEntry
      />
      <Button title="Valider" onPress={handleSavePasswordChanges} />
      <CarAnimation />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 16,
    backgroundColor: Colors.primary05,
  },
  divider: {
    height: 24,
  },
  instructionText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    color: Colors.text,
    marginVertical: 8,
  },
  boldText: {
    fontWeight: 'bold',
  },
});

export default PasswordSettingsScreen;
