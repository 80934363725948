import { AxiosResponse } from "axios";
import { DependencyList, useCallback, useEffect, useState } from "react";
import handleApiError from "../functions/apiErrorHandling";

function useLoadDataList<T>(
  loadFunction: () => Promise<AxiosResponse<T[]>>,
  hookDependencies?: DependencyList,
  showToast?: boolean
) {
  const [data, setData] = useState<T[]>();
  const [refreshing, setRefreshing] = useState(false);

  const loadData = async () => {
    try {
      const res = await loadFunction();
      setData(res.data);
    } catch (error) {
      // If it fails, handle the api message error if the error is actually an api error.
      handleApiError(error, showToast ? undefined : () => {});
    }
  };

  const onRefresh = useCallback(async () => {
    setRefreshing(true);
    await loadData();
    setRefreshing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, hookDependencies || []);

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, hookDependencies || []);

  return { refreshing, onRefresh, data, loadData };
}

export default useLoadDataList;
