import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
import { Emoji, emojiArray } from '../constants/Enums';

interface EmojiSelectorProps {
  selectedEmoji: string;
  onEmojiSelect: (emoji: Emoji) => void;
}

const EmojiSelector: React.FC<EmojiSelectorProps> = ({ selectedEmoji, onEmojiSelect }) => {
  return (
    <View style={styles.emojisContainer}>
      {emojiArray.map((emoji, index) => (
        <Pressable
          key={index}
          style={[
            styles.emojiBox,
            selectedEmoji === emoji ? styles.selectedEmoji : null,
          ]}
          onPress={() => onEmojiSelect(emoji)}
        >
          <Text style={styles.emoji}>{emoji}</Text>
        </Pressable>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  emojisContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    alignItems: 'flex-start',
    width: '100%',
  },
  emojiBox: {
    width: '30%',
    padding: 10,
    margin: 5,
    alignItems: 'center',
    borderRadius: 25,
    borderWidth: 2,
    borderColor: '#F6F9FE',
  },
  selectedEmoji: {
    backgroundColor: '#FFF',
    borderRadius: 25,
    borderWidth: 2,
    borderColor: 'black',
  },
  emoji: {
    fontSize: 30,
  },
});

export default EmojiSelector;
