import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { useSelector } from 'react-redux';
import CustomBackButton from '../components/CustomBackButton';
import { ProgressBarProvider } from '../components/ProgressBarContext';
import Colors from '../constants/Colors';
import { globalStyles } from '../constants/Styles';
import CreateKidWorldScreen from '../screens/kidProfile/CreateKidWorldScreen';
import CreateWorldInstantScreen from '../screens/kidProfile/CreateWorldInstantScreen';
import CreateWorldOnboardingScreen from '../screens/kidProfile/CreateWorldOnboardingScreen';
import EditKidWorldScreen from '../screens/kidProfile/EditKidWorldScreen';
import KidOnboardingScreen from '../screens/kidProfile/KidOnboardingScreen';
import EndOnboardingScreen from '../screens/kidProfile/EndOnboardingScreen';
import StoryOnboardingScreen from '../screens/kidProfile/StoryOnboardingScreen';
import CreditsScreen from '../screens/main/CreditsScreen';
import StoryReadPlaybackScreen from '../screens/storyViewer/StoryReadPlaybackScreen';
import StoryReadScreen from '../screens/storyViewer/StoryReadScreen';
import { RootState } from '../store/store';
import AuthNavigator from './AuthNavigator';
import KidCreationNavigator from './KidCreationNavigator';
import MainTabNavigator from './MainTabNavigator';
import StoryCreationNavigator from './StoryCreationNavigator';

const Stack = createStackNavigator();

const AppNavigator: React.FC = () => {
  const isLoggedIn = useSelector((state: RootState) => state.app.accessToken);

  return (
    <ProgressBarProvider>
      <Stack.Navigator
        screenOptions={{
          headerShown: false,
          headerBackTitleVisible: false,
          cardStyle: { backgroundColor: Colors.primary05 },
        }}
      >
        {isLoggedIn ? (
          <>
            <Stack.Screen name="Main" component={MainTabNavigator} />
          </>
        ) : (
          <>
            <Stack.Screen name="Auth" component={AuthNavigator} />
          </>
        )}
        <Stack.Screen
          name="KidCreationFlow"
          component={KidCreationNavigator}
          options={{
            presentation: 'modal',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="StoryCreationFlow"
          component={StoryCreationNavigator}
          options={{
            presentation: 'modal',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Credits"
          component={CreditsScreen}
          options={{
            presentation: 'modal',
            headerShown: true,
            headerBackImage: () => <CustomBackButton />,
            headerTitleStyle: globalStyles.headerTitleStyle,
            headerTitleAlign: 'center',
            headerTintColor: Colors.text,
            headerStyle: {
              backgroundColor: Colors.primary05,
              borderBottomWidth: 1,
              borderBottomColor: 'black',
            },
          }}
        />
        <Stack.Screen
          name="StoryRead"
          component={StoryReadScreen}
          options={{
            presentation: 'modal',
            headerShown: true,
            headerBackImage: () => <CustomBackButton />,
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: Colors.primary05,
              borderBottomWidth: 1,
              borderBottomColor: 'black',
            },
          }}
        />
        <Stack.Screen
          name="StoryReadPlayback"
          component={StoryReadPlaybackScreen}
          options={{
            presentation: 'modal',
            headerShown: true,
            headerBackImage: () => <CustomBackButton />,
            headerTitleAlign: 'center',
            headerStyle: {
              backgroundColor: Colors.primary05,
              borderBottomWidth: 1,
              borderBottomColor: 'black',
            },
          }}
        />
        <Stack.Screen
          name="EditKidWorld"
          component={EditKidWorldScreen}
          options={{
            presentation: 'modal',
            headerShown: true,
            headerBackImage: () => <CustomBackButton />,
            headerTitleStyle: globalStyles.headerTitleStyle,
            headerTitleAlign: 'center',
            headerTintColor: Colors.text,
            headerStyle: {
              backgroundColor: Colors.primary05,
              borderBottomWidth: 1,
              borderBottomColor: 'black',
            },
          }}
        />
        <Stack.Screen
          name="CreateKidWorld"
          component={CreateKidWorldScreen}
          options={{
            presentation: 'modal',
            headerShown: true,
            headerBackImage: () => <CustomBackButton />,
            headerTitleStyle: globalStyles.headerTitleStyle,
            headerTitleAlign: 'center',
            headerTintColor: Colors.text,
            headerStyle: {
              backgroundColor: Colors.primary05,
              borderBottomWidth: 1,
              borderBottomColor: 'black',
            },
          }}
        />
        <Stack.Screen
          name="CreateWorldOnboarding"
          component={CreateWorldOnboardingScreen}
          options={{
            presentation: 'modal',
            headerShown: true,
            headerBackImage: () => <CustomBackButton />,
            headerTitleStyle: globalStyles.headerTitleStyle,
            headerTitleAlign: 'center',
            headerTintColor: Colors.text,
            headerStyle: {
              backgroundColor: Colors.primary05,
              borderBottomWidth: 1,
              borderBottomColor: 'black',
            },
          }}
        />
        <Stack.Screen
          name="CreateWorldInstant"
          component={CreateWorldInstantScreen}
          options={{
            presentation: 'modal',
            headerShown: true,
            headerBackImage: () => <CustomBackButton />,
            headerTitleStyle: globalStyles.headerTitleStyle,
            headerTitleAlign: 'center',
            headerTintColor: Colors.text,
            headerStyle: {
              backgroundColor: Colors.primary05,
              borderBottomWidth: 1,
              borderBottomColor: 'black',
            },
          }}
        />
        <Stack.Screen
          name="KidOnboarding"
          component={KidOnboardingScreen}
          options={{
            presentation: 'modal',
            headerShown: true,
            headerBackImage: () => <CustomBackButton />,
            headerTitleStyle: globalStyles.headerTitleStyle,
            headerTitleAlign: 'center',
            headerTintColor: Colors.text,
            headerStyle: {
              backgroundColor: Colors.primary05,
              borderBottomWidth: 1,
              borderBottomColor: 'black',
            },
          }}
        />
        <Stack.Screen
          name="StoryOnboarding"
          component={StoryOnboardingScreen}
          options={{
            presentation: 'modal',
            headerShown: true,
            headerBackImage: () => <CustomBackButton />,
            headerTitleStyle: globalStyles.headerTitleStyle,
            headerTitleAlign: 'center',
            headerTintColor: Colors.text,
            headerStyle: {
              backgroundColor: Colors.primary05,
              borderBottomWidth: 1,
              borderBottomColor: 'black',
            },
          }}
        />
        <Stack.Screen
          name="EndOnboarding"
          component={EndOnboardingScreen}
          options={{
            presentation: 'modal',
            headerShown: true,
            headerBackImage: () => <CustomBackButton />,
            headerTitleStyle: globalStyles.headerTitleStyle,
            headerTitleAlign: 'center',
            headerTintColor: Colors.text,
            headerStyle: {
              backgroundColor: Colors.primary05,
              borderBottomWidth: 1,
              borderBottomColor: 'black',
            },
          }}
        />
      </Stack.Navigator>
    </ProgressBarProvider>
  );
};

export default AppNavigator;
