import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import LottieView from 'lottie-react-native';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Keyboard, KeyboardAvoidingView, Platform, Pressable, StyleSheet, View } from 'react-native';
import Toast from 'react-native-toast-message';
import BoyNod from '../../../assets/animations/boy-nod.json';
import BoyReveal from '../../../assets/animations/boy-reveal.json';
import GirlNod from '../../../assets/animations/girl-nod.json';
import GirlReveal from '../../../assets/animations/girl-reveal.json';
import Button from '../../components/Button';
import InputField from '../../components/InputField';
import { useProgressBar } from '../../components/ProgressBarContext';
import { globalStyles } from '../../constants/Styles';
import { AppNavigationProps } from '../../types';

const EnterKidNameScreen = () => {
  const navigation = useNavigation<AppNavigationProps<"EnterKidName">>();
  const [kidName, setKidName] = useState('');
  const { setCurrentStep } = useProgressBar();
  const [animationSource, setAnimationSource] = useState(BoyReveal);
  const [animationStep, setAnimationStep] = useState(1);

  useFocusEffect(
    React.useCallback(() => {
      setCurrentStep(1);
    }, [])
  );

  useLayoutEffect(() => {
    navigation.setOptions({
      title: 'Son prénom',
      headerShadowVisible: false,
      headerBackTitleVisible: false,
      headerLeft: () => null,
      headerRight: () => (
        <Pressable onPress={() => navigation.navigate('MyFamily')} style={{ marginRight: 16 }}>
          <MaterialCommunityIcons name="close" size={24} color="black" />
        </Pressable>
      ),
    });
  }, [navigation]);

  useEffect(() => {
    const timeouts: number[] = [];
    const changeAnimation = (nextAnimation: any, nextStep: React.SetStateAction<number>, delay: number) => {
      const timeout = setTimeout(() => {
        setAnimationSource(nextAnimation);
        setAnimationStep(nextStep);
      }, delay);
      timeouts.push(timeout);
    };

    switch (animationStep) {
      case 1:
        changeAnimation(BoyNod, 2, 2000);
        break;
      case 2:
        changeAnimation(GirlReveal, 3, 2000);
        break;
      case 3:
        changeAnimation(GirlNod, 4, 2000);
        break;
      case 4:
        changeAnimation(BoyReveal, 1, 2000);
        break;
    }

    return () => timeouts.forEach(clearTimeout);
  }, [animationStep]);

  const handleNextPress = () => {
    if (kidName.trim().length === 0) {
      Toast.show({
        type: 'error',
        text1: "Erreur",
        text2: "Le prénom de l'enfant ne peut pas être vide."
      });
      return;
    }
    navigation.navigate('SelectKidAge', { kidName });
  };

  return (
    <KeyboardAvoidingView
      style={{ flex: 1 }}
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      keyboardVerticalOffset={Platform.OS === 'ios' ? 60 : 0}
    >
      <View style={globalStyles.container}>
        <View style={styles.animationContainer}>
          <LottieView
            source={animationSource}
            autoPlay
            loop={false}
            style={styles.lottieAnimation}
          />
        </View>
        <InputField
          placeholder="Quel est son prénom ?"
          value={kidName}
          onChangeText={setKidName}
          inputType="name"
          maxLength={20}
          onSubmitEditing={() => {
            handleNextPress();
            Keyboard.dismiss();
          }}
          autoFocus={true}
        />
        <Button
          title="Suivant"
          onPress={handleNextPress}
        />
      </View>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  animationContainer: {
    height: 150,
    width: 150,
    alignSelf: 'center',
    paddingBottom: 16,
  },
  lottieAnimation: {
    height: '100%',
    width: '100%',
  },
});

export default EnterKidNameScreen;
