import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Animated, ListRenderItemInfo, RefreshControl, StyleSheet, Text, View } from 'react-native';
import Toast from 'react-native-toast-message';
import { getFamilyKids } from '../../api/family/family.api';
import AnimatedBackground from '../../components/AnimatedBackground';
import Button from '../../components/Button';
import FamilyScreenHeader from '../../components/FamilyScreenHeader';
import KidCard from '../../components/KidCard';
import Loader from '../../components/Loader';
import Colors from '../../constants/Colors';
import { resetAppState } from '../../store/slices/appSlice';
import { useAppSelector } from '../../store/store';
import { KidType } from '../../types/kid';
import { AppNavigationProps, RootStackParamList } from '../../types/navigation';
import { formatAge } from '../../utils/functions/formatAge';
import { reset } from '../../utils/functions/navigationRef';
import useLoadData from '../../utils/hooks/useLoadData';
import { useDispatch } from 'react-redux';

const FamilyScreen = ({ route }: NativeStackScreenProps<RootStackParamList, "MyFamily">) => {
  const navigation = useNavigation<AppNavigationProps<"MyFamily">>();
  const { data, loadData, refreshing, onRefresh } = useLoadData(() => getFamilyKids());
  const userFirstName = useAppSelector((state) => state.app.firstName);
  const credits = useAppSelector((state) => state.app.credits);
  const animationTrigger = useRef(new Animated.Value(0)).current;
  const [showTip, setShowTip] = useState(false);
  const loaderTimeoutRef = useRef<number | null>(null);
  const dispatch = useDispatch();

  useFocusEffect(
    useCallback(() => {
      loadData();
      Animated.spring(animationTrigger, {
        toValue: 1,
        speed: 2,
        bounciness: 10,
        useNativeDriver: true,
      }).start();
    }, [])
  );

  useEffect(() => {
    loaderTimeoutRef.current = setTimeout(() => {
      setShowTip(true);
    }, 5000);

    return () => {
      if (loaderTimeoutRef.current) {
        clearTimeout(loaderTimeoutRef.current);
      }
    };
  }, []);

  const handlePressKid = (kidId: number, kidName: string) => {
    navigation.navigate('KidOverview', { kidId, kidName });
  };

  const handleAddKid = (credits: number) => {
    if (credits === 0) {
      navigation.navigate('Credits');
      Toast.show({
        type: 'info',
        text1: 'Il faut du crédit',
        text2: 'pour créer un nouvel enfant !',
        visibilityTime: 4000,
        autoHide: true,
        topOffset: 30,
        bottomOffset: 40,
      });
    } else {
      navigation.navigate('KidCreationFlow');
    }
  };

  const handleSignOut = () => {
    dispatch(resetAppState());
    reset([{ name: 'Main' }]);
  };

  const renderItem = ({ item }: ListRenderItemInfo<KidType>) => (
    <KidCard
      id={item.id}
      name={item.first_name}
      emoji={item.emoji}
      age={formatAge(item.age)}
      onPress={() => handlePressKid(item.id, item.first_name)}
    />
  );

  const getIntroText = () => {
    if (!data || !data.kids) return "";
    if (data.kids.length === 0) {
      return 'Bienvenue chez Fabulo ! Pour commencer, ajoutez un enfant.';
    } else if (data.kids.length === 1) {
      return 'Qu\'allez-vous raconter à votre enfant aujourd’hui ?';
    } else {
      return 'À qui voulez-vous raconter une histoire aujourd’hui ?';
    }
  };

  const translateY = animationTrigger.interpolate({
    inputRange: [0, 1],
    outputRange: [200, 0]
  });

  return (
    <>
      <AnimatedBackground animationKey={Math.floor(Math.random() * 1000)} />
      {!data ? (
        <View style={[styles.centeredContainer]}>
          <Loader />
          {showTip && (
            <View style={styles.tipContainer}>
              <Text style={styles.tipTitle}>Bloqué sur cet écran ?</Text>
              <Text style={styles.tipText}>Il vous faut sans doute vous déconnecter puis vous reconnecter.</Text>
              <Button
                title="Se déconnecter"
                variant="link"
                onPress={handleSignOut}
                style={{ marginVertical: 4 }}
              />
            </View>
          )}
        </View>
      ) : (
        <Animated.FlatList
          data={data.kids}
          renderItem={renderItem}
          keyExtractor={item => item.id.toString()}
          numColumns={2}
          columnWrapperStyle={styles.columnWrapper}
          contentContainerStyle={[
            styles.listContentContainer,
            data && data.kids.length === 0 && styles.flexContainer
          ]}
          ListHeaderComponent={
            <FamilyScreenHeader
              userFirstName={userFirstName || ""}
              introText={getIntroText()}
              numKids={data?.kids.length || 0}
            />
          }
          ListFooterComponent={
            <View style={styles.footerContainer}>
              <Button
                title="Ajouter un enfant"
                variant="secondary"
                onPress={() => handleAddKid(credits)}
                style={styles.button}
              />
            </View>
          }
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={onRefresh}
            />
          }
          style={{ transform: [{ translateY }] }}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  centeredContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.primary05,
    height: '100%',
  },
  container: {
    flex: 1,
    alignItems: 'stretch',
    backgroundColor: 'transparent',
  },
  tipContainer: {
    paddingHorizontal: 16,
    marginTop: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  tipTitle: {
    fontFamily: 'Gilroy-Bold',
    fontSize: 24,
    lineHeight: 30,
    marginVertical: 10,
    textAlign: 'center',
  },
  tipText: {
    fontFamily: 'Gilroy-Regular',
    fontSize: 16,
    lineHeight: 20,
    marginVertical: 10,
    textAlign: 'center',
  },
  columnWrapper: {
    justifyContent: 'center',
  },
  listContentContainer: {
    paddingHorizontal: 16,
    flexGrow: 1,
    justifyContent: 'center',
  },
  footerContainer: {
    flexGrow: 0,
  },
  flexContainer: {
    flex: 1,
  },
  button: {
    width: 316,
    alignSelf: 'center',
  },
});

export default React.memo(FamilyScreen);
function dispatch(arg0: { payload: undefined; type: "app/resetAppState"; }) {
  throw new Error('Function not implemented.');
}
