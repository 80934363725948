import axios from "axios";
import environment from "../environments";
import { store } from "../store/store";

const privateApi = axios.create({
  baseURL: environment.apiUrl,
  headers: {
    Accept: 'application/json',
  },
});

privateApi.interceptors.request.use(
  (config) => {
    const { accessToken, client, uid } = store.getState().app;
    if (accessToken && config.headers) {

      config.headers = {
        ...config.headers,
        'access-token': accessToken,
        'client': client,
        'uid': uid,
        'Content-Type': 'application/json'
      };
    } else {
      delete privateApi.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

privateApi.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: unknown) => {
    // If token has expired, then we logout...
    /*if (axios.isAxiosError(error) && error?.response?.status === 401) {
      store.dispatch(appSlice.actions.resetAppState());
    }*/
    return Promise.reject(error);
  }
);

export default privateApi;
