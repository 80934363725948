import { AuthSignUpResponseType, AuthTokens } from "../../types/auth";
import publicApi from "../../utils/publicApi";
import privateApi from '../../utils/privateApi';

export const handleSignIn = async (email: string, password: string) => {
  const response = await publicApi.post('/auth/sign_in', { email, password });
  const headers = response.headers;
  const user = response.data.data

  return {
    token: headers['access-token'],
    client: headers['client'],
    uid: headers['uid'],
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
  };
};

export const handleSignUp = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  passwordConfirmation: string
): Promise<AuthSignUpResponseType & AuthTokens> => {
  const userData = {
    first_name: firstName,
    last_name: lastName,
    email: email,
    password: password,
    password_confirmation: passwordConfirmation,
  };

  const response = await publicApi.post('/auth', userData);
  const headers = response.headers;

  return {
    // Response data
    ...response.data,
    // Extract tokens from headers
    token: headers['access-token'],
    client: headers['client'],
    uid: headers['uid'],
  };
};

interface resetPasswordData {
  email: string;
  redirect_url: string
}

export const resetPassword = async (resetPasswordData: resetPasswordData) => {
  try {
    const response = await privateApi.post('/auth/password', resetPasswordData);
    return response.data;
  } catch (error) {
    console.error('Error updating password:', error);
    throw error;
  }
};
