export enum Emoji {
  Unicorn = '🦄',
  Dog = '🐶',
  Cat = '😸',
  Horse = '🐴',
  Dolphin = '🐬',
  Rabbit = '🐰',
  Panda = '🐼',
  Lion = '🦁',
  Dinosaur = '🦕',
}

export const AgeRange = [
  3,
  3.5,
  4,
  4.5,
  5,
  5.5,
  6,
  6.5,
  7,
];

export enum SectionTitles {
  family = '👨‍👩‍👧‍👦 Sa famille',
  places = '🏞️ Ses lieux',
  companions = '👫 Ses compagnons',
  passions = '🎯 Ses passions',
  contextual = '🕒 Sur le moment',
}

export enum ProgramType {
  TakeCare = '🪁 Se divertir',
  FallAsleep = '😴 S’endormir',
  ManageEmotions = '😖 Gérer ses émotions',
}

export enum EmotionType {
  Joy = '😊 La joie',
  Anger = '😡 La colère',
  Sadness = '😢 La tristesse',
  Fear = '😨 La peur',
  Frustration = '😤 La frustration',
  Jealousy = '😒 La jalousie',
}

export enum ThemeType {
  DailyLife = '🏡 Le quotidien',
  Family = '👨‍👩‍👧‍👦 La famille',
  Adventures = '🧭 Les aventures',
  Professions = '👩‍🔧 Les métiers',
  Animals = '🐕 Les animaux',
  Seasons = '🌦️ Les saisons',
}

export enum ProfessionsSubThemes {
  Astronaut = '👩‍🚀 Astronaute',
  Firefighter = '🚒 Pompier',
  PoliceOfficer = '👮‍♂️ Policier',
  Doctor = '🩺 Médecin',
  Singer = '🎤 Chanteur',
  YouTuber = '🤳 Youtubeur',
  Teacher = '👩‍🏫 Professeur',
  Dentist = '🦷 Dentiste',
  Librarian = '📚 Libraire',
  Pharmacist = '💊 Pharmacien',
  BowMaker = '🎻 Archetier'
}

export enum AnimalsSubThemes {
  Cat = '😸 Chat',
  Dog = '🐶 Chien',
  Rabbit = '🐰 Lapin',
  Turtle = '🐢 Tortue',
  Bird = '🐦 Oiseau',
  Fish = '🐠 Poisson',
}

export enum SeasonsSubThemes {
  Summer = '🌞 Été',
  Winter = '⛄️ Hiver',
  Autumn = '🍂 Automne',
  Spring = '🌸 Printemps',
}

export enum DailyLifeSubThemes {
  SchoolDay = '👩‍🏫 Va l’école',
  FirstDayInKindergarten = '👶 Rentrée en maternelle',
  FirstDayInPrimary = '📚 Rentrée au CP',
  ParkPlay = '🌳 Se promène au parc',
  BirthdayParty = '🎂 Fête son anniversaire',
  FamilyMeal = '🍽️ Mange comme un grand',
  MakesPancakes = '🥞 Fait des crêpes',
  AlwaysSayingNo = '🙅 Dit toujours non !',
  BrushingTeeth = '🪥 Se brosse les dents',
  NoMorePacifier = '🚫 Fini la tétine !',
  NoMoreBedwetting = '🛏️ Ne fait plus pipi au lit',
  FirstTimeOnPotty = '🚽 Première fois sur le pot',
  PlayingHideAndSeek = '🙈 Joue à cache-cache',
  IsSick = '🤒 Est malade',
  DressesUp = '🎭 Se déguise',
  Dances = '🪩 Danse à fond !',
  FootballChampion = '⚽ Champion de foot !',
  DiscoversBiking = '🚴 Commence le vélo',
}

export enum FamilySubThemes {
  StaysWithGrandparents = '👵 Dort chez papi et mamie',
  StaysWithAFriend = '👦 Dort chez un copain',
  MovingToNewHouse = '🏠 Nouvelle maison',
  SoonABrother = '👶 Bientôt un petit frère',
  SoonASister = '👧 Bientôt une petite sœur',
}

export enum AdventuresSubThemes {
  GoesSwimming = '🏊 Va à la piscine',
  GoesToTheZoo = '🦁 Va au zoo',
  AtTheAquarium = '🐠 Va à l\'aquarium',
  GoesSkiing = '⛷️ Va au ski',
  GoesToTheMountain = '🏔️ Va à la montagne',
  GoesToTheBeach = '🏖️ Va à la plage',
  GoesToTheCircus = '🎪 Va au cirque',
  TakesAPlane = '✈️ Prend l\'avion',
  TakesATrain = '🚂 Prend le train',
  FunFairVisit = '🎡 À la fête foraine',
  Superheroes = '🦸‍♂️ Super-héros',
  Pirates = '🏴‍☠️ Pirates',
  Knights = '🛡️ Chevaliers',
  SpaceExplorers = '🚀 Explorateurs de l’espace',
  MagicWizards = '🧙‍♂️ Magiciens',
}

export const CreditPacks = [
  { credits: 1, price: '0,99 €', pricePerCredit: '0,99 €' },
  { credits: 3, price: '1,99 €', pricePerCredit: '0,66 €' },
  { credits: 5, price: '2,99 €', pricePerCredit: '0,60 €' },
  { credits: 10, price: '4,99 €', pricePerCredit: '0,50 €' },
  { credits: 20, price: '8,99 €', pricePerCredit: '0,45 €' },
  { credits: 50, price: '19,99 €', pricePerCredit: '0,40 €' },
];

export const emojiArray = Object.values(Emoji);
export const ageRangeArray = AgeRange;
